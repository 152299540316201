<div class="dialog-container">
  <div class="body-dialog">
    <h2>Deseja sair sem finalizar as alterações?</h2>
    <mat-icon>warning</mat-icon>
    <div class="buttons-container">
      <button class="button" mat-button (click)="closeAllDialogs()">
        Sair
      </button>
      <button [mat-dialog-close]="true" mat-button color="primary">
        Cancelar
      </button>
    </div>
  </div>
</div>
