import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class DocumentStatusService {
  user!: User | null;
  constructor(private httpClient: HttpClient, private authService:AuthService) {
    this.user = this.authService.user.value;
  }


  editCompanyDocumentStatus(companyId: string, data: { status: string; rejection: string; }, isPartnerDocument: boolean) {
    const responsible = this.user?.fullName;

    const url = isPartnerDocument
      ? environment.API_url + `management-api/documents/partner/update/${companyId}/${responsible}`
      : environment.API_url + `management-api/documents/update/${companyId}/${responsible}`

    return this.httpClient.put(url, data);
  }
}
