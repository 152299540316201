<mat-card [class]="status">
  <ng-content select="[content]"></ng-content>
  <div class="actions">
    <button
      mat-stroked-button
      *ngFor="let item of buttons; let i = index"
      (click)="clicked.emit(i)"
      [disabled]="item.disabled"
    >
      <small>{{ item.name }}</small>
    </button>
  </div>
</mat-card>
