<p class="small-title"></p>
<mat-dialog-content class="container">
  <h1 class="big-title">Administração de Títulos</h1>
</mat-dialog-content>
<hr />
<form [formGroup]="formModal" class="filters-container">
  <div class="filters">
    <mat-form-field appearance="outline" class="filter-cnpj-social">
      <mat-label>Status</mat-label>
      <mat-select formControlName="statusModal"
        ><mat-option
          [value]="option.value"
          *ngFor="let option of statusOptionsModal"
          >{{ option?.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="filter-cnpj-social">
      <mat-label> Destinar Título para a Fomento: </mat-label>
      <input
        class="input-autocomplete"
        formControlName="cnpjModal"
        placeholder="Destinar Título para a Fomento:"
        matInput
        type="text"
        [matAutocomplete]="modal"
      />
      <mat-autocomplete
        #modal="matAutocomplete"
        [displayWith]="displayFn.bind(this)"
      >
        <mat-option [value]="option.id" *ngFor="let option of clientOptions">{{
          option?.name
        }}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <mat-form-field
    matTooltipPosition="above"
    appearance="outline"
    class="responsible"
  >
    <mat-label>Responsável</mat-label>
    <mat-select formControlName="responsibleModal">
      <mat-option
        [value]="option.fullName"
        *ngFor="let option of responsibleOptionsModal"
        >{{ option?.fullName }}</mat-option
      >
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Tipo de Operação</mat-label>
    <mat-select formControlName="operationType">
      <mat-option
        [value]="option.value"
        *ngFor="let option of operationTypeOptions"
      >
        {{ option.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Observação</mat-label>
    <textarea matInput formControlName="observation"></textarea>
  </mat-form-field>
</form>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    Cancelar
  </button>
  <button
    mat-button
    cdkFocusInitial
    [mat-dialog-close]="customerModal"
    color="primary"
    [disabled]="!formModal.valid"
  >
    Salvar
  </button>
</mat-dialog-actions>
