import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { take, exhaustMap, catchError, retry } from 'rxjs/operators';
import { UserInterface } from '../interfaces/user';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  id = null;
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return this.authService.user.pipe(
      take<any>(1),
      exhaustMap((user: UserInterface) => {
        const skipIntercept = req.headers.has('skip');

        if (skipIntercept) {
          const modifiedReq = req.clone({
            headers: req.headers.delete('skip'),
          });
          return next.handle(modifiedReq);
        }

        if (req.url.includes(environment.register_api)) {
          return next.handle(req);
        }
        const modifiedReq = req.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.accessToken,
          },
        });

        return next.handle(modifiedReq).pipe(
          catchError((error: HttpErrorResponse) => {
            let errorMessage = '';
            if (error.error instanceof ErrorEvent) {
              // client-side error
              errorMessage = `Error: ${error.error.message}`;
            } else {
              if (error.error.message === 'INVALID_TOKEN') {
                this.authService.logout();
              }
              // server-side error
              errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            }
            return throwError(error);
          })
        );
      })
    );
  }
}
