<div class="main-container">
  <div class="content-container">
    <div class="dados-operacao">
      <p>Documentos da operação</p>
    </div>

    <div class="example-spacer">
      <h1>{{ nameLead }}</h1>
    </div>
    <div class="spinner-container" *ngIf="!aditiveData">
      <mat-spinner class="spinner" color="primary"></mat-spinner>
    </div>
    <br />
    <div class="cnpj-empresa" *ngIf="aditiveData">
      <p>{{ aditiveData[0].nameCollect }}</p>
    </div>
    <br />

    <div class="dropdown" *ngFor="let item of aditiveData">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header class="dropdown-header">
            <div class="dropdown-label-container">
              <p class="label">{{ item.nameCollect }}</p>

              <mat-chip-list>
                <mat-chip
                  [ngStyle]="{
                    'background-color': getStatusColor(item.status)
                  }"
                >
                  {{ getStatusName(item.status) }}
                </mat-chip>
              </mat-chip-list>

              <button mat-flat-button class="button-link">
                <a href="{{ item.link }}" target="_blank">Acessar</a>
              </button>
            </div>
          </mat-expansion-panel-header>

          <div class="collect">
            <div class="row-collect" *ngFor="let document of item.documents">
              <p class="label-collect">
                {{ getCollectDocuments(document.type) }}
              </p>

              <mat-chip-list>
                <mat-chip
                  [ngStyle]="{
                    'background-color': getStatusColor(item.status)
                  }"
                >
                  {{ getStatusName(item.status) }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
