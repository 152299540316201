import { filter } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  optinsMenu: any;
  ca: any;

  subscriptionMenu!: Subscription;
  subscriptionCA!: Subscription;
  asCustomerManagement!: boolean;

  constructor(private menuService: MenuService) {
    this.subscriptionMenu = this.menuService.optinsMenu.subscribe((x) => {
      this.optinsMenu = x;
    });
    this.subscriptionCA = this.menuService.ca.subscribe((x) => (this.ca = x));
    this.verifyRoute();
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.optinsMenu = null;
    this.ca = null;
    this.subscriptionCA.unsubscribe();
    this.subscriptionMenu.unsubscribe();
  }

  verifyRoute(): void {
    let url = window.location.href;
    url.split('/').includes('customerManagement')
      ? (this.asCustomerManagement = true)
      : (this.asCustomerManagement = false);
  }
}
