<div class="user mr-2">

  <mat-card class="d-none"></mat-card>
  <div mat-card-avatar class="avatar-image mr-2" ></div>
  <!-- <button class=" mr-2"  mat-icon-button (click)="fileInput.click()">
    <mat-icon>account_circle</mat-icon>
    <mat-spinner [diameter]="30" *ngIf="loading"></mat-spinner>

   <input class="d-none" type="file" #fileInput accept="'image/*'" (change)="changePicture($event)"> 
 
  </button> -->
  <div class="data">
    <small class="data-name"><b>{{user?.fullName}}</b></small>
    <small><a class="button" (click)="logout()">Sair</a> </small>
  </div>
</div>