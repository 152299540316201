import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { TitlePageService } from './services/title-page.service';
import { RouteService } from './services/route.service';
import { LayoutService } from './services/layout.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  currURL!: string;
  hasSideLeft!: boolean;
  hasToolbar!: boolean;
  header: any;
  subscriptionToolbar!: Subscription
  subscriptionSideLeft!: Subscription
  subscriptionTitle!: Subscription
  subscriptionRouteService!: Subscription

  menuSubscription!: any;
  menu: any;

  constructor(
    private layoutService: LayoutService,
    private routeService: RouteService,
    private titlePageService: TitlePageService,
    private authService: AuthService) {}

  ngOnInit(): void {
    this.subscriptionTitle = this.titlePageService.header.subscribe(header => { this.header = header })
    this.subscriptionRouteService = this.routeService.currURL.subscribe(currUrl => this.currURL = currUrl)
    this.subscriptionSideLeft = this.layoutService.hasSideLeft.subscribe(hasSideLeft => { this.hasSideLeft = hasSideLeft})
    this.subscriptionToolbar = this.layoutService.hasToolbar.subscribe(hasToolbar => this.hasToolbar = hasToolbar)
  }

  ngOnDestroy(): void {
    this.subscriptionRouteService.unsubscribe();
    this.subscriptionSideLeft.unsubscribe();
    this.subscriptionToolbar.unsubscribe();
    this.subscriptionTitle.unsubscribe();
  }


}
