<div class="toolbar">
  <div class="header-container">
    <div>
      <p class="date">{{ date }}</p>
    </div>
    <div class="container">
      <h1>{{ company }}</h1>
      <button mat-flat-button color="primary" class="recalculate">
        <mat-icon>cached</mat-icon>Recalcular
      </button>
    </div>
    <div class="content">
      <p class="value-liqudated">
        O comissionamento da Broadfactor será 1% do valor liquidado!
      </p>
      <div class="button-delete-download">
        <button
          mat-flat-button
          color="primary"
          [disabled]="
            selection.selected.length < 1 && operationDelete.selected.length < 1
          "
        >
          <mat-icon>delete</mat-icon>Excluir
        </button>
        <button mat-raised-button>
          <mat-icon>download</mat-icon>Baixar extrato
        </button>
      </div>
    </div>
  </div>
  <form [formGroup]="formFilters" (keydown.enter)="$event.preventDefault()">
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>
        <mat-icon>search</mat-icon>
        Cedente
      </mat-label>
      <input
        class="input-autocomplete"
        placeholder="Digite o nome do Cedente"
        matInput
        type="text"
        [matAutocomplete]="auto"
        formControlName="lead"
        (blur)="handleAutocompleteBlur()"
      />
      <mat-autocomplete
        [displayWith]="displayLEAD.bind(this)"
        #auto="matAutocomplete"
      >
        <mat-option *ngFor="let option of leadsOptions" [value]="option">
          {{ option.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>
        <mat-icon>search</mat-icon>
        Sacado
      </mat-label>
      <input
        class="input-autocomplete"
        placeholder="Digite o nome do Sacado"
        matInput
        type="text"
        [matAutocomplete]="sacado"
        formControlName="sacado"
        (blur)="handleAutocompleteSacadoBlur()"
      />
      <mat-autocomplete
        [displayWith]="displaySacado.bind(this)"
        #sacado="matAutocomplete"
      >
        <mat-option *ngFor="let option of sacadoOptions" [value]="option">
          {{ option.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>
        <mat-icon>search</mat-icon>
        Número do Título
      </mat-label>
      <input
        class="input-autocomplete"
        placeholder="Digite o Número do título"
        matInput
        type="text"
        formControlName="titleNumber"
      />
    </mat-form-field>
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>
        <mat-icon>search</mat-icon>
        Número do Aditivo
      </mat-label>
      <input
        class="input-autocomplete"
        placeholder="Digite o Número do aditivo"
        matInput
        type="text"
        formControlName="additiveNumber"
      />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Categoria</mat-label>
      <mat-select formControlName="category">
        <mat-option
          [value]="option.value"
          *ngFor="let option of categoryOptions"
          >{{ option.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <button
      mat-flat-button
      class="submit-button w-btn-filter"
      color="primary"
      (click)="handleSubmit()"
      [disabled]="!formFilters.value"
    >
      <mat-icon>filter_alt</mat-icon>
      <span>Filtrar</span>
    </button>
    <button mat-stroked-button class="submit-button w-btn-clear" (click)="clearFilters()">
      <mat-icon>filter_alt_off</mat-icon>
    </button>
  </form>

  <section
    class="table-container"
    tabindex="0"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="getDetailsFinancialBuyers()"
    [scrollWindow]="false"
  >
    <table 
      mat-table 
      [dataSource]="dataSource" 
      class="mat-elevation-z8" 
    >
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? toggleAllRows() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="cedente">
        <th mat-header-cell *matHeaderCellDef>Cedente</th>
        <td mat-cell *matCellDef="let element">{{ element.lead ?? "N/A" }}</td>
      </ng-container>

      <ng-container matColumnDef="sacado">
        <th mat-header-cell *matHeaderCellDef>Sacado</th>
        <td mat-cell *matCellDef="let element">
          {{ element.drawee ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="valor de face">
        <th mat-header-cell *matHeaderCellDef>Valor de face</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.faceValue | currency : "BRL") ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="valor liquidado">
        <th mat-header-cell *matHeaderCellDef>Valor liquidado</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.paidValue | currency : "BRL") ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="vencimento">
        <th mat-header-cell *matHeaderCellDef>Vencimento</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.expirationDate | date : "dd/MM/YYYY") ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="numero do titulo">
        <th mat-header-cell *matHeaderCellDef>Nº do título</th>
        <td mat-cell *matCellDef="let element">
          {{ element.installmentNumber ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="data">
        <th
          mat-header-cell
          *matHeaderCellDef
          matTooltip="Data de liquidação ou postagem dos documentos"
        >
          Data
          <mat-icon>help</mat-icon>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ (element.publicationDate | date : "dd/MM/YYYY") ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="documento">
        <th mat-header-cell *matHeaderCellDef>Documento</th>
        <td mat-cell *matCellDef="let element">
          {{ getDocumentFinancialName(element.documentType ?? "N/A") }}
        </td>
      </ng-container>
      <ng-container matColumnDef="aditivo">
        <th mat-header-cell *matHeaderCellDef>Aditivo</th>
        <td mat-cell *matCellDef="let element">
          {{ element.additive ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="valor">
        <th mat-header-cell *matHeaderCellDef>Valor</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.entryValue | currency : "BRL") ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="categoria">
        <th mat-header-cell *matHeaderCellDef>Categoria</th>
        <td mat-cell *matCellDef="let element">
          <mat-chip-list>
            <mat-chip
              [ngStyle]="{
                'background-color': getStatusColor(element)
              }"
            >
              {{ getCategoryName(element ?? "N/A") }}
            </mat-chip>
          </mat-chip-list>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      
    </table>

    <div class="h-75 d-flex justify-content-center align-items-center"*ngIf="!dataSource.length && !isLoading">
      <p class="message">Nenhum dado encontrado com os filtros utilizados.</p>
    </div>
    
    <div 
      *ngIf="isLoading"
      class="w-100 h-100 d-flex justify-content-center align-items-center" 
    >
      <mat-spinner></mat-spinner>    
    </div>
  </section>

  <div class="total">
    <p class="value">
      Total <span>{{ totalValue | currency : "BRL" }}</span>
    </p>
  </div>
</div>
