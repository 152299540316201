import { status } from './../../../../interfaces/operacaoFilters';
import { RequiredTypesCompany } from './../../../../utils/documents-company-list';
import { MatDialog } from '@angular/material/dialog';
import { AfterViewInit, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { CustomerDetail } from 'src/app/interfaces/customer';
import { CustomerService } from 'src/app/services/customer.service';
import { LayoutService } from 'src/app/services/layout.service';
import { TitlePageService } from 'src/app/services/title-page.service';
import { CreateBlob } from 'src/app/utils/create-blob';
import { LegalNatureList } from 'src/app/utils/legalNatureList';
import { QualificationList } from 'src/app/utils/qualificationList';
import { environment } from 'src/environments/environment';
import { PlataformLeadsEditComponent } from '../../leads-management/plataform-leads/plataform-leads-edit/plataform-leads-edit.component';
import { AuthService } from 'src/app/services/auth.service';
import { DocumentStatusService } from 'src/app/services/document-status.service';
import { ChangeStatusModalComponent, ChangeStatusModalData } from 'src/app/components/change-status-modal/change-status-modal.component';
import { DocumentStyleList } from 'src/app/utils/documents-company-list';
import { RequiredTypesPartner } from 'src/app/utils/documents-partners-list';

@Component({
  selector: 'app-customer-detail',
  templateUrl:
    '../../../../components/customer-detail/customer-detail.component.html',
  styleUrls: [
    '../../../../components/customer-detail/customer-detail.component.scss',
  ],
})
export class CustomerDetailComponent implements AfterViewInit {
  data!: CustomerDetail | any;
  loading = true;
  customerCNPJ: any;
  error!: boolean;
  showActions: boolean = false;
  url: any;
  loadingDocument: boolean = false;
  isLead = false;
  asPlataformLead = false;
  editedEmail!: string;
  leadSource: any;
  documentStatusStyle = DocumentStyleList.list();
  canModifyStatusDocuments = false;
  requiredTypesCompany = RequiredTypesCompany.list();
  requiredTypesPartner = RequiredTypesPartner.list();

  constructor(
    private customerService: CustomerService,
    private activeRoute: ActivatedRoute,
    private layoutService: LayoutService,
    private titlePageService: TitlePageService,
    private snack: MatSnackBar,
    private domSanitizer: DomSanitizer,
    private dialog: MatDialog,
    private documentStatusService: DocumentStatusService
  ) {
    this.setHeader();
    this.customerCNPJ = activeRoute.snapshot.paramMap.get('cnpj');
    this.get();

    /* Definindo em quais telas podem ser aprovados e reijeitados os documentos a partir da url */
    this.activeRoute.url.subscribe(url =>{
      let typeUser = url[0].path;
      if(typeUser == 'supplier'){
        this.canModifyStatusDocuments = true;
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.layoutService.hideMenu();
    });
  }

  get() {
    this.loading = true;
    this.customerService
      .getCustomerData(this.customerCNPJ)
      .pipe(take(1))
      .subscribe(
        (resp) => {
          resp.partners?.map(
            (partner) =>
              (partner.qualificationCode = this.getQualificationName(
                partner.qualificationCode
              ))
          );
          resp.legalNatureCode = resp.legalNatureCode
            ? this.getLegalNatureName(resp.legalNatureCode)
            : null;
          resp.documents = this.addRequiredTypes(resp.documents, this.requiredTypesCompany);

          resp.partners?.forEach((partner: any) => {
            partner.documents = this.addRequiredTypes(partner.documents, this.requiredTypesPartner);
          });

          this.data = resp;
          this.data.canEdit = false;
          this.loading = false;
          this.customerService.userId.next(resp?.id);
        },
        (err) => {
          this.loading = false;
          this.error = true;
        }
      );
  }

  setHeader(): void {
    this.titlePageService.setHeader({
      title: 'Detalhes',
      subtitle: '',
      locationBack: true,
    });
  }

  getQualificationName(qualificationCode: string) {
    const qualification: any = QualificationList.getList().filter(
      (qualification) => qualification.code === qualificationCode
    );
    return qualification?.length ? qualification[0].name : '-';
  }

  getLegalNatureName(legalNatureCode: string) {
    const legalNature: any = LegalNatureList.getList().filter(
      (legalNature) => legalNature.code === legalNatureCode
    );
    return legalNature?.length ? legalNature[0].name : '-';
  }

  download(cnpj: any, name: string): void {
    this.snack.open('Baixando documento', '');
    const fileName = name;
    fileName.toString();

    this.customerService
      .downloadDocuments(cnpj)
      .pipe(take(1))
      .subscribe(
        (data: any) => {
          CreateBlob.Do(data, 'zip', fileName);
          this.snack.dismiss();
        },
        () => {
          this.snack.open('Ainda não temos os documentos deste cliente', '', {
            duration: 8000,
          });
        }
      );
  }

  getDocument(type: 'company' | 'partner', id: string, path?: any) {
    this.url = '';
    this.loadingDocument = true;
    this.customerService
      .getSignedDocuments(type, id)
      .pipe(take(1))
      .subscribe(
        (signedUrl) => {
          this.url = signedUrl;
          this.loadingDocument = false;
        },
        (err) => {
          this.url = null;
          this.loadingDocument = false;
        }
      );
  }

  changeStatus(status: any, customer: any) {}
  openEditDialog(): void {
    const url =
      environment.register_url +
      '/cedente/cadastro/95ba2beb-ca43-40bf-a622-64527919ef81280e97b3-e485-4aa8-833f-beac6ff89ad1/edit';
    this.data.url = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    this.dialog.open(PlataformLeadsEditComponent, {
      width: '100vw',
      height: '95vh',
      data: this.data,
      panelClass: 'my-custom-dialog-class',
    });
  }
  openEditEmailDialog(inde?: number): void {}
  saveMainMail(): void {}

  approvedOrRejectedDocument(data: any, status: string): void {

    let dataDialog: ChangeStatusModalData = {
      object: data,
      action: (status  == 'APPROVED') ? 'aprovar': 'rejeitar',
      entity: 'documento',
      show: {status: true, observation: status == 'REJECTED'},
      btnColor: (status  == 'APPROVED') ? 'primary': 'warn',
    }


    if(data.status == 'REJECTED' && status == 'APPROVED'){
      this.snack.open('Não é possível aprovar um documento rejeitado!', 'OK', {duration: 10000});
      return;
    }

    if(data.status == 'OUTDATED'){
      this.snack.open('Não é possível alterar o status de um documento Desatualizado!', 'OK', {duration: 10000});
      return;
    }

    const dialogRef = this.dialog.open(ChangeStatusModalComponent, {width: '600px', data:dataDialog});

    dialogRef.afterClosed().subscribe((result) => {
      if (!!result && result.bool) {
        this.documentStatusService.editCompanyDocumentStatus(data.id, {status: status, rejection:result.rejection}, !!data.partner).subscribe({
          next: () => {
            if(status == 'APPROVED'){
              let documentOutdated = (!data.partner)
                                      ? this.data.documents.find((doc: any) => doc.type == data.type && doc.status == 'APPROVED')
                                      : this.data.partners.find((partner: any) => partner.id == data.partner)
                                                .documents.find((doc: any) =>  doc.type == data.type && doc.status == 'APPROVED');

              if(documentOutdated) documentOutdated.status = 'OUTDATED';
            }

            let document = (!data.partner)
                              ? this.data.documents.find((doc: any) => doc.id == data.id)
                              : this.data.partners.find((partner: any) => partner.id == data.partner)
                                        .documents.find((doc: any) => doc.id == data.id);
            document.status = status;



            this.snack.open('Documento atualizado com sucesso', '', {duration: 5000});
          },
          error: () => {
            this.snack.open('Erro ao atualizar documento', '', {duration: 5000});
          }
        });
      }
    });

  }


  addRequiredTypes(documents: any[], requiredTypes:{description: string, type: string}[]){
    requiredTypes.forEach((requiredType) => {
      const document = documents.find((doc) => doc.type === requiredType.type && !!doc.file && ( doc.status === 'PENDING' || doc.status === 'APPROVED'));
      if (!document) {
        documents.push(requiredType);
      }
    });

    return documents;
  }
}

