export class CategoryFinancialList {
  static list() {
    return [
      {
        name: 'Documentos',
        value: {
          category: 'DOC_SIGN',
          type: 'EXPENSE',
        },
      },
      {
        name: 'Comissão',
        value: {
          category: 'COMMISSION',
          type: 'EXPENSE',
        },
      },
      {
        name: 'Crédito Documentos',
        value: {
          category: 'DOC_SIGN',
          type: 'DISCOUNT',
        },
      },
      {
        name: 'Crédito Comissão',
        value: {
          category: 'COMMISSION',
          type: 'DISCOUNT',
        },
      },
    ];
  }
}
