import { Injectable, OnInit } from '@angular/core';
import { NavigationEnd, Router} from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteService{

  currURL = new BehaviorSubject<any>(undefined)

  constructor(private router: Router) { 
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url = event.url.substring(1)
        this.currURL.next(url)
       }  
      }) 
   }
  
}
