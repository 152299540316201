export class DocumentsPartnersList {
  static list() {
    return [
      {
        name: 'CNH ou RG ou Identificação em Entidade de Classe reconhecida',
        files: [],
        type: 'IDENTIFICACAO',
      },
      {
        name: 'Comprovante de Endereço (até 60 dias)',
        files: [],
        type: 'ENDERECO',
      },
      {
        name: 'Imposto de renda do sócio administrador',
        files: [],
        type: 'IMPOSTO_RENDA',
      },
      {
        name: 'Recibo de entrega do imposto de renda',
        files: [],
        type: 'RECIBO_IMPOSTO_RENDA',
      },
      {
        name: 'Outros',
        files: [],
        type: 'OTHERS',
      },
    ];
  }
}


export class RequiredTypesPartner{
  static list(){
    return [
      {
        description: "CNH ou RG ou Identificação em entidade reconhecida",
        type: "IDENTIFICACAO",
        status: "NOT_IMPORTED"
      },
      {
        description: "Um comprovante de Endereço com menos de 90 dias",
        type: "ENDERECO",
        status: "NOT_IMPORTED"
      },
      {
        description: "Ultima declaração de imposto de renda",
        type: "ULTIMO_IMPOSTO_RENDA",
        status: "NOT_IMPORTED"
      }
    ]
  }
}
