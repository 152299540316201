import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  CustomerFinanceDetailInterface,
  discountEntries,
  FinanceInterface,
} from '../interfaces/finance';
import { OperationFilters, OperationLead } from '../interfaces/operacaoFilters';
import { UpdateInstallments } from '../interfaces/updateInstallments.interface';
import { AnalysisOperationsResponseInterface } from '../interfaces/analysis-operations.interface';
import { DocumentsInterface } from '../interfaces/documents-status.interface';
import { ModalAditiveInterface } from '../interfaces/modal-aditive.interface';
import { CompanyDataInterface } from '../interfaces/companyData.interface';
import { CreditInfoInterface } from '../utils/creditInfo.interface';

@Injectable({
  providedIn: 'root',
})
export class operacaoService {
  initialDataFilter: BehaviorSubject<any> = new BehaviorSubject(null);
  defaultOperationFilters: any = {
    status: null,
    responsible: null,
    type: null,
    numberInvoice: null,
    createdAtInit: null,
    createdAtEnd: null,
    dueDateInit: null,
    dueDateEnd: null,
    companyList: [
      {
        cnpj: null,
        type: null,
      },
      {
        cnpj: null,
        type: null,
      },
    ],
  };

  constructor(private http: HttpClient) {}

  getAll(
    pageIndex: number,
    pageSize: number,
    filters: any
  ): Observable<{
    content: OperationLead[];
    totalElements: number;
    last: boolean;
  }> {
    const url =
      environment.operation_api + `open/installment/${pageIndex}/${pageSize}`;
    return this.http.post<{
      content: OperationLead[];
      totalElements: number;
      last: boolean;
    }>(url, filters);
  }
  getById(
    id: any,
    month: any,
    year: any
  ): Observable<CustomerFinanceDetailInterface> {
    let headers = new HttpHeaders();
    headers = headers
      .set('Accept', '*/*')
      .set('Month', `${month}`)
      .set('TenantId', `${id}`)
      .set('Year', `${year}`);
    return this.http.get<CustomerFinanceDetailInterface>(
      `${environment.finance_url}:8091/api/bill/summary/tenant`,
      { headers }
    );
  }

  addDiscount(
    discountData: discountEntries,
    month: string,
    year: string
  ): Observable<discountEntries> {
    let headers = new HttpHeaders();
    headers = headers
      .set('Accept', '*/*')
      .set('Month', `${month}`)
      .set('Year', `${year}`);
    return this.http.post<discountEntries>(
      `${environment.finance_url}:8091/api/account-entry/discount`,
      discountData,
      { headers }
    );
  }
  downloadInvoice(id: any, month: any, year: any) {
    let headers = new HttpHeaders();
    headers = headers
      .set('Accept', '*/*')
      .set('Month', `${month}`)
      .set('TenantId', `${id}`)
      .set('Year', `${year}`);
    return this.http.get(
      environment.finance_url + ':8091/api/bill/summary/tenant/report',
      { headers, responseType: 'blob' }
    );
  }

  updateInstallments(data: UpdateInstallments) {
    return this.http.put(environment.operation_api + 'open/installment', data);
  }

  getUsers() {
    return this.http.get(environment.API_url + 'management-api/users');
  }

  getSearchFomento(
    type: string,
    filter?: string
  ): Observable<{ content: any[] }> {
    const defaultType = 'BUYER';
    return this.http.get<{ content: any[] }>(
      environment.API_url +
        `management-api/customers/legacy/${type ?? defaultType}/0/50/?filter=${
          filter || ''
        }`
    );
  }

  getOperationsInAnalysis(
    filters?: any
  ): Observable<AnalysisOperationsResponseInterface> {
    const url = environment.operation_api + `open/installment/ANALYSIS/0/50`;
    return this.http.post<AnalysisOperationsResponseInterface>(
      url,
      filters || this.defaultOperationFilters
    );
  }

  getDownloadFilesOperation(id: string): any {
    return this.http.get(
      environment.operation_api + `api/invoice/document/download/all/${id}`,
      {
        headers: {
          skip: 'true',
          Authorization:
            'Bearer Cj$rJwr23ZjVYb7z3AfjY6%u&w^@M^WvwHWw@^m#Y4pwgB3Z7jfZ6m5sKZaO!LAfi7!BYyT$MBYx%YrhLRoe7Xag9ojjVKclLtFrR1jE@eqz&#5vVHhwoYQ0IrJHL1%y%nILjYuuE#YV9&mUe2luG&vYZZ%zYrE6jG*M4NQ&x6iB5&5CMpBdqd8H9tLw%VSP0xhu0r',
        },
        responseType: 'blob',
      }
    );
  }

  getDocumentOperation(
    pageIndex: number,
    pageSize: number,
    filters: any
  ): Observable<DocumentsInterface> {
    const url =
      environment.operation_api +
      `open/installment/DOCUMENT/${pageIndex}/${pageSize}`;
    return this.http.post<DocumentsInterface>(url, filters);
  }

  getModalAditive(additive: string): Observable<ModalAditiveInterface[]> {
    const url = environment.aditive_api + `collect/`;
    return this.http.post<ModalAditiveInterface[]>(url, additive);
  }
  getCompanyData(cnpj: string): Observable<CompanyDataInterface> {
    const url =
      environment.register_api + `secure-api/company/getCompanyByCnpj/${cnpj}`;
    return this.http.get<CompanyDataInterface>(url);
  }
  getSignedDocuments(type: 'company' | 'partner', id: string) {
    return this.http.get(
      environment.API_url + 'management-api/documents/leads?path=' + id,
      { responseType: 'text' }
    );
  }

  getCompanyByCnpj(cnpj: string): Observable<CompanyDataInterface> {
    const url =
      environment.register_api + `secure-api/company/getCompanyByCnpj/${cnpj}`;
    return this.http.get<CompanyDataInterface>(url);
  }

  getCreditInfo(companyId: string): Observable<CreditInfoInterface> {
    const url =
      environment.register_api +
      `secure-api/company/${companyId}/config/monthlyPlan`;
    return this.http.get<CreditInfoInterface>(url);
  }
}
