import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { RoutePages } from '../enum/router.enum';
import { RouteService } from './route.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutService implements OnDestroy {

  hasSideLeft = new BehaviorSubject<boolean>(true)
  hasToolbar = new BehaviorSubject<boolean>(true)
  subscribeRoute = new Subscription;

  constructor(private routeService: RouteService) { 
    this.subscribeRoute = this.routeService.currURL.subscribe(currRoute => {
      if (currRoute) { this.currRoute(currRoute) }
    })
  }

  currRoute(route: string): void {
    this.hasSideLeft.next(!Boolean(hideSideLeftList.find((r: any) => r === route)));
    this.hasToolbar.next(!Boolean(hideToolbarList.find((r: any) => r === route)));
  }

  hideMenu(): void {
    this.hasSideLeft.next(false);
  }

  showMenu(): void {
    this.hasSideLeft.next(true);
  }

  hideToolbar(): void {
    this.hasToolbar.next(false);
  }

  showToolbar(): void {
    this.hasToolbar.next(true);
  }


  ngOnDestroy(): void {
    this.subscribeRoute.unsubscribe()
  }
}


  const hideSideLeftList: any = [
    RoutePages.Login,
    RoutePages.RecoverPassword,
    RoutePages.Modules,
  ]
  
  const hideToolbarList: any = [
    RoutePages.Login,
    RoutePages.RecoverPassword,
    RoutePages.Modules,
  ]