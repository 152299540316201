import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalAditiveInterface } from 'src/app/interfaces/modal-aditive.interface';
import { operacaoService } from 'src/app/services/operacao-contaazul.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-number-aditive',
  templateUrl: './modal-number-aditive.component.html',
  styleUrls: ['./modal-number-aditive.component.scss'],
})
export class ModalNumberAditiveComponent implements OnInit {
  DisplayedColumns: string[] = [
    'download',
    'documents',
    'cedente',
    'origem',
    'assinatura',
    'link',
  ];
  additiveNumber!: string;
  nameLead!: string;
  aditiveData!: ModalAditiveInterface[];
  statusColors = {
    ERROR: { color: '#e28c49', name: 'Erro' },
    PARTIAL_SIGNED: { color: '#e28c49', name: 'Parcialmente Assinado' },
    PENDING: { color: '#e28c49', name: 'Pendente' },
    SIGNED: { color: '#32a852', name: 'Assinado' },
    NOT_SIGNED: { color: '#e28c49', name: 'Não Assinado' },
    EXPIRED: { color: '#e28c49', name: 'Expirado' },
    CANCELLED: { color: '#e28c49', name: 'Cancelado' },
    REJECTED: { color: '#e28c49', name: 'Rejeitado' },
  };

  collectDocuments = {
    ADDITIVE: 'Aditivo',
    ENDORSEMENT: 'Endosso',
    MASTER_CONTRACT: 'Contrato Mãe',
    PROMISSORY_NOTE: 'Nota Promissória',
    TRADE_NOTE: 'Duplicata',
    SESSION_LETTER: 'Carta de cessão',
  };

  constructor(
    public operationService: operacaoService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA)
    public data: { additiveNumber: string; nameLead: string }
  ) {
    this.additiveNumber = data.additiveNumber;
    this.nameLead = data.nameLead;
  }

  ngOnInit(): void {
    this.getAdditive(this.additiveNumber);
  }
  getStatusName(status: string) {
    if (Object.keys(this.statusColors).includes(status)) {
      return this.statusColors[status as keyof typeof this.statusColors].name;
    }
    return this.statusColors['PENDING'].name;
  }

  getCollectDocuments(documentType: string) {
    if (Object.keys(this.collectDocuments).includes(documentType)) {
      return this.collectDocuments[
        documentType as keyof typeof this.collectDocuments
      ];
    }
    return '';
  }
  getStatusColor(status: string) {
    if (Object.keys(this.statusColors).includes(status)) {
      return this.statusColors[status as keyof typeof this.statusColors].color;
    }
    return this.statusColors['PENDING'].color;
  }
  getAdditive(additiveNumber: string) {
    this.operationService.getModalAditive(additiveNumber).subscribe((data) => {
      this.aditiveData = data.map((aditive) => ({
        ...aditive,
        link: environment.signer_url + aditive.link,
      }));
    });
  }
  closeModal() {
    this.dialogRef.close();
  }
}
