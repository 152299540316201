export class LegalNatureList {
    static getList() {
        return [
            {code: '201-1', name: 'Empresa Pública'},
            {code: '203-8', name: 'Sociedade de Economia Mista'},
            {code: '204-6', name: 'Sociedade Anônima Aberta'},
            {code: '205-4', name: 'Sociedade Anônima Fechada'},
            {code: '206-2', name: 'Sociedade Empresária Limitada'},
            {code: '207-0', name: 'Sociedade Empresária em Nome Coletivo'},
            {code: '208-9', name: 'Sociedade Empresária em Comandita Simples'},
            {code: '209-7', name: 'Sociedade Empresária em Comandita por Ações'},
            {code: '212-7', name: 'Sociedade em Conta de Participação'},
            {code: '213-5', name: 'Empresário Individual'},
            {code: '214-3', name: 'Cooperativa'},
            {code: '215-1', name: 'Consórcio de Sociedades'},
            {code: '216-0', name: 'Grupo de Sociedades'},
            {code: '217-8', name: 'Estabelecimento, no Brasil, de Sociedade Estrangeira'},
            {code: '219-4', name: 'Estabelecimento, no Brasil, de Empresa Binacional Argentino-Brasileira'},
            {code: '221-6', name: 'Empresa Domiciliada no Exterior'},
            {code: '222-4', name: 'Clube/Fundo de Investimento'},
            {code: '223-2', name: 'Sociedade Simples Pura'},
            {code: '224-0', name: 'Sociedade Simples Limitada'},
            {code: '225-9', name: 'Sociedade Simples em Nome Coletivo'},
            {code: '226-7', name: 'Sociedade Simples em Comandita Simples'},
            {code: '227-5', name: 'Empresa Binacional'},
            {code: '228-3', name: 'Consórcio de Empregadores'},
            {code: '229-1', name: 'Consórcio Simples'},
            {code: '230-5', name: 'Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)'},
            {code: '231-3', name: 'Empresa Individual de Responsabilidade Limitada (de Natureza Simples)'},
            {code: '232-1', name: 'Sociedade Unipessoal de Advogados'},
            {code: '233-0', name: 'Cooperativa de Consumo'},
        ]
    }
}