import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ModalAdmComponent } from './components/modal-adm/modal-adm.component';
import { AuthInterceptorService } from './interceptors/auth-interceptor.service';

import { MatPaginatorIntl } from '@angular/material/paginator';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DataFormat } from '../app/pipes/date.pipe';
import { PercentagePipe } from '../app/pipes/percentage.pipe';
import { getbrazilianPaginatorIntl } from './brazilian-paginator.intl';
import { ChangeStatusModalComponent } from './components/change-status-modal/change-status-modal.component';
import { ModalNumberAditiveComponent } from './components/modal-number-aditive/modal-number-aditive.component';
import { ModalRegisterCedenteComponent } from './components/modal-register-cedente/modal-register-cedente.component';
import { NotifierModalComponent } from './components/notifier-modal/notifier-modal.component';
import { BuyerDetailsFinancialComponent } from './pages/suite/financial/buyer-details-financial/buyer-details-financial.component';
import { BuyerFinancialComponent } from './pages/suite/financial/buyer-financial/buyer-financial.component';
import { ContaazulDetailsFinancialComponent } from './pages/suite/financial/contaazul-details-financial/contaazul-details-financial.component';
import { FinancialContaazulComponent } from './pages/suite/financial/financial-contaazul/financial-contaazul.component';
import { TransferContaAzulComponent } from './pages/suite/financial/transfer-conta-azul/transfer-conta-azul.component';
import { CoreModule } from './modules/core.module';

@NgModule({
  declarations: [
    AppComponent,
    ModalAdmComponent,
    ModalNumberAditiveComponent,
    ModalRegisterCedenteComponent,
    FinancialContaazulComponent,
    TransferContaAzulComponent,
    BuyerFinancialComponent,
    BuyerDetailsFinancialComponent,
    ContaazulDetailsFinancialComponent,
    PercentagePipe,
    DataFormat,
    NotifierModalComponent,
    ChangeStatusModalComponent,
  ],
  imports: [
    InfiniteScrollModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    { provide: MatPaginatorIntl, useValue: getbrazilianPaginatorIntl() },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
