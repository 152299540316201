import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { take, tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserInterface } from '../interfaces/user';
import { User } from '../model/user';
import { RouteService } from './route.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user = new BehaviorSubject<User | null>(null);

  constructor(private http: HttpClient, private router: Router, private routeService: RouteService) {
    this.recoveryDataFromLocalStorage();
  }

  login(mail: string, password: string): Observable<any> {
    let headers = new HttpHeaders(
      {'Content-Type':'application/json; charset=utf-8',
      'Authorization': 'Basic ' + btoa(mail + ':' + password),
      'skip': 'true'
    });

    return this.http
      .post<UserInterface>(environment.API_url + 'open-management-api/auth/login', null, {headers: headers})
      .pipe(
        catchError(this.handleError),
        tap(resData => {
          this.handleAuthentication(resData);
        })
      );
  }
  autoLogin() {
    const json = sessionStorage.getItem('user_IS');
    const user_IS = JSON.parse(json || '{}');

    if (!user_IS) return;
    const loadedUser = this.newUser(user_IS);

    if (loadedUser.accessToken) {
      this.user.next(loadedUser);

      this.routeService.currURL.subscribe(url => {
        if (url === 'login') {
          this.router.navigate(['/modules']);
        }
      });
    }

  }

  resetPassword(mail: string) {
    let headers = new HttpHeaders(
      { 'skip': 'true' });

    return this.http
      .post(environment.API_url + 'open-management-api/auth/reset/password', mail, {headers: headers})
      .pipe(take(1))
  }

  createdPassword(password: string) {
    let headers = new HttpHeaders(
      {'Content-Type':'application/json; charset=utf-8',
      'Basic': btoa(password),
    });

    return this.http
      .put(environment.API_url + 'management-api/users/password/' + this.user.value?.id, null, {headers: headers})
  }

  logout() {
    sessionStorage.removeItem('user_IS');
    this.router.navigate(['/login']);
    location.reload();
    // let headers = new HttpHeaders(
    //   {'Content-Type':'application/json; charset=utf-8',
    //   'Authorization': 'Basic ' + this.user.value?.accessToken});

    //  return this.http
    //   .post(environment.API_url + 'api/auth/logout', null, { headers: headers })
    //   .pipe(take(1))
    //   .subscribe(resp => {
    //       this.user.next(null);
    //       this.router.navigate(['/login']);
    //     }
    //   )
  }

  logoutByInvalidToken() {
    this.user.next(null);
    this.router.navigate(['/login']);
    sessionStorage.removeItem('user_IS');
  }

  isAuthenticated(): boolean {
    return !!sessionStorage.getItem('user_IS');
  }


  private handleAuthentication(user: UserInterface) {
    const _user = this.newUser(user)

    this.user.next(_user);
    sessionStorage.setItem('user_IS', JSON.stringify(_user));
    localStorage.clear();
  }

  private newUser(user: UserInterface) {
    const _user = new User(
      user.fullName,
      user.mail,
      user.id,
      user.accessToken,
      user.userType,
      user.enable,
      user.permissions,
      user.pictureUrl,
    );

    return _user;
  }

  changeUser(user: any) {
    sessionStorage.getItem('user_IS');
    sessionStorage.setItem('user_IS', JSON.stringify(user));
    this.user.next(user);
  }

  private handleError(errorRes: HttpErrorResponse) {
    let errorMessage;

    if (!errorRes.error || !errorRes.error.error) {
      return throwError(errorMessage);
    }

    switch (errorRes.error.message) {
      case 'MAIL_DO_NOT_EXISTS':
        errorMessage = 'O e-mail informado não está cadastrado.';
        break;
      case 'INVALID_PASSWORD':
        errorMessage = 'O e-mail informado não está cadastrado ou a senha está incorreta. Verifique os dados digitados e tente entrar novamente.';
        break;
      case 'INVALID_CNPJ':
        errorMessage = 'O CNPJ informado é inválido.';
        break;

      default:
        errorMessage = null
    }

    return throwError(errorMessage);
  }

  public recoveryDataFromLocalStorage(): void {
    let data: any = sessionStorage.getItem('user_IS');
    if (data) {
      data = JSON.parse(data);
      this.user.next(data);
    }
  }

}
