import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat',
})
export class DataFormat implements PipeTransform {
  transform(date?: string, ...args: unknown[]): string | void {
    if (date === undefined || date === null) return;

    let formatted = date.split('_').reverse().join('/');

    return formatted;
  }
}
