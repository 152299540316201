import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TitlePageService {
  header = new BehaviorSubject<{
    title: string;
    subtitle: string;
    locationBack?: boolean;
    hasSearch?: boolean;
  }>({ title: '', subtitle: '' });

  constructor() {}

  setHeader(value: {
    title: string;
    subtitle: string;
    locationBack?: boolean;
    hasSearch?: boolean;
    previousRoute?: string;
  }) {
    return this.header.next(value);
  }
}
