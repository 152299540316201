import { Pipe } from '@angular/core';
@Pipe({
  name: 'getSubTitle'
})
export class GetSubTitlePipe {
  transform(length: any): string {
    if (!length) {
      return 'Nenhum documento importado.';
    }
    if (length === 1) {
      return '1 documento importado.';
    }
    if (length > 1) {
      return `${length} documentos importados`;
    }
    return '-';
  }
}
