 
 <mat-toolbar *ngIf="hasToolbar">
      <div class="d-flex align-items-center w-100 ">
         
         <div class="d-flex align-items-center w-100">

            <button  class="mr-2"  mat-icon-button  (click)="goBack()" >  <mat-icon>arrow_back</mat-icon> </button>
            <div class="header">
               <span class="header__title">{{ header.title }}</span>
               <span class="header__subtitle">{{ header.subtitle }}</span>
            </div>
        
         </div>

         <div  *ngIf="header.hasSearch">
            <mat-form-field class="example-form-field" appearance="outline">
               <mat-icon matPrefix>search</mat-icon>
               <input matInput type="text" [ngModel]="searchModel" (ngModelChange)="updateSearchModel($event)">
               <button *ngIf="searchModel" matSuffix mat-icon-button aria-label="Clear" (click)="updateSearchModel(null)">
                 <mat-icon>close</mat-icon>
               </button>
             </mat-form-field>
         </div>
      </div>
   </mat-toolbar>