import { Pipe } from '@angular/core';

@Pipe({
  name: 'getName',
})
export class GetNamePipe {
  transform(file: string): string {
    const splitedArray = file.split('/');
    if (splitedArray) {
      return splitedArray[splitedArray.length - 1];
    } else {
      return '-';
    }
  }
}
