<div class="toolbar">
  <form [formGroup]="formFilters" (keydown.enter)="$event.preventDefault()">
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>Mês/Ano</mat-label>
      <input matInput [matDatepicker]="dp" formControlName="date" />
      <mat-hint>MM/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
      <mat-datepicker
        #dp
        startView="multi-year"
        (monthSelected)="setMonthAndYear($event, dp)"
        panelClass="example-month-picker"
      >
      </mat-datepicker>
    </mat-form-field>

    <mat-form-field
      class="form-field"
      appearance="outline"
      class="filter-cnpj-social"
    >
      <mat-label>
        <mat-icon>search</mat-icon>
        Pesquisar por CNPJ/Razão Social
      </mat-label>
      <input
        class="input-autocomplete"
        placeholder="Digite o CNPJ ou a Razão Social"
        matInput
        type="text"
        [matAutocomplete]="auto"
        formControlName="tenant"
        (blur)="handleAutocompleteBlur()"
      />
      <mat-autocomplete
        [displayWith]="displayBuyer.bind(this)"
        #auto="matAutocomplete"
      >
        <mat-option *ngFor="let option of buyers" [value]="option">
          {{ option.name }}</mat-option
        >
      </mat-autocomplete>
    </mat-form-field>

    <button
      mat-flat-button
      class="submit-button w-btn-filter"
      color="primary"
      (click)="handleSubmit()"
    >
      <mat-icon class="mr-2">filter_alt</mat-icon>
      <span>Filtrar</span>
    </button>
    <button 
      mat-stroked-button 
      class="submit-button w-btn-clear" 
      (click)="clearFilters()"
    >
      <mat-icon>filter_alt_off</mat-icon>
    </button>
  </form>

  <section
    class="table-container"
    tabindex="0"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="getFinancialBuyers()"
    [scrollWindow]="false"
  >
    <table 
      mat-table 
      [dataSource]="dataSource" 
      class="mat-elevation-z8" 
    >
      <ng-container matColumnDef="razaoSocial">
        <th mat-header-cell *matHeaderCellDef>Razão Social</th>
        <td mat-cell *matCellDef="let element">
          {{ element.company ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="document">
        <th mat-header-cell *matHeaderCellDef>Documentos Digitais</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.totalDocSign | currency : "BRL") ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="comissionamento">
        <th mat-header-cell *matHeaderCellDef>Comissionamento</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.totalCommissions | currency : "BRL") ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Valor Total</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.totalExpenses | currency : "BRL") ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="details">
        <th mat-header-cell *matHeaderCellDef>Detalhes</th>
        <td mat-cell *matCellDef="let element">
          <button mat-flat-button (click)="navigateTo(element)">
            <mat-icon>visibility</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>  
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <div class="h-75 d-flex justify-content-center align-items-center" *ngIf="!dataSource.length && !isLoading">
      <p class="message">Nenhum dado encontrado com os filtros utilizados.</p>
    </div>
    <div 
      *ngIf="isLoading"
      class="w-100 h-100 d-flex justify-content-center align-items-center" 
    >
      <mat-spinner></mat-spinner>    
    </div>
  </section>
</div>
