import { Component, OnInit } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import moment, { Moment } from 'moment';
import { FormControl, FormGroup } from '@angular/forms';
import {
  FilterTransferContaAzulInterface,
  TransferContaAzulInterface,
} from 'src/app/interfaces/transfer-conta-azul.interface';
import { BuyerFinancialService } from 'src/app/services/buyer-financial.service';
import { TitlePageService } from 'src/app/services/title-page.service';
import { FinancialService } from 'src/app/services/financial.service';

@Component({
  selector: 'app-transfer-conta-azul',
  templateUrl: './transfer-conta-azul.component.html',
  styleUrls: ['./transfer-conta-azul.component.scss'],
})
export class TransferContaAzulComponent implements OnInit {
  formFilters!: FormGroup;

  displayedColumns: string[] = [
    'date',
    'compensatory-fee',
    'liquidity-ratio',
    'rebut',
    'liquidated-value',
    'comission-month',
    'commission-payable',
    'details',
  ];
  dataSource: TransferContaAzulInterface[] = [];
  isLoading = true;
  setMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.formFilters.controls['date'].value ?? moment();
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.formFilters.controls['date'].setValue(ctrlValue);
    datepicker.close();
  }

  constructor(
    private router: Router,
    private buyerFinancialService: BuyerFinancialService,
    private titlePageService: TitlePageService,
    private financialService: FinancialService
  ) {
    this.setHeader();
    this.formFilters = new FormGroup({
      date: new FormControl(moment()),
    });
  }
  setHeader(): void {
    this.titlePageService.setHeader({
      title: 'Módulos',
      subtitle: '',
      locationBack: false,
    });
  }

  getTransferContaAzul() {
    this.dataSource = [];
    this.isLoading = true;
    const date = moment(this.formFilters.controls['date'].value).toISOString();
    const formatedDate = new Intl.DateTimeFormat('pt-br', {
      month: 'numeric',
      year: 'numeric',
    }).format(new Date(date));

    this.financialService
      .getTransferContaAzul(formatedDate)
      .subscribe((data) => {
        this.isLoading = false;

        if(!this.isEmptyTable(data)) this.dataSource = [data];
      });
  }
  navigateTo(item: TransferContaAzulInterface) {
    const date = moment(this.formFilters.controls['date'].value).toISOString();
    this.buyerFinancialService.setDate(date);
    this.router.navigate(['/financial/contaAzul/repasse']);
  }

  ngOnInit(): void {
    this.getTransferContaAzul();
  }

  isEmptyTable(data: any): boolean{
    for (const key in data) {
      if(data[key] !== null) return false;
    }

    return true;
  }
}
