export enum RoutePages {
  Root = '',
  Login = 'login',
  RecoverPassword = 'recoverPassword',
  Modules = 'modules',

  AntecipaGov = 'antecipagov',
  AntecipaGov_PROPOSALS = 'antecipagov/proposals',
  AntecipaGov_QUOTES = 'antecipagov/quotes',
  AntecipaGov_OPERATION = 'antecipagov/operation',

  CustomerManagement = 'customerManagement',
  CustomerManagement_FI = 'customerManagement/financial-instituition',
  CustomerManagement_FACTORING = 'customerManagement/fomento',
  CustomerManagement_FIDC = 'customerManagement/fidc',
  CustomerManagement_SUPPLIER = 'customerManagement/supplier',
  CustomerManagement_MY_CUSTOMER = 'customerManagement/my-customers',
  CustomerManagement_LEADS = 'customerManagement/leads',
  CustomerManagement_PLATAFORM = 'customerManagement/plataform',

  UserManagement = 'userManagement',
  UserManagement_LIST = 'userManagement/list',
  UserManagement_CREATE = 'userManagement/create',

  Leads = 'leads',
  LeadsJaer = 'leads/jaer',
  LeadsPlataform = 'leads/plataform',
  LeadsBom = 'leads/bom',
  LeadsAntecipagov = 'leads/antecipagov',
  LeadsCA = 'leads/contaAzul',
  LeadsRejeitados = 'leads/rejeitados',
  Integrations = 'leads/integracoes',

  HumanResources = 'humanResources',
  HumanResources_LIST = 'humanResources/list',
  HumanResources_CREATE = 'humanResources/new',

  Serasa = 'serasa',
  SerasaUpload = 'serasa/uploadFile',
  SerasaDocument = 'serasa/generateRemittance',
  SerasaGenerateConciliation = 'serasa/generateConciliation',
  Operacao = 'operacao',
  Financial = 'financial',
  FinancialDetail = 'FinancialDetail',
}

export class RouteUtil {
  //   public static currentRoute(page: string): RoutePages{
  //       const keys = Object.keys(RoutePages).filter(k => typeof RoutePages[k as any] === "string")
  //       const values = keys.filter(k => {
  //         if (RoutePages[k as any] == page)
  //           return k
  //       })
  //       return RoutePages[values[0]]
  //   }
}
