import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { Route, Router } from '@angular/router';
import moment, { Moment } from 'moment';
import { of } from 'rxjs';
import { debounceTime, switchMap, take } from 'rxjs/operators';
import {
  BuyerFinancialFilterInterface,
  BuyerFinancialInterface,
} from 'src/app/interfaces/buyer-financial.interface';
import { BuyerFinancialService } from 'src/app/services/buyer-financial.service';
import { FinancialService } from 'src/app/services/financial.service';
import { TitlePageService } from 'src/app/services/title-page.service';

@Component({
  selector: 'app-buyer-financial',
  templateUrl: './buyer-financial.component.html',
  styleUrls: ['./buyer-financial.component.scss'],
})
export class BuyerFinancialComponent implements OnInit {
  formFilters!: FormGroup;
  pageIndex = 0;
  pageSize = 20;
  isLoading = true;
  constructor(
    private router: Router,
    private financialService: FinancialService,
    private buyerFinancialService: BuyerFinancialService,
    private titlePageService: TitlePageService
  ) {
    this.setHeader();
    this.formFilters = new FormGroup({
      date: new FormControl(moment()),
      tenant: new FormControl(null),
    });
    this.formFilters.controls.tenant.valueChanges
      .pipe(
        debounceTime(500),
        switchMap((data: string): any => {
          if (
            typeof data !== 'object' &&
            data?.trimStart() !== '' &&
            data?.trimEnd() !== '' &&
            data?.length > 2
          ) {
            this.getFilterSearchBuyers(data);
          }

          return of('');
        })
      )
      .subscribe();
  }
  setHeader(): void {
    this.titlePageService.setHeader({
      title: 'Módulos',
      subtitle: '',
      locationBack: false,
    });
  }

  displayedColumns: string[] = [
    'razaoSocial',
    'document',
    'comissionamento',
    'amount',
    'details',
  ];
  dataSource: BuyerFinancialInterface[] = [];
  buyers: any[] = [];
  getFilterSearchBuyers(filter?: string) {
    this.financialService
      .getFilterSearchBuyers(filter)
      .pipe(take(1))
      .subscribe((res) => {
        this.buyers = res.content;
      });
  }
  displayBuyer(tenant?: any) {
    return tenant?.id
      ? this.buyers.find((_: any) => _.id === tenant.id).name
      : undefined;
  }
  lastPage = false;

  getFinancialBuyers() {
    const tenant = this.formFilters.controls['tenant'].value;
    const date = moment(this.formFilters.controls['date'].value).toISOString();
    const formatedDate = new Intl.DateTimeFormat('pt-br').format(
      new Date(date)
    );
    const requestData: BuyerFinancialFilterInterface = {
      date: formatedDate,
      tenantId: tenant?.id,
    };
    if (!this.lastPage) {
      this.financialService
        .getFinancialBuyers(this.pageIndex, this.pageSize, requestData)
        .subscribe((data) => {
          this.isLoading = false;
          this.dataSource = this.dataSource.concat(data.content);
          if (!data.last) this.pageIndex = this.pageIndex + 1;
          if (data.last) this.lastPage = true;
        });
    }
  }
  clearFilters() {
    this.formFilters.reset({ date: moment() });
    this.handleSubmit();
  }
  handleSubmit() {
    this.dataSource = [];
    this.lastPage = false;
    this.pageIndex = 0;
    this.isLoading = true;
    this.getFinancialBuyers();
  }

  handleAutocompleteBlur() {
    const tenant = this.formFilters.controls['tenant'].value;
    if (!tenant.id) {
      const date = moment(this.formFilters.controls['date'].value);
      this.formFilters.reset({ date });
    }
  }

  setMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.formFilters.controls['date'].value ?? moment();
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.formFilters.controls['date'].setValue(ctrlValue);
    datepicker.close();
  }

  navigateTo(item: BuyerFinancialInterface) {
    const date = moment(this.formFilters.controls['date'].value).toISOString();
    this.buyerFinancialService.setDate(date);
    this.buyerFinancialService.setTenantId(item.tenantId);
    this.buyerFinancialService.setCompany(item.company);

    this.router.navigate(['/financial/contaAzul/fomento']);
  }

  ngOnInit(): void {
    this.getFinancialBuyers();
  }
}
