import { CommonModule, CurrencyPipe } from '@angular/common';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { BlankslateComponent } from '../components/blankslate/blankslate.component';
import { CardComponent } from '../components/card/card.component';
import { MenuComponent } from '../components/layout/menu/menu.component';
import { ToolbarComponent } from '../components/layout/toolbar/toolbar.component';
import { LoadingComponent } from '../components/loading/loading.component';
import { UserAvatarComponent } from '../components/user-avatar/user-avatar.component';
import { AppsModuleComponent } from '../pages/apps-module/apps-module.component';
import { LoginComponent } from '../pages/login/login.component';
import { AsideComponent } from './../components/aside/aside.component';
import { AngularMaterialModule } from './angular-material.module';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { InputMaskModule } from '@ngneat/input-mask';
import {
  CURRENCY_MASK_CONFIG,
  CurrencyMaskConfig,
  CurrencyMaskModule,
} from 'ng2-currency-mask';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AutocompleteFilterComponent } from '../components/autocomplete-filter/autocomplete-filter.component';
import { DocViewerComponent } from '../components/document-viewer/document-viewer.component';
import { TimelineComponent } from '../components/timeline/timeline.component';
import { RecoverPasswordComponent } from '../pages/login/recover-password/recover-password.component';
import { CustomerDetailComponent } from '../pages/suite/customer-management/customer-detail/customer-detail.component';
import { CustomerTimelineComponent } from '../pages/suite/customer-management/customer-timeline/customer-timeline.component';

registerLocaleData(localePt, 'pt');
export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
};

@NgModule({
  declarations: [
    DocViewerComponent,
    MenuComponent,
    ToolbarComponent,
    LoginComponent,
    AppsModuleComponent,
    UserAvatarComponent,
    CardComponent,
    LoadingComponent,
    BlankslateComponent,
    RecoverPasswordComponent,
    TimelineComponent,
    AsideComponent,
    CustomerDetailComponent,
    CustomerTimelineComponent,
  ],
  imports: [
    AngularMaterialModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    NgxMaskModule.forRoot(),
    CurrencyMaskModule,
    InfiniteScrollModule,
    InputMaskModule,
  ],
  exports: [
    AngularMaterialModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MenuComponent,
    ToolbarComponent,
    LoginComponent,
    AppsModuleComponent,
    UserAvatarComponent,
    CardComponent,
    NgxMaskModule,
    LoadingComponent,
    BlankslateComponent,
    TimelineComponent,
    AsideComponent,
    DocViewerComponent,
    CustomerDetailComponent,
    CustomerTimelineComponent,
    CurrencyMaskModule,
    InfiniteScrollModule,
    InputMaskModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    CurrencyPipe,
  ],
})
export class CoreModule {}
