import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserInterface } from '../../interfaces/user';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide = true;
  hasError = false;
  errorMessage = '';
  showForgetPasswordPage = false;
  formLogin: FormGroup;

  constructor(private fb: FormBuilder, private router: Router, private authService: AuthService) {
    this.formLogin = this.fb.group({
      mail: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
   }

  ngOnInit() {

  }

  login() {
    const mail = this.formLogin.get('mail')?.value;
    const password = this.formLogin.get('password')?.value;
    this.authService.login(mail, password).subscribe(
      (user: UserInterface) => {
        user.firstAccess ? this.router.navigate(['/recoverPassword']) : this.router.navigate(['/modules']);
      },
      errorMessage => {
        this.hasError = true;
        this.errorMessage = errorMessage ? errorMessage : 'O e-mail informado não está cadastrado ou a senha está incorreta. Verifique os dados digitados e tente entrar novamente.'
      }
    );
  }
}
