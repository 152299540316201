<div class="toolbar">
  <form [formGroup]="formFilters">
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>Mês/Ano</mat-label>
      <input matInput [matDatepicker]="dp" formControlName="date" />
      <mat-hint>MM/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
      <mat-datepicker
        #dp
        startView="multi-year"
        (monthSelected)="setMonthAndYear($event, dp)"
        panelClass="example-month-picker"
      >
      </mat-datepicker>
    </mat-form-field>

    <button
      mat-flat-button
      class="submit-button w-btn-filter" 
      color="primary"
      (click)="getTransferContaAzul()"
    >
      <mat-icon>filter_alt</mat-icon>
      <span>Filtrar</span>
    </button>
  </form>

  <div class="table-container">
    <table 
      mat-table 
      [dataSource]="dataSource" 
      class="mat-elevation-z8"
    >
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Mês/Ano</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.monthYear | dateFormat) ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="compensatory-fee">
        <th mat-header-cell *matHeaderCellDef>Taxa compensatória</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.compensatoryFee | percentage) ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="liquidity-ratio">
        <th mat-header-cell *matHeaderCellDef>Índice de liquidez</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.liquidityPercentage | percentage) ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="rebut">
        <th mat-header-cell *matHeaderCellDef>% de Rebate</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.rebatePercentage | percentage) ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="liquidated-value">
        <th mat-header-cell *matHeaderCellDef>Valor Liquidado</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.valueSettled | currency : "BRL") ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="comission-month">
        <th mat-header-cell *matHeaderCellDef>Comissão do mês</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.commission | currency : "BRL") ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="commission-payable">
        <th mat-header-cell *matHeaderCellDef>Comissão a pagar</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.commissionPayable | currency : "BRL") ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="details">
        <th mat-header-cell *matHeaderCellDef>Detalhes</th>
        <td mat-cell *matCellDef="let element">
          <button mat-flat-button (click)="navigateTo(element)">
            <mat-icon>visibility</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    
    <div class="h-75 d-flex justify-content-center align-items-center" *ngIf="!isLoading && !dataSource.length">
      <p class="message">Nenhum dado encontrado com os filtros utilizados.</p>
    </div>

    <div 
      *ngIf="isLoading"
      class="w-100 h-100 d-flex justify-content-center align-items-center" 
    >
      <mat-spinner></mat-spinner>    
    </div>
  </div>
</div>
