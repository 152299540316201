export class User {
   constructor(
    public fullName: string,
    public mail: string,
    public id: string,
    public accessToken: string,
    public userType: string,
    public enable: boolean,
   public permissions: any,
   public pictureUrl: any
   ) { }
}