 <mat-sidenav-container class="container-sidenav">
  <mat-sidenav class="sidenav" mode="side" [(opened)]="hasSideLeft" *ngIf="hasSideLeft">
    <app-menu></app-menu>
  </mat-sidenav>

  <mat-sidenav-content class="content-sidenav">

      <app-toolbar [hasToolbar]="hasToolbar" [header]="header" [hasToolbar]="hasToolbar">
        <ng-container [ngTemplateOutlet]="menu"></ng-container>
      </app-toolbar>

      <div class="container-outlet">
        <router-outlet></router-outlet>
      </div>
  </mat-sidenav-content>

</mat-sidenav-container>
