<div class="main-container">
  <div class="close-container">
    <button mat-icon-button class="icon-close" (click)="closeModal()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="company-details-container">
    <div class="company-detail-container">
      <div class="dados-operacao">
        <p>Dados da operação</p>
      </div>
      <mat-toolbar>
        <span class="example-spacer">
          <p>{{ companyData?.name }}</p>
        </span>
      </mat-toolbar>
      <div class="cnpj-empresa">
        <p>{{ formatedCnpjLabel(companyData.cnpj) }}</p>
      </div>
    </div>

    <div
      class="company-detail-container credit-card-container"
      *ngIf="creditInfo"
    >
      <div class="card">
        <div class="card-title-container">
          <mat-icon>bar_chart</mat-icon>
          <div class="title-content">
            <span class="card-title">Limite de crédito do cedente</span>
            <span class="card-value">{{
              creditInfo?.creditLimit | currency : "BRL"
            }}</span>
          </div>
        </div>
      </div>
      <div class="card comission-content">
        <div class="card-title-container">
          <mat-icon>payments</mat-icon>
          <div class="title-content">
            <span class="card-title">Crédito disponível do cedente</span>
            <span class="card-value">
              {{ creditInfo?.remainingCredit | currency : "BRL" }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="spinner-container" *ngIf="!companyData || !sacadoData">
    <mat-spinner class="spinner" color="primary"></mat-spinner>
  </div>
  <mat-tab-group #tabGroup color="accent" *ngIf="companyData && sacadoData">
    <mat-tab label="Dados do Cedente">
      <ng-template mat-tab-label>Dados do Cedente</ng-template>
      <ng-template matTabContent>
        <div class="tab-content-container">
          <mat-expansion-panel>
            <mat-expansion-panel-header class="dropdown-header">
              <div class="dropdown-label-container">
                <p class="dropdown-label">Empresa</p>
              </div>
            </mat-expansion-panel-header>
            <div class="list-item">
              <span class="list-item__title">Nome comercial</span>
              <span class="list-item__text">{{ companyData.tradeName }}</span>
            </div>
            <hr />

            <div class="list-item">
              <span class="list-item__title">Site</span>
              <span class="list-item__text">{{ companyData.website }}</span>
            </div>
            <hr />
            <div class="list-item">
              <span class="list-item__title">Email</span>
              <span class="list-item__text">{{
                companyData.mailInformed
              }}</span>
            </div>
            <hr />
            <div class="list-item">
              <span class="list-item__title">Regime</span>
              <span class="list-item__text">{{ companyData.regime }}</span>
            </div>
            <hr />
            <div class="list-item">
              <span class="list-item__title">Data de Abertura</span>
              <span class="list-item__text">{{ companyData.openedOn }}</span>
            </div>
            <hr />
            <div class="list-item">
              <span class="list-item__title">Natureza Legal</span>
              <span class="list-item__text">{{ companyData.legalNature }}</span>
            </div>
            <hr />
            <div class="list-item">
              <span class="list-item__title">Unidade</span>
              <span class="list-item__text">{{ companyData.unit }}</span>
            </div>
            <hr />
            <div class="list-item">
              <span class="list-item__title">Faturamento Anual</span>
              <span class="list-item__text">{{
                companyData.annualBilling | currency : "BRL"
              }}</span>
            </div>
            <hr />
            <div class="list-item">
              <span class="list-item__title">Capital Social</span>
              <span class="list-item__text">{{
                companyData.sharedCapital | currency : "BRL"
              }}</span>
            </div>
            <hr />
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header class="dropdown-header">
              <div class="dropdown-label-container">
                <p class="dropdown-label">Contatos</p>
              </div>
            </mat-expansion-panel-header>

            <div class="list-item" *ngFor="let contact of companyData.contacts">
              <div class="list-item">
                <span class="list-item__title">Nome</span>
                <span class="list-item__text">{{ contact.name }}</span>
              </div>
              <hr />
              <div class="list-item">
                <span class="list-item__title">Telefone</span>
                <span class="list-item__text">{{ contact.phone }}</span>
              </div>
              <hr />
              <div class="list-item">
                <span class="list-item__title">Email</span>
                <span class="list-item__text">{{ contact.mail }}</span>
              </div>
              <hr />
              <br />
              <br />
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header class="dropdown-header">
              <div class="dropdown-label-container">
                <p class="dropdown-label">Endereço</p>
              </div>
            </mat-expansion-panel-header>
            <div *ngFor="let address of companyData.address">
              <div class="list-item">
                <span class="list-item__title">CEP</span>
                <span class="list-item__text">{{ address.postalCode }}</span>
              </div>
              <hr />
              <div class="list-item">
                <span class="list-item__title">Endereço</span>
                <span class="list-item__text">{{
                  getAddressInfo(address)
                }}</span>
              </div>
              <hr />
              <div class="list-item">
                <span class="list-item__title">Complemento</span>
                <span class="list-item__text">{{ address.streetSuffix }}</span>
              </div>
              <hr />
              <div class="list-item">
                <span class="list-item__title">Bairro</span>
                <span class="list-item__text">{{ address.district }}</span>
              </div>
              <hr />
              <div class="list-item">
                <span class="list-item__title">Cidade</span>
                <span class="list-item__text">{{ address.city }}</span>
              </div>
              <hr />
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header class="dropdown-header">
              <div class="dropdown-label-container">
                <p class="dropdown-label">Bancos</p>
              </div>
            </mat-expansion-panel-header>
            <div *ngFor="let banks of companyData.banks">
              <div class="list-item">
                <span class="list-item__title">Conta</span>
                <span class="list-item__text">{{ banks.account }}</span>
              </div>
              <hr />
              <div class="list-item">
                <span class="list-item__title">Agência</span>
                <span class="list-item__text">{{ banks.agency }}</span>
              </div>
              <hr />
              <div class="list-item">
                <span class="list-item__title">Proposito</span>
                <span class="list-item__text">{{ banks.purpose }}</span>
              </div>
              <hr />
              <div class="list-item">
                <span class="list-item__title">Nome</span>
                <span class="list-item__text">{{ banks.name }}</span>
              </div>

              <hr />
              <div class="list-item">
                <span class="list-item__title">Tipo</span>
                <span class="list-item__text">{{ banks.type }}</span>
              </div>

              <hr />
              <div class="list-item">
                <span class="list-item__title">Pix</span>
                <span class="list-item__text">{{ banks.pix }}</span>
              </div>

              <hr />
              <br />
              <br />
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header class="dropdown-header">
              <div class="dropdown-label-container">
                <p class="dropdown-label">Atividade Econômica</p>
              </div>
            </mat-expansion-panel-header>
            <div
              *ngFor="let economicsActivities of companyData.economicActivities"
            >
              <span class="economic-activity-label"
                >{{ economicsActivities.code }} -
              </span>
              <span class="economic-activity-label">{{
                economicsActivities.description
              }}</span>
              <hr />
            </div>
          </mat-expansion-panel>
        </div>
        <mat-expansion-panel>
          <mat-expansion-panel-header class="dropdown-header">
            <div class="dropdown-label-container">
              <p class="dropdown-label">Quadro Societário</p>
            </div>
          </mat-expansion-panel-header>
          <div *ngFor="let partners of companyData.partners">
            <span class="list-item__title">Nome</span>
            <span class="list-item__text">{{ partners.name }}</span>
            <hr />
            <span class="list-item__title">CPF</span>
            <span class="list-item__text">{{ partners.cpfCnpj }}</span>
            <hr />
            <span class="list-item__title">Telefone</span>
            <span class="list-item__text">{{ partners.phone }}</span>
            <hr />
            <span class="list-item__title">Qualificação</span>
            <span class="list-item__text">{{ partners.qualification }}</span>
            <hr />
            <br />
            <br />
          </div>
        </mat-expansion-panel>
      </ng-template>
    </mat-tab>

    <mat-tab label="Documentos">
      <ng-template mat-tab-label> Documentos </ng-template>
      <ng-template matTabContent>
        <div class="main-pdf-container">
          <div class="document-container">
            <div
              class="document-row-container"
              *ngFor="let doc of documents"
              matTooltip="{{ !doc.file ? 'documento não importado' : '' }}"
              (click)="getDocument('company', doc)"
            >
              <mat-icon mat-list-icon>insert_drive_file</mat-icon>
              <div mat-line>{{ doc.description }}</div>
            </div>
          </div>
          <div class="pdf-view">
            <div class="image" *ngIf="!currentDoc">
              <img
                width="300px"
                src="../../../assets/undraw_selection_re_ycpo.svg"
                alt=""
              />
              <p>Selecione algum documento para visualizar.</p>
            </div>
            <doc-viewer-angular
              *ngIf="currentDoc"
              [url]="url"
              [urlTypeFile]="currentDoc?.file"
              [fileName]="currentDoc?.description"
              [loading]="loadingDocument"
            >
            </doc-viewer-angular>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab label="Dados do Sacado">
      <ng-template mat-tab-label> Dados do Sacado </ng-template>
      <ng-template matTabContent>
        <div class="tab-content-container">
          <mat-expansion-panel>
            <mat-expansion-panel-header class="dropdown-header">
              <div class="dropdown-label-container">
                <p class="dropdown-label">Empresa</p>
              </div>
            </mat-expansion-panel-header>
            <div class="list-item">
              <span class="list-item__title">Razão Social</span>
              <span class="list-item__text">{{ sacadoData.name }}</span>
            </div>
            <hr />
            <div class="list-item">
              <span class="list-item__title">CNPJ</span>
              <span class="list-item__text">{{ sacadoData.cnpj }}</span>
            </div>
            <hr />
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header class="dropdown-header">
              <div class="dropdown-label-container">
                <p class="dropdown-label">Contatos</p>
              </div>
            </mat-expansion-panel-header>
            <div class="list-item" *ngFor="let contact of sacadoData.contacts">
              <div class="list-item">
                <span class="list-item__title">Nome</span>
                <span class="list-item__text">{{ contact.name }}</span>
              </div>
              <hr />
              <div class="list-item">
                <span class="list-item__title">Telefone</span>
                <span class="list-item__text">{{ contact.phone }}</span>
              </div>
              <hr />
              <div class="list-item">
                <span class="list-item__title">Email</span>
                <span class="list-item__text">{{ contact.mail }}</span>
              </div>
              <hr />
              <br />
              <br />
            </div>
          </mat-expansion-panel>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
