<div class="dialog-container">
  <div class="header-container">
    <mat-icon (click)="!changedForm ? closeDialog() : openConfirmModal()"  class="mat-18">close</mat-icon>
  </div>
  <mat-tab-group (selectedTabChange)="resetAlertActions()" mat-align-tabs="center" [disableRipple]="false">

    <form [formGroup]="form">
    <mat-tab *ngIf="data?.type === 'LEAD' || data?.type === 'SACADO'" label="Operação">
      <ng-template mat-tab-label>
        <p>Operação</p>
        <mat-icon [ngClass]="validOperation ? 'checked' : 'error'">{{validOperation ? 'check_circle_outline' : 'highlight_off'}}</mat-icon>
      </ng-template>
      <div class="select-operation-container">
        <div class="custumer-data">
          <b class="customer-name">{{ data?.name }}</b>
          <small> <b>CNPJ</b> - {{ data?.cnpj | mask: "CPF_CNPJ" }}</small>
        </div>
        <mat-form-field appearance="outline">
          <mat-label>Tipo de Operação</mat-label>
          <mat-select multiple required formControlName="selectedOptionsSacado" (selectionChange)="addSacadoOption(); setChangedForm()">
            <mat-option
            *ngFor="let item of optionsOperationSacados"
            [value]="item.type"
            >
              {{ item.operation }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="buttons-container">
        <button
          class="button"
          (click)="saveAllData();"
          mat-button
          color="primary"
        >
          Salvar
        </button>
        <button [mat-dialog-close]="true" mat-button color="primary">
          Cancelar
        </button>
      </div>
    </mat-tab>
    </form>

    <mat-tab label="Dados">
      <ng-template mat-tab-label>
        <p>Dados</p>
      </ng-template>
      <iframe
        (load)="isLoading = false"
        class="iframe"
        [src]="data?.url"
      ></iframe>
    </mat-tab>

    <mat-tab *ngIf="!isSacado">
      <ng-template mat-tab-label>
        <p>Mensalidade</p>
        <mat-icon [ngClass]="validMonthly ? 'checked' : 'error'">{{validMonthly ? 'check_circle_outline' : 'highlight_off'}}</mat-icon>
      </ng-template>
      <form [formGroup]="form">
        <div class="custumer-data">
          <b class="customer-name">{{ data?.name }}</b>
          <small> <b>CNPJ</b> - {{ data?.cnpj | mask: "CPF_CNPJ" }}</small>
        </div>
        <div class="form-field-container">
          <mat-form-field *ngIf="data.type === 'LEAD'" class="w-100" appearance="outline">
            <mat-label>Mensalidade</mat-label>
            <mat-select
              required
              formControlName="monthly"
              placeholder="Escolha o tipo de mensalidade"
              (selectionChange)="setChangedForm()"
            >
              <mat-option [value]="item.type" *ngFor="let item of monthlyOptions">
                <p>{{ item.name }}</p>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="data.type === 'LEAD'" class="w-100" appearance="outline">
            <mat-label>Dia do faturamento</mat-label>
            <mat-select
              (selectionChange)="setChangedForm()"
              required
              formControlName="billingDay"
            >
              <mat-option
                [value]="item.value"
                *ngFor="let item of optionsDay; let i = index"
              >
                {{ item.date }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="data.type === 'LEAD'" class="w-100" appearance="outline">
            <mat-label>Tipo</mat-label>
            <mat-select (selectionChange)="setChangedForm()" multiple required formControlName="type">
              <mat-option
                [value]="item?.value"
                *ngFor="let item of typesOptions"
              >
                <p>{{ item?.name }}</p>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="data.type === 'LEAD'" class="form-field-container">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Limite de Crédito</mat-label>
            <input
              (keyup)="setChangedForm()"
              matInput
              currencyMask
              required
              formControlName="creditLimit"
              placeholder="Escolha o tipo de mensalidade"
            />
          </mat-form-field>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Fator de Deságio</mat-label>
            <input
              mask="0.00"
              suffix="%"
              matInput
              required
              formControlName="discountFactor"
              [dropSpecialCharacters]="false"
              [validation]="false"
              (change)="setChangedForm()"
            />
          </mat-form-field>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Ad Valorem</mat-label>
            <input
              mask="0.00"
              [validation]="false"
              suffix="%"
              matInput
              required
              formControlName="adValorem"
              [dropSpecialCharacters]="false"
              (change)="setChangedForm()"
            />
          </mat-form-field>
        </div>
        <div *ngIf="data.type === 'LEAD'" class="form-field-container">
          <mat-form-field appearance="outline">
            <mat-label>Broker</mat-label>
            <mat-select (selectionChange)="setChangedForm()" required formControlName="broker">
              <input class="input-autocomplete" placeholder="Digite o nome do Broker" formControlName="enteredData" [matAutocomplete]="auto" matInput type="text">
              <mat-option
                [value]="item?.id"
                *ngFor="let item of brokerOptions"
              >
                <p>{{ item?.name }}</p>
              </mat-option>
              <mat-autocomplete #auto="matAutocomplete"></mat-autocomplete>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="data.type !== 'LEAD'" class="custom-monthly">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Mensalidade</mat-label>
            <input
              (keyup)="setChangedForm()"
              matInput
              currencyMask
              formControlName="monthlyFee"
              placeholder="Digite o valor da mensalidade"
            />
          </mat-form-field>
      </div>
      </form>
      <div class="buttons-container">
        <button
          class="button"
          (click)="saveAllData();"
          mat-button
          color="primary"
        >
          Salvar
        </button>
        <button [mat-dialog-close]="true" mat-button color="primary">
          Cancelar
        </button>
      </div>
    </mat-tab>

    <mat-tab class="documents-container-tab">
      <div class="container-docs">
        <div
        [ngClass]="
             saved
            ? 'alert-container saved'
            : hasError
            ? 'alert-container error'
            : 'alert-container'
        "
      >
        <div class="alert-header">
          <mat-icon class="mat-18">error</mat-icon>
          <p *ngIf="!hasError && !saved">
            Os documentos são salvos automaticamente, as alterações feitas são
            irreversíveis!
          </p>
          <p *ngIf="saved">Documentos importados com sucesso.</p>
          <p *ngIf="hasError">
            Tivemos um erro ao tentar importar este documento. Tente novamente
            mais tarde!
          </p>
        </div>
      </div>
      <ng-template mat-tab-label>
        <p>Documentos</p>
        <mat-icon
          matTooltip="Os documentos são salvos automaticamente, as alterações feitas são irreversíveis!"
          >help</mat-icon
        >
      </ng-template>
      <p class="type-doc">
        <mat-icon>account_balance</mat-icon>
        Empresa
      </p>
      <div class="container-accordion">
        <mat-accordion
          class="accordion"
          *ngFor="let doc of documents; let i = index"
          [multi]="true"
          [hideToggle]="false"
        >
          <mat-expansion-panel
          [ngClass]="doc?.files?.length ?'':'dontHasDocuments' "
          >
            <mat-expansion-panel-header class="header-panel">
              <div class="header-accordion-container">
                <p>{{ doc.name }}</p>
                <p
                  [ngClass]="
                    doc?.files?.length ? 'success-alert' : 'exception-alert'
                  "
                  innerText="{{ doc?.files?.length | getSubTitle }}"
                ></p>
              </div>
              <button
              class="import-button"
                mat-stroked-button
                color="accent"
                (fileDropped)="addDocument({file:$event, type:doc.type, index:i})"
                (click)="fileInput.click()"
              >
                Importar
                <input
                  class="d-none"
                  type="file"
                  #fileInput
                  multiple
                  accept="'.png', '.PNG', '.jpg', '.JPG', '.jpeg', '.JPEG', '.pdf', '.PDF'"
                  (change)="addDocument({file:$event, type:doc.type, index:i})"
                />
              </button>
            </mat-expansion-panel-header>
            <div class="body-container">
              <p *ngFor="let info of doc.files">
                <mat-icon
                  *ngIf="info.file"
                  matTooltip="Remover documento"
                  (click)="deleteDocument({userId: data?.id, docId: info?.id, index: i, name: info?.file})"
                  class="icon-remove"
                  >remove_circle</mat-icon
                >
                {{ info?.file | getName }}
              </p>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <p class="type-doc">
        <mat-icon>people</mat-icon>
        Quadro Societário
      </p>
      <div
        *ngFor="let partner of partnersData; let indexPartner = index"
        class="partner-documents"
      >
        <p class="type-doc">
          <mat-icon>person</mat-icon>
          {{ partner?.partnerName }}
        </p>
        <div class="container-accordion">
          <mat-accordion
            class="accordion"
            *ngFor="let doc of partner.documents; let indexDocument = index"
            [multi]="true"
            [hideToggle]="false"
          >
            <mat-expansion-panel [ngClass]="doc?.files?.length ? '' : 'dontHasDocuments' ">
              <mat-expansion-panel-header class="header-panel">
                <div class="header-accordion-container">
                  <p>{{ doc.name }}</p>
                  <p
                    [ngClass]="
                      doc?.files?.length ? 'success-alert' : 'exception-alert'
                    "
                    innerText="{{ doc?.files?.length | getSubTitle }}"
                  ></p>
                </div>
                <button
                class="import-button"
                  mat-stroked-button
                  color="accent"
                  (fileDropped)="
                    addDocumentPartner(
                      {
                        file:$event,
                        type:doc.type,
                        indexDocument,
                        indexPartner,
                        partnerId:partner?.partnerId
                      }
                    )
                  "
                  (click)="fileInput.click()"
                >
                  Importar
                  <input
                    class="d-none"
                    type="file"
                    #fileInput
                    multiple
                    accept="'.png', '.PNG', '.jpg', '.JPG', '.jpeg', '.JPEG', '.pdf', '.PDF'"
                    (change)="
                      addDocumentPartner(
                        {
                          file:$event,
                          type:doc.type,
                          indexDocument,
                          indexPartner,
                          partnerId:partner?.partnerId
                        }
                      )
                    "
                  />
                </button>
              </mat-expansion-panel-header>
              <div class="body-container">
                <p *ngFor="let info of doc?.files">
                  <mat-icon
                    *ngIf="info.file"
                    matTooltip="Remover documento"
                    (click)="
                      deleteDocumentPartner(
                        {
                        partnerId: partner.partnerId,
                        docId: info.id,
                        indexPartner,
                        indexDocument,
                        name: info.file
                      }
                      )
                    "
                    class="icon-remove"
                    >remove_circle</mat-icon
                  >
                  {{ info?.file | getName }}
                </p>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
      </div>
    </mat-tab>

  </mat-tab-group>
</div>

