import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss']
})
export class AsideComponent implements OnInit {

  @Input() opened: any;
  @Output() isClose: any = new EventEmitter();
  @Output() scrolled: any = new EventEmitter();

  close() {
    this.opened = false;
    this.isClose.emit(false);
  }

  constructor() { }

  ngOnInit() {
  }

  onScroll(){
    this.scrolled.emit();
  }

}
