import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MonthlyPlanInteface } from './interfaces/monthly-plan.interface';
import { BrokerInterface } from './interfaces/broker.interface';
import { SacadosOptionsInterface } from './interfaces/sacados-options.interface';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {
  DeleteDocumentPartnersInterface,
  DeleteDocumentCompanysInterface,
  AddDocumentPartnersInterface,
  AddDocumentInterface,
  ReloadDocumentsPartnerInterface,
} from './interfaces/methods-document-params.interface';
import {
  MonthlyOptionsList,
  TypeOptionsList,
  OptionsInvoicingDay,
  OptionsOperationSacado,
} from './../../../../../utils/documents-company-list';
import { DocumentsPartnersList } from './../../../../../utils/documents-partners-list';
import { PartnerInterface } from './interfaces/partner.interface';
import { PlataformLeadsConfirmDialogComponent } from './../plataform-leads-confirm-dialog/plataform-leads-confirm-dialog.component';
import { DocumentInterface } from './../interfaces/documents.interface';
import { CustomerService } from 'src/app/services/customer.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DocumentsCompanyList } from 'src/app/utils/documents-company-list';

@Component({
  selector: 'app-plataform-leads-edit',
  templateUrl: './plataform-leads-edit.component.html',
  styleUrls: ['./plataform-leads-edit.component.scss'],
})
export class PlataformLeadsEditComponent implements OnInit, OnDestroy {
  form!: FormGroup;
  isLoading: boolean = true;
  monthlyOptions: { name: string; type: string }[] = MonthlyOptionsList.list();
  typesOptions: { name: string; value: string; selected: boolean }[] =
    TypeOptionsList.list();
  documents: any = DocumentsCompanyList.list();
  optionsDay = OptionsInvoicingDay.list();
  optionsOperationSacados = OptionsOperationSacado.list();
  partners: PartnerInterface[] = [];
  operationForm: { type: string; operation: string }[] = [];
  saving: boolean = false;
  saved: boolean = false;
  hasError: boolean = false;
  partnersData: any = [];
  isSacado: boolean = false;
  operationType: any = [];
  operationTypeRemoved: any = [];
  companyId = this.data.id;
  pendingSave: boolean = false;
  brokerOptions: BrokerInterface[] = [];
  secondaryBrokerOptions: BrokerInterface[] = [];
  subscriptions$: Subscription[] = [];
  discountFactor: number = 0;
  adValorem: number = 0;
  validOperation: boolean = false;
  validMonthly: boolean = false;
  savedAllData: boolean = false;
  notsSacadoAndLead: boolean = false;
  changedForm: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customerService: CustomerService,
    private dialog: MatDialog
  ) {
    this.form = new FormGroup({
      monthly: new FormControl(null, [Validators.required]),
      billingDay: new FormControl(null, [Validators.required]),
      monthlyFee: new FormControl(null),
      type: new FormControl(null, [Validators.required]),
      creditLimit: new FormControl(null, [Validators.required]),
      discountFactor: new FormControl(null, [Validators.required]),
      adValorem: new FormControl(null, [Validators.required]),
      broker: new FormControl(null, [Validators.required]),
      selectedOptionsSacado: new FormControl(null, []),
      enteredData: new FormControl(null),
    });
  }

  ngOnInit() {
    this.getSacadoOptions();
    this.verifyTypeClient();
    this.filterDocumentByType(this.data?.documents);
    this.data?.partners?.forEach((element: PartnerInterface) => {
      this.partners.push(element);
    });
    this.getPartners();
    this.autocompleteFunction();
    this.getAllBrokersOptions();
    this.getMonthlyPlan();
    this.onChanges();
  }
  ngOnDestroy(): void {
    this.subscriptions$.forEach((subscription) => subscription.unsubscribe());
  }

  filterDocumentByType(docs: DocumentInterface[]): void {
    const TYPE: any = {
      CONTRATO_SOCIAL: (doc: DocumentInterface) => {
        doc?.file ? this.documents[0]?.files.push(doc) : '';
      },
      DRE: (doc: DocumentInterface) => {
        doc?.file ? this.documents[1]?.files.push(doc) : '';
      },
      ENDERECO: (doc: DocumentInterface) => {
        doc?.file ? this.documents[2]?.files.push(doc) : '';
      },
      FACHADA_EMPRESA: (doc: DocumentInterface) => {
        doc?.file ? this.documents[3]?.files.push(doc) : '';
      },
      FATURAMENTO: (doc: DocumentInterface) => {
        doc?.file ? this.documents[4]?.files.push(doc) : '';
      },
      INTERIOR_EMPRESA: (doc: DocumentInterface) => {
        doc?.file ? this.documents[5]?.files.push(doc) : '';
      },
      NF: (doc: DocumentInterface) => {
        doc?.file ? this.documents[6]?.files.push(doc) : '';
      },
      OTHERS: (doc: DocumentInterface) => {
        doc?.file ? this.documents[7]?.files.push(doc) : '';
      },
      MEI: (doc: DocumentInterface) => {
        doc?.file ? this.documents[8]?.files.push(doc) : '';
      },
      CNPJ: (doc: DocumentInterface) => {
        doc?.file ? this.documents[9]?.files.push(doc) : '';
      },
      EXTRATO_SIMPLES: (doc: DocumentInterface) => {
        doc?.file ? this.documents[10]?.files.push(doc) : '';
      },
      ULTIMO_BALANCO: (doc: DocumentInterface) => {
        doc?.file ? this.documents[11]?.files.push(doc) : '';
      },
      PENULTIMO_BALANCO: (doc: DocumentInterface) => {
        doc?.file ? this.documents[12]?.files.push(doc) : '';
      },
      ATA_CONSTITUICAO: (doc: DocumentInterface) => {
        doc?.file ? this.documents[13]?.files.push(doc) : '';
      },
      ATA_ELEICAO: (doc: DocumentInterface) => {
        doc?.file ? this.documents[14]?.files.push(doc) : '';
      },
      CPF: (doc: DocumentInterface) => {
        doc?.file ? this.documents[15]?.files.push(doc) : '';
      },
      CONTRATO_SERVICO_BROADFACTOR: (doc: DocumentInterface) => {
        doc?.file ? this.documents[16]?.files.push(doc) : '';
      },
    };
    docs?.forEach((element: DocumentInterface) => {
      if (Object.keys(TYPE).includes(element.type)) {
        TYPE[element?.type](element);
      } else {
        TYPE['OTHERS'](element);
      }
    });
  }

  filterDocumentPartnerByType(partner: PartnerInterface) {
    let documentsPartners: {
      files: DocumentInterface[];
      name: string;
      type: string;
    }[] = DocumentsPartnersList.list();
    const TYPE: any = {
      IDENTIFICACAO: (doc: DocumentInterface) => {
        doc?.file ? documentsPartners[0]?.files.push(doc) : '';
      },
      ENDERECO: (doc: DocumentInterface) => {
        doc?.file ? documentsPartners[1]?.files.push(doc) : '';
      },
      IMPOSTO_RENDA: (doc: DocumentInterface) => {
        doc?.file ? documentsPartners[2]?.files.push(doc) : '';
      },
      RECIBO_IMPOSTO_RENDA: (doc: DocumentInterface) => {
        doc?.file ? documentsPartners[3]?.files.push(doc) : '';
      },
      OTHERS: (doc: DocumentInterface) => {
        doc?.file ? documentsPartners[4]?.files.push(doc) : '';
      },
    };

    partner.documents.forEach((element: DocumentInterface) => {
      if (TYPE[element?.type]) {
        TYPE[element?.type](element);
      } else {
        TYPE['OTHERS'](element);
      }
    });
    return documentsPartners;
  }
  getPartners(): void {
    this.partners.forEach((part: PartnerInterface) => {
      this.partnersData.push({
        partnerName: part?.name,
        partnerId: part?.id,
        documents: this.filterDocumentPartnerByType(part),
      });
    });
  }

  getNameDocument(file: string): string {
    const splitedArray = file.split('/');
    return splitedArray[splitedArray?.length - 1];
  }

  deleteDocument(params: DeleteDocumentCompanysInterface): void {
    const dialogRef = this.dialog.open(PlataformLeadsConfirmDialogComponent, {
      height: '320px',
      width: '500px',
      data: {
        userId: params.userId,
        docId: params.docId,
        name: this.getNameDocument(params.name),
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      res
        ? this.reloadDocuments(params.docId, params.index)
        : () => {
            this.hasError = true;
            setTimeout(() => (this.hasError = false), 4000);
          };
    });
  }

  deleteDocumentPartner(params: DeleteDocumentPartnersInterface): void {
    const dialogRef = this.dialog.open(PlataformLeadsConfirmDialogComponent, {
      height: '320px',
      width: '500px',
      data: {
        partnerId: params?.partnerId,
        docId: params?.docId,
        name: this.getNameDocument(params?.name),
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      res
        ? this.reloadDocumentsPartners({
            docId: params?.docId,
            indexPartner: params?.indexPartner,
            indexDocument: params?.indexDocument,
          })
        : () => {
            this.hasError = true;
            setTimeout(() => (this.hasError = false), 4000);
          };
    });
  }

  reloadDocuments(docId: string, index: number): void {
    const filteredData = this.documents[index].files.filter(
      (doc: DocumentInterface) => doc.id !== docId
    );
    this.documents[index].files = [...filteredData];
  }

  reloadDocumentsPartners(params: ReloadDocumentsPartnerInterface): void {
    const filteredData = this.partnersData[params?.indexPartner].documents[
      params?.indexDocument
    ].files.filter((doc: DocumentInterface) => doc.id !== params?.docId);
    this.partnersData[params?.indexPartner].documents[
      params?.indexDocument
    ].files = [...filteredData];
  }

  addDocument(params: AddDocumentInterface): void {
    this.resetAlertActions();
    this.saving = true;
    this.customerService
      .uploadDocument(params.file.target.files, params.type)
      .pipe(take(1))
      .subscribe((res: DocumentInterface[]) => {
        this.saved = true;
        res.forEach((document: DocumentInterface) => {
          this.documents[params?.index].files.push(document);
        });
        this.resetAlertActions();
        setTimeout(() => (this.saved = false), 5000);
      });
  }

  addDocumentPartner(params: AddDocumentPartnersInterface): void {
    this.resetAlertActions();
    this.saving = true;
    this.customerService
      .uploadDocumentPartner(
        params?.file.target.files,
        params?.type,
        params?.partnerId
      )
      .pipe(take(1))
      .subscribe((res: DocumentInterface[]) => {
        res.forEach((document: DocumentInterface) => {
          this.partnersData[params?.indexPartner].documents[
            params?.indexDocument
          ].files.push(document);
        });
        this.resetAlertActions();
        this.saved = true;
      });
  }

  resetAlertActions(): void {
    this.saved = false;
    this.hasError = false;
    this.saving = false;
  }
  verifyTypeClient(): void {
    this.data?.type === 'SACADO'
      ? (this.isSacado = true)
      : (this.isSacado = false);
  }
  getSacadoOptions(): void {
    this.customerService
      .getSacadoOptions(this.companyId)
      .pipe(take(1))
      .subscribe((res) => {
        const optionsType = res.map(
          (element: SacadosOptionsInterface) => element.type
        );
        optionsType?.length || this.data.type !== 'LEAD'
          ? (this.validOperation = true)
          : (this.validOperation = false);
        this.form.get('selectedOptionsSacado')?.patchValue(optionsType);
      });
  }
  addSacadoOption(): void {
    this.addRemovedSacadoOptions();
    this.operationType = this.form.get('selectedOptionsSacado')?.value;
    this.validOperation = true;
    this.pendingSave = true;
    this.saved = false;
    this.saving = false;
  }
  addRemovedSacadoOptions(): void {
    const allTypes = this.optionsOperationSacados.map(
      (element) => element.type
    );
    allTypes.forEach((type) => {
      !this.form.get('selectedOptionsSacado')?.value.includes(type) &&
      !this.operationTypeRemoved.includes(type)
        ? this.operationTypeRemoved.push(type)
        : null;
    });
  }
  saveSacadoOptions(): void {
    let operationsTypeRemove: { type: string }[] = [];
    let operationsTypeAdd: { type: string }[] = [];
    this.operationTypeRemoved.forEach((element: string) => {
      operationsTypeRemove.push({ type: element });
    });
    this.operationType.forEach((element: string) => {
      operationsTypeAdd.push({ type: element });
    });
    this.subscriptions$.push(
      this.customerService
        .addSacadoOption(operationsTypeAdd, this.companyId)
        .pipe(take(1))
        .subscribe()
    );
    this.subscriptions$.push(
      this.customerService
        .removeSacadoOption(operationsTypeRemove, this.companyId)
        .pipe(take(1))
        .subscribe()
    );
  }

  autocompleteFunction(): void {
    this.form.get('enteredData')?.valueChanges.subscribe((res: string) => {
      this.filterData(res);
    });
  }

  filterData(enteredData: string): void {
    if (enteredData) {
      const filteredCustomer = this.brokerOptions.filter(
        (option) =>
          option.name
            .toLocaleLowerCase()
            .indexOf(enteredData?.toLocaleLowerCase()) > -1
      );
      filteredCustomer?.length
        ? (this.brokerOptions = filteredCustomer)
        : (this.brokerOptions = []);
    } else {
      this.brokerOptions = this.secondaryBrokerOptions;
    }
  }
  getAllBrokersOptions(): void {
    this.customerService
      .getAllBrokersOption()
      .pipe(take(1))
      .subscribe((res: BrokerInterface[]) => {
        this.brokerOptions = res;
        this.secondaryBrokerOptions = res;
      });
  }
  addMonthlyPlan(): void {
    this.saving = true;
    let {
      broker,
      billingDay,
      creditLimit,
      discountFactor,
      adValorem,
      monthly,
      monthlyFee,
    } = this.form.value;
    let actPrivate = this.verifyTypeMonthly().actPrivate;
    let actPawn = this.verifyTypeMonthly().actPawn;
    discountFactor = discountFactor.replace('%', '');
    adValorem = adValorem.replace('%', '');
    this.customerService
      .addMonthlyPlan(
        {
          broker: this.verifyIfValueIsFalsy(broker),
          billingDay: this.verifyIfValueIsFalsy(billingDay),
          actPawn: this.verifyIfValueIsFalsy(actPawn),
          actPrivate: this.verifyIfValueIsFalsy(actPrivate),
          adValorem: this.verifyIfValueIsFalsy(adValorem),
          creditLimit: this.verifyIfValueIsFalsy(creditLimit),
          discountFactor: this.verifyIfValueIsFalsy(discountFactor),
          monthlyFee: this.verifyIfValueIsFalsy(monthlyFee),
          type: this.verifyIfValueIsFalsy(monthly),
        },
        this.data.id
      )
      .pipe(take(1))
      .subscribe((res) => {
        if (res) this.validMonthly = true;

        this.dialog.closeAll();
      });
  }
  verifyTypeMonthly(): { actPrivate: boolean; actPawn: boolean } {
    const values = this.form.get('type')?.value;
    let myObject = { actPrivate: false, actPawn: false };
    values?.includes('private')
      ? (myObject.actPrivate = true)
      : (myObject.actPrivate = false);
    values?.includes('effort')
      ? (myObject.actPawn = true)
      : (myObject.actPawn = false);
    return myObject;
  }
  getMonthlyPlan(): void {
    this.customerService
      .getMonthlyPlan(this.data.id)
      .pipe(take(1))
      .subscribe((res: MonthlyPlanInteface) => {
        let myArray = [];
        const {
          actPawn,
          actPrivate,
          adValorem,
          billingDay,
          broker,
          creditLimit,
          discountFactor,
          type,
          monthlyFee,
        } = res;
        this.verifyMonthlyPlan(res);
        actPawn ? myArray.push('effort') : null;
        actPrivate ? myArray.push('private') : null;
        this.form.patchValue({
          type: myArray,
          adValorem,
          billingDay,
          broker,
          creditLimit,
          discountFactor,
          monthlyFee,
          monthly: type,
        });
      });
  }
  verifyMonthlyPlan(monthlyPlan: MonthlyPlanInteface): boolean {
    if (this.data.type === 'LEAD') {
      const arrayValues = Object.values(monthlyPlan);
      arrayValues.every((plan: string | number | boolean) => {
        plan ? (this.validMonthly = true) : (this.validMonthly = false);
      });
      return this.validMonthly;
    } else {
      const monthlyFee = this.form.get('monthlyFee')?.value;
      monthlyFee ? (this.validMonthly = true) : (this.validMonthly = false);
      return this.validMonthly;
    }
  }
  onChanges(): void {
    this.subscriptions$.push(
      this.form.valueChanges.subscribe((value) => {
        const sacados = this.form.get('selectedOptionsSacado')?.value;
        sacados?.length
          ? (this.validOperation = true)
          : (this.validOperation = false);
        const {
          broker,
          billingDay,
          creditLimit,
          discountFactor,
          adValorem,
          monthly,
        } = this.form.value;
        const actPrivate = this.verifyTypeMonthly().actPrivate;
        const actPawn = this.verifyTypeMonthly().actPawn;
        this.verifyMonthlyPlan({
          broker,
          billingDay,
          actPawn,
          actPrivate,
          adValorem,
          creditLimit,
          discountFactor,
          type: monthly,
        });
      })
    );
  }
  verifyIfNotsLeadOrSacado(): void {
    const type = this.data.type;
    if (type !== 'SACADO' && type !== 'LEAD') {
      this.notsSacadoAndLead = true;
    } else {
      this.notsSacadoAndLead = false;
    }
  }
  saveAllData(): void {
    this.saveSacadoOptions();
    this.addMonthlyPlan();
    this.savedAllData = true;
  }
  openConfirmModal(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      height: '320px',
      width: '320px',
      panelClass: 'custom-dialog',
    });
  }
  closeDialog(): void {
    this.dialog.closeAll();
  }
  setChangedForm(): void {
    this.changedForm = true;
  }
  verifyIfValueIsFalsy(value: string | number | boolean | null): any {
    if (value) {
      return value;
    } else {
      return null;
    }
  }
}
