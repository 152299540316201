import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { UserInterface } from '../../interfaces/user';
import { AuthService } from '../../services/auth.service';
import { UsersService } from '../../services/users.service';

@Component({
	selector: 'app-user-avatar',
	templateUrl: './user-avatar.component.html',
	styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit {

	loading!: boolean;
	user!: UserInterface;

	constructor(private authService: AuthService, private usersService: UsersService, private snack: MatSnackBar) {
	 }

	ngOnInit(): void {
		this.authService.user.subscribe((user: any) => {
			this.user = user;
		});
	}

	logout(): void {
		this.authService.logout();
	}
	changePicture(image: any): void {
		this.loading = true;
		this.usersService.updatePictureUser(this.user.id, image.target.files[0], this.user.accessToken)
			.then(response => response.text())
			.then((result: any) => {
				this.user.pictureUrl = result;
				this.authService.user.next(this.user);
				this.loading = false;
			});
	}
}
