import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) { }

  getUsers() {
    return this.http.get(environment.API_url + 'management-api/users')
  }

  getUser(id: string) {
    return this.http.get(environment.API_url + 'management-api/users/' + id )
  }

  createUser(user: any) {
    return this.http.post(environment.API_url + 'management-api/users', user)
  }

  updateUser(id: string, user: any) {
    return this.http.put(environment.API_url + 'management-api/users/' + id, user)
  }
  
  updatePasswordUser(id: string, user: any) {
    return this.http.put(environment.API_url + 'management-api/users/password' + id, user)
  }

  // usuário logado
  getProfile(id: string) {
    return this.http.get(environment.API_url + 'management-api/users/profile' + id)
  }

  async updatePictureUser(id: string, image: any, token: string) {
    const formData = new FormData();
    formData.append('file', image, image.name);
    formData.append('name',  image.name);
    formData.append('documentType', image.type);

    const requestOptions = {
      method: 'PUT',
      headers: { media: 'true', 'Authorization':  'Bearer ' + token },
      body: formData,
    };

    return fetch(environment.API_url + 'management-api/users/profile/picture/' + id, requestOptions)
  }
}
