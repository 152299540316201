import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerService } from 'src/app/services/customer.service';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-plataform-leads-confirm-dialog',
  templateUrl: './plataform-leads-confirm-dialog.component.html',
  styleUrls: ['./plataform-leads-confirm-dialog.component.scss'],
})
export class PlataformLeadsConfirmDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customerService: CustomerService,
    private dialogRef: MatDialogRef<PlataformLeadsConfirmDialogComponent>
  ) {}

  ngOnInit(): void {}

  deleteDoc(): void {
    if (this.data?.partnerId) {
      this.customerService
        .deleteDocumentPartner(this.data?.partnerId, this.data?.docId)
        .subscribe(
          () => {
            this.dialogRef.close({ deletedDoc: true });
          },
          () => {
            this.dialogRef.close({ deletedDoc: true });
          }
        );
    } else {
      this.customerService
        .deleteDocument(this.data?.docId, this.data?.userId)
        .subscribe(
          () => {
            this.dialogRef.close({ deletedDoc: true });
          },
          () => {
            this.dialogRef.close({ deletedDoc: true });
          }
        );
    }
  }
  close(): void {
    this.dialogRef.close(false);
  }
}
