import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { RoutePages } from 'src/app/enum/router.enum';
import { AuthService } from 'src/app/services/auth.service';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent?.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {

  myForm: FormGroup;
  hide!: any;
  matcher = new MyErrorStateMatcher();
  successResponse = false;
  errorResponse = false;

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router) {
    this.myForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['']
    }, { validator: this.checkPasswords });

  }
  ngOnInit(): void {}

  checkPasswords(group: FormGroup) {
    let pass = group.controls.password.value;
    let confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true }
  }

  changePassword() {
    const password = this.myForm.get('password')?.value;

    this.authService.createdPassword(password).subscribe(
      () => {
        this.successResponse = true;
        this.router.navigate([RoutePages.Modules]);
      },
      () => {
        this.errorResponse = true;
      }
    )
  }

}
