
<mat-tab-group #tabGroup color="accent">
  <mat-tab label="Fomentos">
    <ng-template mat-tab-label>
      <label>
         Fomentos
      </label>
    </ng-template>
    <ng-template matTabContent>
        <app-buyer-financial></app-buyer-financial>
    </ng-template>
  </mat-tab>
  <mat-tab label="Repasse Conta azul">
    <ng-template mat-tab-label>
      <label>
        Repasse Conta azul
      </label>
    </ng-template>
    <ng-template matTabContent>
      <app-transfer-conta-azul></app-transfer-conta-azul>
    </ng-template>
  </mat-tab>
</mat-tab-group>

