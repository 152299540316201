<div class="toolbar">
  <div class="cards-container">
    <div class="card">
      <div class="card-title-container">
        <mat-icon> bar_chart</mat-icon>
        <div class="title-content">
          <span class="card-title">Índice de liquidez</span>
          <span class="card-value">{{
            cardsValue?.liquidityPercentage | percentage
          }}</span>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-title-container">
        <mat-icon>percent</mat-icon>
        <div class="title-content">
          <span class="card-title">Rebate</span>
          <span class="card-value">{{
            cardsValue?.rebatePercentage | percentage
          }}</span>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-title-container">
        <mat-icon>shopping_cart</mat-icon>
        <div class="title-content">
          <span class="card-title">Valor liquidado</span>
          <span class="card-value">{{
            cardsValue?.valueSettled | currency : "BRL"
          }}</span>
        </div>
      </div>
    </div>
    <div class="card comission-content">
      <div class="card-title-container">
        <mat-icon>payments</mat-icon>
        <div class="title-content">
          <span class="card-title">Comissão do mês</span>
          <span class="card-value">{{
            cardsValue?.commission | currency : "BRL"
          }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<form [formGroup]="formFilters" (keydown.enter)="$event.preventDefault()">
  <mat-form-field class="form-field" appearance="outline">
    <mat-label>
      <mat-icon>search</mat-icon>
      Cedente
    </mat-label>
    <input
      class="input-autocomplete"
      placeholder="Digite o nome do Cedente"
      matInput
      type="text"
      [matAutocomplete]="auto"
      formControlName="lead"
      (blur)="handleAutocompleteBlur()"
    />
    <mat-autocomplete
      [displayWith]="displayLEAD.bind(this)"
      #auto="matAutocomplete"
    >
      <mat-option *ngFor="let option of leadsOptions" [value]="option">
        {{ option.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field class="form-field" appearance="outline">
    <mat-label>
      <mat-icon>search</mat-icon>
      Sacado
    </mat-label>
    <input
      class="input-autocomplete"
      placeholder="Digite o nome do Sacado"
      matInput
      type="text"
      [matAutocomplete]="sacado"
      formControlName="sacado"
      (blur)="handleAutocompleteSacadoBlur()"
    />
    <mat-autocomplete
      [displayWith]="displaySacado.bind(this)"
      #sacado="matAutocomplete"
    >
      <mat-option *ngFor="let option of sacadoOptions" [value]="option">
        {{ option.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field class="form-field" appearance="outline">
    <mat-label>
      <mat-icon>search</mat-icon>
      Número do Título
    </mat-label>
    <input
      class="input-autocomplete"
      placeholder="Digite o Número do título"
      matInput
      type="text"
      formControlName="installment"
    />
  </mat-form-field>

  <button
    mat-flat-button
    class="submit-button"
    color="primary"
    (click)="handleSubmit()"
    [disabled]="!formFilters.value"
  >
    <mat-icon>filter_alt</mat-icon>
    <span>Filtrar</span>
  </button>
  <button mat-stroked-button class="submit-button w-btn-clear" (click)="clearFilters()">
    <mat-icon>filter_alt_off</mat-icon>
  </button>
</form>
<div class="download-button-container">
  <button mat-raised-button class="download-extract">
    <mat-icon>download</mat-icon>Baixar extrato
  </button>
</div>

<div class="table-container">
  <table 
    mat-table 
    [dataSource]="dataSource" 
    class="mat-elevation-z8" 
  >
    <ng-container matColumnDef="cedente">
      <th mat-header-cell *matHeaderCellDef>Cedente</th>
      <td mat-cell *matCellDef="let element">{{ element.lead ?? "N/A" }}</td>
    </ng-container>

    <ng-container matColumnDef="sacado">
      <th mat-header-cell *matHeaderCellDef>Sacado</th>
      <td mat-cell *matCellDef="let element">{{ element.drawee ?? "N/A" }}</td>
    </ng-container>

    <ng-container matColumnDef="comprador">
      <th mat-header-cell *matHeaderCellDef>Comprador</th>
      <td mat-cell *matCellDef="let element">{{ element.buyer }}</td>
    </ng-container>

    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>Número</th>
      <td mat-cell *matCellDef="let element">
        {{ element.installmentNumber ?? "N/A" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="vencimento">
      <th mat-header-cell *matHeaderCellDef>Vencimento</th>
      <td mat-cell *matCellDef="let element">
        {{ (element.expirationDate | date : "dd/MM/YYYY") ?? "N/A" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="valor de face">
      <th mat-header-cell *matHeaderCellDef>Valor de Face</th>
      <td mat-cell *matCellDef="let element">
        {{ (element.faceValue | currency : "BRL") ?? "N/A" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="valorLiquidação">
      <th mat-header-cell *matHeaderCellDef>Valor liquidado</th>
      <td mat-cell *matCellDef="let element">
        {{ (element.paidValue | currency : "BRL") ?? "N/A" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="dataLiquidacão">
      <th mat-header-cell *matHeaderCellDef>Data</th>
      <td mat-cell *matCellDef="let element">
        {{ (element.publicationDate | date : "dd/MM/YYYY") ?? "N/A" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="valor">
      <th mat-header-cell *matHeaderCellDef>Valor</th>
      <td mat-cell *matCellDef="let element">
        {{ (element.value | currency : "BRL") ?? "N/A" }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    
  </table>
  <div class="h-75 d-flex justify-content-center align-items-center" *ngIf="!dataSource.length && !isLoading">
    <p class="message">Nenhum dado encontrado com os filtros utilizados.</p>
  </div>
  <div 
    *ngIf="isLoading"
    class="w-100 h-100 d-flex justify-content-center align-items-center" 
  >
    <mat-spinner></mat-spinner>    
  </div>
</div>
<div class="total">
  <p class="value">
    Total <span>{{ totalValue | currency : "BRL" }}</span>
  </p>
</div>
