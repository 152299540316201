import { MonthlyPlanInteface } from './../pages/suite/leads-management/plataform-leads/plataform-leads-edit/interfaces/monthly-plan.interface';
import { BrokerInterface } from './../pages/suite/leads-management/plataform-leads/plataform-leads-edit/interfaces/broker.interface';
import { SacadosOptionsInterface } from './../pages/suite/leads-management/plataform-leads/plataform-leads-edit/interfaces/sacados-options.interface';
import { AuthService } from 'src/app/services/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DocumentInterface } from './../pages/suite/leads-management/plataform-leads/interfaces/documents.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { customerCount, CustomerDetail } from '../interfaces/customer';
import { Pagination } from '../pages/suite/customer-management/interfaces/customer.interface';
import { GetResponseInterface } from '../pages/suite/leads-management/plataform-leads/plataform-leads-edit/interfaces/get-response.interface';
import { CustomerManagementUtils } from '../utils/customer-managment.utils';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  userId: any = new BehaviorSubject(null);

  constructor(private http: HttpClient, private authService: AuthService) {}

  getHistory(id: string) {
    return this.http.get(
      environment.API_url + 'management-api/customers/history/' + id
    );
  }

  createHistory(id: string, data: any) {
    return this.http.post(
      environment.API_url + 'management-api/customers/history/' + id,
      data
    );
  }

  getCounts(type: any) {
    return this.http.get<any[]>(
      environment.API_url + 'management-api/customers/count/' + type
    );
  }

  getUnregistered(pagination:Pagination, filter:any) {
    return this.http.get<any[]>(
      environment.API_url + `management-api/customers/unregistered/${pagination.index}/${pagination.size}/?filter=${filter ? filter : ''}`
    );
  }

  getCustomers(page:Pagination, data: any){

    switch (data.typeCustomer) {
      case 'PLATAFORM':
        return this.getCustomersBroadfactor (page, data)
      case 'SUPPLIER':
        return this.getSupplier(page, data);
      default:
        return this.getCustomerAntecipagov(page, data);
    }
 }

  getCustomersBroadfactor(page:Pagination, data: any) {
    return this.http.get<customerCount>(
      environment.API_url + `management-api/customers/legacy/${data.filter.clientType}/status/${data.filter.status}/${page.index}/${page.size}/${data.filter.source}/?filter=${data.filter.enteredData ? data.filter.enteredData  : ''}`
    )

  }

  getCustomerAntecipagov(page: Pagination, data:any) {
    const typeCustomer = CustomerManagementUtils.getFormatTypeToService(data.typeCustomer);
    const status = data.status && data.status == 'ALL' ? '' : data.status;
    const responsible = typeCustomer == 'mine' ? data.responsible : '';

    return this.http.get<customerCount>(
      environment.API_url + `management-api/customers/${typeCustomer}/${status ? status + '/' : ''}${responsible ? responsible + '/' : ''}${page.index}/${page.size}/?filter=${data.filter.enteredData ? data.filter.enteredData : ""}`
    );
  }

  getMyCustomers(responsible: any, page:Pagination, filter:any) {
    return this.http.get<customerCount>(
      environment.API_url + `management-api/customers/mine/${responsible}/${page.index}/${page.size}/?filter=${filter ? filter : ""}`
    );
  }

  getCustomerData(id: string) {
    return this.http.get<CustomerDetail>(
      environment.API_url + 'management-api/customers/details/' + id
    );
  }

  downloadDocuments(cnpj: string) {
    return this.http.get(
      environment.API_url + 'management-api/customers/download/all/' + cnpj,
      { responseType: 'arraybuffer' }
    );
  }

  updateCustomerResponsible(customerId: string, responsible: any) {
    return this.http.put(
      environment.API_url +
        'management-api/customers/update/responsible/' +
        customerId,
      responsible
    );
  }

  updateStatusCustomer(id: string, status: {}, type: string = '') {
    return this.http.put(
      environment.API_url + 'management-api/customers/update/'+ `${type == 'PLATAFORM' ? 'lead/' : '' }`+'status/' + id,
      status
    );
  }

  // IF
  getIF(status: string, page: Pagination, filter:any) {
    return this.http.get<customerCount>(
      environment.API_url + `management-api/customers/if/${status ? status + '/' : ''}${page.index}/${page.size}/?filter=${filter ? filter : ""}`
    );
  }

  // FOMENTO
  getFactoring(status: string, page: Pagination, filter:any) {
    return this.http.get<customerCount>(
      environment.API_url + `management-api/customers/fomento/${status ? status + '/' : ''}${page.index}/${page.size}/?filter=${filter ? filter : ""}`
    );
  }

  // FIDC
  getFidc(status: string, page: Pagination, filter:any) {
    return this.http.get<customerCount>(
      environment.API_url + `management-api/customers/fidc/${status ? status + '/' : ''}${page.index}/${page.size}/?filter=${filter ? filter : ""}`
    );
  }

  // SUPPLIER
  getSupplier(page: Pagination, data:any ){
    return this.http.post<customerCount>(
      environment.API_url + `management-api/customers/supplier/${data.status && data.status !== 'ALL' ? data.status + '/' : ''}${page.index}/${page.size}`,
      {
        filter:data.filter.enteredData,
        documentExpired: data.filter.statusDocument.documentExpired,
        documentPending: data.filter.statusDocument.documentPending,
      }
    );
  }

  getLeadsJaer() {
    return this.http.get('https://integrations.antecipagov.com.br', {
      headers: { skip: 'true' },
    });
  }

  setIntegratedLeadsJaer(id: string, value: boolean) {
    return this.http.put(
      'http://api.dev.antecipagov.com.br:8081/api/leads/' + id + '/integrated',
      value,
      { headers: { skip: 'true', 'Content-Type': 'application/json' } }
    );
  }

  // SIGNED DOCUMENTS

  getSignedDocuments(type: 'company' | 'partner', id: string) {
    return this.http.get(
      environment.API_url + 'management-api/documents/' + type + '/' + id,
      { responseType: 'text' }
    );
  }

  uploadDocument(documents: any, type: any): Observable<DocumentInterface[]> {
    const user = this.authService.user.value;
    let url = `${environment.register_api}secure-api/company/${this.userId.value}/document/all/${user?.fullName}`;
    let formData = new FormData();
    let objToArray = [...documents];
    objToArray.map((files: any) => {
      formData.append('files', files);
    });
    formData.append('type', type);
    return this.http.post<DocumentInterface[]>(url, formData, {
      headers: { skip: 'true', media: 'true' },
    });
  }

  deleteDocument(documentId: any, userId: string) {
    const user = this.authService.user.value;
    let url = `${environment.register_api}secure-api/company/${userId}/document/${documentId}/${user?.fullName}`;
    return this.http.delete(url);
  }

  uploadDocumentPartner(
    documents: any,
    type: any,
    partnerId: any
  ): Observable<DocumentInterface[]> {
    const user = this.authService.user.value;
    let url = `${environment.API_register}/secure-api/company/${this.userId.value}/partner/${partnerId}/document/all/${user?.fullName}`;
    let formData = new FormData();
    let objToArray = [...documents];
    objToArray.map((files: any) => {
      formData.append('files', files);
    });
    formData.append('type', type);
    return this.http.post<DocumentInterface[]>(url, formData, {
      headers: { skip: 'true', media: 'true' },
    });
  }

  deleteDocumentPartner(partnerId: any, documentId: any) {
    const user = this.authService.user.value;
    let url = `${environment.API_register}/secure-api/company/${this.userId.value}/partner/${partnerId}/document/${documentId}/${user?.fullName}`;
    return this.http.delete(url);
  }

  getSacadoOptions(companyId: string): Observable<SacadosOptionsInterface[]> {
    let url = `${environment.register_api}secure-api/company/${companyId}/operation`;
    return this.http.get<SacadosOptionsInterface[]>(url);
  }
  addSacadoOption(data: { type: string }[], companyId: string) {
    const user = this.authService.user.value;
    let url = `${environment.register_api}secure-api/company/${companyId}/operation/${user?.fullName}`;
    return this.http.post(url, data, {
      headers: { skip: 'true', 'Content-Type': 'application/json' },
    });
  }
  removeSacadoOption(data: { type: string }[], companyId: string) {
    const user = this.authService.user.value;
    let url = `${environment.register_api}secure-api/company/${companyId}/operation/${user?.fullName}`;
    return this.http.delete(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: data,
    });
  }
  getAllBrokersOption(): Observable<BrokerInterface[]> {
    let url = `${environment.register_api}secure-api/company/allBroker/`;
    return this.http.get<BrokerInterface[]>(url);
  }
  changeEmailCompany(companyId: string, newEmail: string): Observable<any> {
    const user = this.authService.user.value;
    let url = `${environment.API_register}/secure-api/company/${companyId}/mailLogin/${user?.fullName}`;
    let formData = new FormData();
    formData.append('mail', `${newEmail}`);
    return this.http.post(url, formData, { headers: { skip: 'true' } });
  }
  addMonthlyPlan(
    data: MonthlyPlanInteface,
    companyId: string
  ): Observable<any> {
    const user = this.authService.user.value;
    let url = `${environment.register_api}secure-api/company/${companyId}/config/monthlyPlan/${user?.fullName}`;
    return this.http.post(url, data, {
      headers: { skip: 'true', 'Content-Type': 'application/json' },
    });
  }
  getMonthlyPlan(companyId: string): Observable<MonthlyPlanInteface> {
    let url = `${environment.register_api}secure-api/company/${companyId}/config/monthlyPlan`;
    return this.http.get<MonthlyPlanInteface>(url);
  }

  qualificationList = [
    { code: '05', name: 'Administrador' },
    { code: '08', name: 'Conselheiro de Administração' },
    { code: '10', name: 'Diretor' },
    { code: '16', name: 'Presidente' },
    { code: '17', name: 'Procurador' },
    { code: '20', name: 'Sociedade Consorciada' },
    { code: '21', name: 'Sociedade Filiada' },
    { code: '22', name: 'Sócio' },
    { code: '23', name: 'Sócio Capitalista' },
    { code: '24', name: 'Sócio Comanditado' },
    { code: '25', name: 'Sócio Comanditário' },
    { code: '26', name: 'Sócio de Indústria' },
    { code: '28', name: 'Sócio-Gerente' },
    { code: '29', name: 'Sócio Incapaz ou Relat.Incapaz (exceto menor)' },
    { code: '30', name: 'Sócio Menor (Assistido/Representado)' },
    { code: '31', name: 'Sócio Ostensivo' },
    { code: '37', name: 'Sócio Pessoa Jurídica Domiciliado no Exterior' },
    { code: '38', name: 'Sócio Pessoa Física Residente no Exterior' },
    { code: '47', name: 'Sócio Pessoa Física Residente no Brasil' },
    { code: '48', name: 'Sócio Pessoa Jurídica Domiciliado no Brasil' },
    { code: '49', name: 'Sócio-Administrador' },
    { code: '52', name: 'Sócio com Capital' },
    { code: '53', name: 'Sócio sem Capital' },
    { code: '54', name: 'Fundador' },
    { code: '55', name: 'Sócio Comanditado Residente no Exterior' },
    {
      code: '56',
      name: 'Sócio Comanditário Pessoa Física Residente no Exterior',
    },
    {
      code: '57',
      name: 'Sócio Comanditário Pessoa Jurídica Domiciliado no Exterior',
    },
    { code: '58', name: 'Sócio Comanditário Incapaz' },
    { code: '59', name: 'Produtor Rural' },
    { code: '63', name: 'Cotas em Tesouraria' },
    {
      code: '65',
      name: 'Titular Pessoa Física Residente ou Domiciliado no Brasil',
    },
    {
      code: '66',
      name: 'Titular Pessoa Física Residente ou Domiciliado no Exterior',
    },
    {
      code: '67',
      name: 'Titular Pessoa Física Incapaz ou Relativamente Incapaz (exceto menor)',
    },
    {
      code: '68',
      name: 'Titular Pessoa Física Menor (Assistido/Representado)',
    },
    { code: '70', name: 'Administrador Residente ou Domiciliado no Exterior' },
    {
      code: '71',
      name: 'Conselheiro de Administração Residente ou Domiciliado no Exterior',
    },
    { code: '72', name: 'Diretor Residente ou Domiciliado no Exterior' },
    { code: '73', name: 'Presidente Residente ou Domiciliado no Exterior' },
    {
      code: '74',
      name: 'Sócio-Administrador Residente ou Domiciliado no Exterior',
    },
    { code: '75', name: 'Fundador Residente ou Domiciliado no Exterior' },
    { code: '00', name: 'Representante legal' },
  ];

  legalNatureList = [
    { code: '201-1', name: 'Empresa Pública' },
    { code: '203-8', name: 'Sociedade de Economia Mista' },
    { code: '204-6', name: 'Sociedade Anônima Aberta' },
    { code: '205-4', name: 'Sociedade Anônima Fechada' },
    { code: '206-2', name: 'Sociedade Empresária Limitada' },
    { code: '207-0', name: 'Sociedade Empresária em Nome Coletivo' },
    { code: '208-9', name: 'Sociedade Empresária em Comandita Simples' },
    { code: '209-7', name: 'Sociedade Empresária em Comandita por Ações' },
    { code: '212-7', name: 'Sociedade em Conta de Participação' },
    { code: '213-5', name: 'Empresário Individual' },
    { code: '214-3', name: 'Cooperativa' },
    { code: '215-1', name: 'Consórcio de Sociedades' },
    { code: '216-0', name: 'Grupo de Sociedades' },
    {
      code: '217-8',
      name: 'Estabelecimento, no Brasil, de Sociedade Estrangeira',
    },
    {
      code: '219-4',
      name: 'Estabelecimento, no Brasil, de Empresa Binacional Argentino-Brasileira',
    },
    { code: '221-6', name: 'Empresa Domiciliada no Exterior' },
    { code: '222-4', name: 'Clube/Fundo de Investimento' },
    { code: '223-2', name: 'Sociedade Simples Pura' },
    { code: '224-0', name: 'Sociedade Simples Limitada' },
    { code: '225-9', name: 'Sociedade Simples em Nome Coletivo' },
    { code: '226-7', name: 'Sociedade Simples em Comandita Simples' },
    { code: '227-5', name: 'Empresa Binacional' },
    { code: '228-3', name: 'Consórcio de Empregadores' },
    { code: '229-1', name: 'Consórcio Simples' },
    {
      code: '230-5',
      name: 'Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)',
    },
    {
      code: '231-3',
      name: 'Empresa Individual de Responsabilidade Limitada (de Natureza Simples)',
    },
    { code: '232-1', name: 'Sociedade Unipessoal de Advogados' },
    { code: '233-0', name: 'Cooperativa de Consumo' },
  ];
}
