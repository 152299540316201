export class OperationTypeList{

    public static list(){
        return [
            {value: '', name: "Todos"},
            {value: "CONFIRMED_DRAWEE", name: "Confirmado Sacado"},
            {value: "ASSIGNOR_SENDS_A_TICKET", name: "Cedente envia boleto"},
            {value: "COMMISSIONER", name: "Comissária"},
            {value: "CHECK", name: "Cheque"},
            {value: "ESCROW_ACCOUNT", name: "Conta Escrow"},
            {value: "CONFIRMED_ORDER", name: "Confirmado pedido"},
            {value: "WITHOUT_CONFIRMATION", name: "Sem confirmação - pulverizada"},
            {value: "EFFORT", name: "Empenho"},
        ]
    }

    public static getName(value: string){
      const operationType = this.list().find(e => e.value === value);
      return (operationType?.value) ? operationType.name : null ;
    }
}
