import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { of, Subscription } from 'rxjs';
import { debounce, debounceTime, switchMap, take } from 'rxjs/operators';
import { OperationLead } from 'src/app/interfaces/operacaoFilters';
import { StatusOptionsModal } from 'src/app/utils/modal-adm-lists';
import { operacaoService } from 'src/app/services/operacao-contaazul.service';
import { OperationTypeOption } from 'src/app/interfaces/transfer-conta-azul.interface';
import { OperationTypeList } from 'src/app/utils/operation-type-list';

@Component({
  selector: 'app-modal-adm',
  templateUrl: './modal-adm.component.html',
  styleUrls: ['./modal-adm.component.scss'],
})
export class ModalAdmComponent implements OnInit {
  formModal: FormGroup = new FormGroup({
    statusModal: new FormControl(null),
    responsibleModal: new FormControl(null),
    cnpjModal: new FormControl(null, []),
    observation: new FormControl(null),
    operationType: new FormControl(null),
  });
  subscription!: Subscription;
  customerModal!: any;
  clientOptions!: any;
  operationTypeOptions!: OperationTypeOption[];

  statusOptionsModal = StatusOptionsModal.list();
  responsibleOptionsModal!: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OperationLead[],
    private operacaoService: operacaoService
  ) {
    this.listenBuyerChanges();
    this.listenToFormModalValueChanges();
  }

  ngOnInit() {
    this.getUsers();
    this.handleSetOperationTypeOptions();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  listenBuyerChanges() {
    this.formModal.controls.cnpjModal.valueChanges
      .pipe(
        debounceTime(500),
        switchMap((value: string) => {
          if (value.replace(' ', '').length > 2) {
            this.getFomentoCustomers(value);
          }

          return of('');
        })
      )
      .subscribe();
  }

  listenToFormModalValueChanges(): void {
    this.subscription = this.formModal.valueChanges
      .pipe(debounceTime(300))
      .subscribe(
        ({
          responsibleModal,
          statusModal,
          cnpjModal,
          observation,
          operationType,
        }) => {
          const installments = this.data.map((i) => i.id);

          this.customerModal = {
            installments,
            responsible: responsibleModal,
            status: statusModal,
            buyerId: cnpjModal,
            obs: observation,
            operationType: operationType,
          };
        }
      );
  }

  displayFn(id?: string) {
    return id
      ? this.clientOptions.find((_: any) => _.id === id).name
      : undefined;
  }

  getFomentoCustomers(filter: string): void {
    this.operacaoService
      .getSearchFomento('BUYER', filter)
      .pipe(take(1))
      .subscribe((res) => {
        this.clientOptions = res.content;
      });
  }

  getUsers(): void {
    this.operacaoService
      .getUsers()
      .pipe(take(1))
      .subscribe((res: any) => {
        this.responsibleOptionsModal = res;
      });
  }

  handleSetOperationTypeOptions(): void {
    this.operationTypeOptions = OperationTypeList.list().filter(
      ({ name }) => name !== 'Todos'
    );
  }
}
