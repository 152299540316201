import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BuyerFinancialService {
  private tenantId!: string;
  private date!: string;
  private company!: string;

  getTenantId() {
    return this.tenantId;
  }

  getDate() {
    return this.date;
  }
  getCompany() {
    return this.company;
  }

  setCompany(company: string) {
    this.company = company;
  }
  setTenantId(id: string) {
    this.tenantId = id;
  }

  setDate(date: string) {
    this.date = date;
  }
}
