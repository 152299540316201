import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  search = new BehaviorSubject<any>(null)
  private clear = new EventEmitter()

  filter(arr: any, searchWord: any) {
    const regex = new RegExp('[a-zA-Z\s]+');
			const word = searchWord;
	
			if(regex.test(searchWord)) {
			  return arr.filter = searchWord?.trim().toLowerCase();
			} else {
			  const onlyNumbers = word?.replace(/\D/g, "")
			  return arr.filter = onlyNumbers?.trim().toLowerCase();
			}
  }

  clearSearch() {
    this.clear.emit(null)
  }

  clearGetEmmiter() {
    return this.clear
  }

}
