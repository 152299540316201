<div class="max-size">
  <div class="d-flex justify-content-between" *ngIf="!loading && !error">
    <div>
      <p class="h6 mb-0">
        {{ data?.name }}
        <mat-icon
          class="text-success align-bottom"
          *ngIf="data?.hasQuote"
          matTooltip="EMPRESA COM COTAÇÃO"
          >attach_money</mat-icon
        >
      </p>
      <p class="text-secondary mb-2">
        CNPJ: {{ data.cnpj | mask : "CPF_CNPJ" }}
      </p>

      <div
        class="badge-text badge-success"
        *ngIf="data?.status === 'ENABLED' && !asPlataformLead"
      >
        Ativo
      </div>
      <div
        class="badge-text"
        *ngIf="data?.status === 'BLOCKED' && !asPlataformLead"
      >
        Bloqueado
      </div>
      <div
        class="badge-text badge-danger"
        *ngIf="data?.status === 'OVERDUE' && !asPlataformLead"
      >
        Inadimplente
      </div>
      <div
        class="badge-text badge-note"
        *ngIf="data?.status === 'NEW' && !asPlataformLead"
      >
        Novo
      </div>
      <div
        class="badge-text badge-warning"
        *ngIf="data?.status === 'PENDING' && !asPlataformLead"
      >
        Pendente
      </div>
      <div
        class="badge-text badge-orange"
        *ngIf="data?.status === 'VALIDATING' && !asPlataformLead"
      >
        Validando
      </div>
      <div
        class="badge-text badge-warning"
        *ngIf="data?.status === 'ANALYSIS' && !asPlataformLead"
      >
        Em análise
      </div>
      <div
        class="badge-text badge-success"
        *ngIf="data?.status === 'APPROVED' && !asPlataformLead"
      >
        Aprovado
      </div>
      <div
        class="badge-text"
        *ngIf="data?.status === 'NOT_INTERESTED' && !asPlataformLead"
      >
        Sem interesse
      </div>
      <div
        class="badge-text badge-danger"
        *ngIf="data?.status === 'REJECTED' && !asPlataformLead"
      >
        Rejeitado
      </div>
      <div
        class="badge-text badge-warning"
        *ngIf="data?.status === 'IN_REGISTRATION' && !asPlataformLead"
      >
        Em registro
      </div>
      <div
        class="badge-text badge-success"
        *ngIf="data?.status === 'ACTIVE' && !asPlataformLead"
      >
        Ativo
      </div>
      <div
        class="badge-text"
        *ngIf="data?.status === 'DISABLED' && !asPlataformLead"
      >
        Desligado
      </div>
      <div
        class="badge-text"
        *ngIf="data?.status === 'NOT_USED' && !asPlataformLead"
      >
        Não usado
      </div>

      <div
        class="badge-text"
        *ngIf="data?.situation === 'NOT_INTERESTED' && asPlataformLead"
      >
        Sem interesse
      </div>
      <div
        class="badge-text badge-warning"
        *ngIf="data?.situation === 'ANALYSIS' && asPlataformLead"
      >
        Em análise
      </div>
      <div
        class="badge-text badge-success"
        *ngIf="data?.situation === 'PRE_APPROVED' && asPlataformLead"
      >
        Pré-aprovado
      </div>
      <div
        class="badge-text badge-success"
        *ngIf="data?.situation === 'APPROVED' && asPlataformLead"
      >
        Aprovado
      </div>
      <div
        class="badge-text badge-danger"
        *ngIf="data?.situation === 'REJECTED' && asPlataformLead"
      >
        Rejeitado
      </div>
    </div>

    <div class="actions">
      <button
        class="mr-1"
        mat-icon-button
        matTooltip="Baixar documentos"
        (click)="download(data.cnpj, data.name)"
      >
        <mat-icon>download</mat-icon>
      </button>
      <button
        class="mr-1"
        mat-icon-button
        *ngIf="data?.canEdit"
        (click)="openEditDialog()"
        matTooltip="Editar"
      >
        <mat-icon>settings</mat-icon>
      </button>
      <button
        *ngIf="showActions && data?.status !== 'REJECTED'"
        mat-stroked-button
        class="mx-1"
        color="warn"
        (click)="changeStatus('REJECTED', data)"
      >
        REJEITAR
      </button>
      <button
        *ngIf="
          showActions &&
          data?.status !== 'NOT_INTERESTED' &&
          this.leadSource !== 'ContaAzul' &&
          this.leadSource !== 'BaseB'
        "
        mat-stroked-button
        class="mx-1"
        (click)="changeStatus('NOT_INTERESTED', data)"
      >
        SEM INTERESSE
      </button>
      <ng-container *ngIf="showActions && data?.status !== 'APPROVED'">
        <button
          *ngIf="
            leadSource === 'BaseB' ||
            leadSource === 'ContaAzul' ||
            leadSource === 'Bom'
          "
          mat-flat-button
          class="ml-1"
          color="primary"
          (click)="
            changeStatus(
              (data.situation === 'PRE_APPROVED' || data.situation === 'APPROVED') ? 'APPROVED' : 'PRE_APPROVED',
              data
            )
          "
        >
          {{ (data.situation === "PRE_APPROVED" || data.situation === 'APPROVED') ? "APROVAR" : "PRÉ APROVAR" }}
        </button>

        <button
          *ngIf="
            leadSource !== 'BaseB' &&
            leadSource !== 'ContaAzul' &&
            leadSource !== 'Bom'
          "
          mat-flat-button
          class="ml-1"
          color="primary"
          (click)="changeStatus('APPROVED', data)"
        >
          APROVAR
        </button>
      </ng-container>
    </div>
  </div>

  <app-blankslate *ngIf="!loading && error">
    <div text>
      <p>
        Não localizamos nenhum cliente com o
        <b>CNPJ {{ customerCNPJ | mask : "CPF_CNPJ" }}</b>
      </p>
    </div>
  </app-blankslate>

  <app-loading *ngIf="loading"></app-loading>

  <mat-tab-group mat-align-tabs="center" *ngIf="!loading && !error">
    <mat-tab label="DADOS">
      <div class="container-data mt-4" *ngIf="!loading && !error">
        <div class="w-100">
          <mat-accordion multi>
            <mat-expansion-panel opened>
              <mat-expansion-panel-header>
                <mat-panel-title>Empresa</mat-panel-title>
              </mat-expansion-panel-header>

              <div class="list-item">
                <span class="list-item__title">Nome comercial</span>
                <span class="list-item__text">{{
                  data.tradeName ? data.tradeName : "-"
                }}</span>
                <hr />
              </div>
              <div class="list-item">
                <span class="list-item__title">Site</span>
                <span class="list-item__text">{{
                  data.website ? data.website : "-"
                }}</span>
                <hr />
              </div>
              <div class="list-item editing-mail">
                <span class="list-item__title">Email</span>
                <span *ngIf="!data?.editingEmail" class="list-item__text">{{
                  data.mailInformed ? data.mailInformed : "-"
                }}</span>
                <input
                  [(ngModel)]="editedEmail"
                  [value]="data?.mailInformed"
                  class="input-edit"
                  *ngIf="data?.editingEmail"
                  type="text"
                  matInput
                />
                <mat-icon
                  matTooltip="Editar Email"
                  class="edit-icon"
                  *ngIf="data.canEditEmail && !data?.editingEmail"
                  (click)="openEditEmailDialog()"
                  >edit</mat-icon
                >
                <div *ngIf="data?.editingEmail" class="buttons-container">
                  <button
                    class="button-save"
                    mat-button
                    (click)="saveMainMail()"
                  >
                    Salvar
                  </button>
                  <button
                    (click)="data.editingEmail = false"
                    mat-button
                    color="primary"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
              <hr />
              <div class="list-item">
                <span class="list-item__title">Regime</span>
                <span class="list-item__text">{{
                  data.regime ? data.regime : "-"
                }}</span>
                <hr />
              </div>
              <div class="list-item">
                <span class="list-item__title">Data de abertura</span>
                <span class="list-item__text">{{
                  data.openedOn ? data.openedOn : "-"
                }}</span>
                <hr />
              </div>
              <div class="list-item">
                <span class="list-item__title">Natureza legal</span>
                <span class="list-item__text">{{
                  data.legalNatureCode ? data.legalNatureCode : "-"
                }}</span>
                <hr />
              </div>
              <div class="list-item">
                <span class="list-item__title">Unidade</span>
                <span class="list-item__text">{{
                  data.unit == "BRANCH" ? "Filial" : "Matriz"
                }}</span>
                <hr />
              </div>

              <div class="list-item">
                <span class="list-item__title">Faturamento anual</span>
                <span class="list-item__text">{{
                  data.annualBilling ? (data.annualBilling | currency) : "-"
                }}</span>
                <hr />
              </div>
              <div class="list-item">
                <span class="list-item__title">Capital social</span>
                <span class="list-item__text">{{
                  data.sharedCapital ? (data.sharedCapital | currency) : "-"
                }}</span>
                <hr />
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>Contatos</mat-panel-title>
              </mat-expansion-panel-header>

              <div
                class="list-item"
                *ngFor="let contact of data.contacts; let i = index"
              >
                <b class="d-block pt-4 pb-3">
                  {{ contact.name | uppercase }}
                </b>

                <div class="d-flex">
                  <span class="list-item w-50 d-flex flex-column">
                    <span class="list-item__title block">E-mail</span>
                    <span class="list-item__text d-block">{{
                      contact.mail
                    }}</span>
                    <hr />
                  </span>
                  <span class="list-item w-25 d-flex flex-column">
                    <span class="list-item__title">Telefone</span>
                    <span class="list-item__text">{{ contact.phone }}</span>
                    <hr />
                  </span>
                </div>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>Endereço</mat-panel-title>
              </mat-expansion-panel-header>

              <div class="list-item">
                <span class="list-item__title">CEP</span>
                <span class="list-item__text">{{
                  data.address?.postalCode ? data.address?.postalCode : "-"
                }}</span>
                <hr />
              </div>

              <div class="list-item">
                <span class="list-item__title">Endereço</span>
                <span class="list-item__text"
                  >{{ data.address?.street ? data.address?.street : "-" }}
                  {{ data.address?.number ? data.address?.number : "-" }}</span
                >
                <hr />
              </div>

              <div class="list-item">
                <span class="list-item__title">Complemento</span>
                <span class="list-item__text">{{
                  data.address?.additionalInformation
                    ? data.address?.additionalInformation
                    : "-"
                }}</span>
                <hr />
              </div>

              <div class="list-item">
                <span class="list-item__title">Bairro</span>
                <span class="list-item__text">{{
                  data.address?.district ? data.address?.district : "-"
                }}</span>
                <hr />
              </div>

              <div class="list-item">
                <span class="list-item__title">Cidade</span>
                <span class="list-item__text"
                  >{{ data.address?.city ? data.address?.city : "-" }} /
                  {{ data.address?.state ? data.address?.state : "-" }}</span
                >
                <hr />
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel [disabled]="!data.banks?.length">
              <mat-expansion-panel-header>
                <mat-panel-title>Bancos</mat-panel-title>
                <mat-panel-description *ngIf="!data.banks?.length">
                  Sem dados cadastrados
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div *ngFor="let bank of data.banks" class="d-flex">
                <div class="list-item w-25 d-flex flex-column me-2">
                  <span class="list-item__title">Banco</span>
                  <span class="list-item__text"
                    >{{ bank?.code }}-{{ bank?.name }}</span
                  >
                  <hr />
                </div>
                <div class="list-item w-25 d-flex flex-column">
                  <span class="list-item__title">Conta</span>
                  <span class="list-item__text">{{ bank?.account }}</span>
                  <hr />
                </div>
                <div class="list-item w-25 d-flex flex-column">
                  <span class="list-item__title">Agência</span>
                  <span class="list-item__text">
                    {{ bank?.code }}-{{ bank?.agency }}
                  </span>
                  <hr />
                </div>

                <div class="list-item w-25 d-flex flex-column">
                  <span class="list-item__title">Chave Pix</span>
                  <span class="list-item__text">{{ bank?.pix }}</span>
                  <hr />
                </div>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel [disabled]="!data.economicActivities?.length">
              <mat-expansion-panel-header>
                <mat-panel-title>Atividade econômica</mat-panel-title>
                <mat-panel-description *ngIf="!data.economicActivities?.length">
                  Sem dados cadastrados
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div
                class="list-item"
                *ngFor="let item of data.economicActivities"
              >
                <span class="list-item__text">
                  {{ item?.code }} - {{ item?.name }}
                </span>
                <hr />
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel [disabled]="!data.partners?.length">
              <mat-expansion-panel-header>
                <mat-panel-title>Quadro societário</mat-panel-title>
                <mat-panel-description *ngIf="!data.partners?.length">
                  Sem dados cadastrados
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div *ngFor="let partner of data.partners">
                <b class="d-block pt-4 pb-3">{{ partner.name }}</b>
                <div class="list-item">
                  <span class="list-item__title">CPF</span>
                  <span class="list-item__text">
                    {{
                      partner.cpfCnpj
                        ? (partner.cpfCnpj | mask : "CPF_CNPJ")
                        : "-"
                    }}
                  </span>
                  <hr />
                </div>
                <div class="list-item">
                  <span class="list-item__title">E-mail</span>
                  <span class="list-item__text">{{ partner.mail }} </span>
                  <hr />
                </div>
                <div class="list-item">
                  <span class="list-item__title">Telefone</span>
                  <span class="list-item__text">
                    {{
                      partner.phone
                        ? (partner.phone | mask : "(00) 0000-00000")
                        : "-"
                    }}
                  </span>
                  <hr />
                </div>
                <div class="list-item">
                  <span class="list-item__title">Qualificação</span>
                  <span class="list-item__text">
                    {{
                      partner.qualificationCode
                        ? partner.qualificationCode
                        : "-"
                    }}</span
                  >
                  <hr />
                </div>

                <div class="list-item">
                  <span class="list-item__title">Sócio Assinante</span>
                  <span class="list-item__text">
                    {{ partner.subscriber ? "Sim" : "Não" }}</span
                  >
                  <hr />
                </div>
                <div class="list-item">
                  <span class="list-item__title">Sócio Solidário</span>
                  <span class="list-item__text">
                    {{ partner.solidaryPartner ? "Sim" : "Não" }}</span
                  >
                  <hr />
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div class="timeline">
          <app-customer-timeline
            [customerId]="data.id"
            [isLead]="isLead"
          ></app-customer-timeline>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="DOCUMENTOS">
      <div class="container-data mt-4" *ngIf="!loading && !error">
        <div class="timeline ml-0">
          <mat-selection-list #listDocuments [multiple]="false">
            <label><b>Empresa</b></label>
            <div
              *ngFor="let doc of data.documents"
              [matTooltip]="
                canModifyStatusDocuments
                  ? documentStatusStyle[doc.status].tooltip
                  : ''
              "
            >
              <mat-list-option
                [value]="doc"
                [disabled]="doc.status === 'NOT_IMPORTED'"
                *ngIf="
                  (doc.file && doc.type !== 'NF') ||
                  doc.status === 'NOT_IMPORTED'
                "
                (click)="getDocument('company', doc.id, doc.file)"
                [ngStyle]="{
                  color: canModifyStatusDocuments
                    ? documentStatusStyle[doc.status || 'PENDING'].style
                    : 'black'
                }"
              >
                <div mat-line class="d-flex flex-column">
                  <span>{{ doc.description }}</span>
                  <span *ngIf="doc.file"
                    ><small>Data de Inclusão: {{ doc.createdAt }}</small></span
                  >
                </div>
                <mat-icon
                  mat-list-icon
                  *ngIf="
                    canModifyStatusDocuments &&
                    (doc.file || doc.status === 'NOT_IMPORTED')
                  "
                  >{{
                    documentStatusStyle[doc.status]?.icon || "insert_drive_file"
                  }}</mat-icon
                >
              </mat-list-option>
            </div>

            <div *ngFor="let partner of data.partners">
              <hr />
              <label
                ><b>Sócio: {{ partner.name }}</b></label
              >
              <div
                *ngFor="let doc of partner.documents"
                [matTooltip]="
                  canModifyStatusDocuments
                    ? documentStatusStyle[doc.status].tooltip
                    : ''
                "
              >
                <mat-list-option
                  [value]="doc"
                  [disabled]="doc.status === 'NOT_IMPORTED'"
                  *ngIf="doc.file || doc.status === 'NOT_IMPORTED'"
                  (click)="getDocument('partner', doc.id, doc.file)"
                  [ngStyle]="{
                    color: canModifyStatusDocuments
                      ? documentStatusStyle[doc.status || 'PENDING'].style
                      : 'black'
                  }"
                >
                  <mat-icon
                    mat-list-icon
                    *ngIf="
                      canModifyStatusDocuments &&
                      (doc.file || doc.status === 'NOT_IMPORTED')
                    "
                    >{{
                      documentStatusStyle[doc.status]?.icon ||
                        "insert_drive_file"
                    }}</mat-icon
                  >
                  <div mat-line class="d-flex flex-column">
                    <span>{{ doc.description }}</span>
                    <span *ngIf="doc.file"
                      ><small
                        >Data de Inclusão: {{ doc.createdAt }}</small
                      ></span
                    >
                  </div>
                </mat-list-option>
              </div>
            </div>
          </mat-selection-list>
        </div>

        <div class="container-doc-viewer">
          <div
            class="container justify-content-between"
            *ngIf="
              canModifyStatusDocuments &&
              url &&
              listDocuments.selectedOptions.selected[0]?.value.status ===
                'PENDING'
            "
          >
            <div class="label">
              <mat-icon>insert_drive_file</mat-icon>
              <p>Este documento está legivel e apto para análise?</p>
            </div>

            <div class="actions-container">
              <button
                mat-stroked-button
                class="mx-1"
                color="warn"
                (click)="
                  approvedOrRejectedDocument(
                    listDocuments.selectedOptions.selected[0]?.value,
                    'REJECTED'
                  )
                "
              >
                REJEITAR
              </button>
              <button
                mat-flat-button
                color="primary"
                (click)="
                  approvedOrRejectedDocument(
                    listDocuments.selectedOptions.selected[0]?.value,
                    'APPROVED'
                  )
                "
              >
                APROVAR
              </button>
            </div>
          </div>
          <doc-viewer-angular
            [url]="url"
            [urlTypeFile]="
              listDocuments.selectedOptions.selected[0]?.value.file
            "
            [fileName]="
              listDocuments.selectedOptions.selected[0]?.value.description
            "
            [loading]="loadingDocument"
          >
          </doc-viewer-angular>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="NF - Análise de Crédito" *ngIf="data.type == 'LEAD'">
      <div class="container-data mt-4" *ngIf="!loading && !error">
        <div class="timeline ml-0">
          <mat-selection-list #listDocuments [multiple]="false">
            <label><b>Empresa</b></label>
            <div
              *ngFor="let doc of data.documents; let index = index"
              [matTooltip]="
                canModifyStatusDocuments
                  ? documentStatusStyle[doc.status].tooltip
                  : ''
              "
            >
              <mat-list-option
                [value]="doc"
                *ngIf="doc.file && doc.type === 'NF'"
                (click)="getDocument('company', doc.id, doc.file)"
              >
                <div mat-line class="d-flex flex-column">
                  <span>{{ doc.description }} {{ index + 1 }}</span>
                </div>
                <mat-icon mat-list-icon> insert_drive_file </mat-icon>
              </mat-list-option>
            </div>
          </mat-selection-list>
        </div>

        <div class="container-doc-viewer">
          <doc-viewer-angular
            [url]="url"
            [urlTypeFile]="
              listDocuments.selectedOptions.selected[0]?.value.file
            "
            [fileName]="
              listDocuments.selectedOptions.selected[0]?.value.description
            "
            [loading]="loadingDocument"
          >
          </doc-viewer-angular>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
