import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { forkJoin, of } from 'rxjs';
import { debounceTime, mergeMap, switchMap, take } from 'rxjs/operators';
import {
  BuyerDetailsFilterInterface,
  BuyerDetailsFinancialInterface,
} from 'src/app/interfaces/buyer-details-financial.interface';
import { FilterOptionsInterface } from 'src/app/interfaces/filterOptions.interface';
import {
  ContaAzulTransferDetailFilterInterface,
  TransferContaAzulInterface,
} from 'src/app/interfaces/transfer-conta-azul.interface';
import { BuyerFinancialService } from 'src/app/services/buyer-financial.service';
import { FinancialService } from 'src/app/services/financial.service';
import { LayoutService } from 'src/app/services/layout.service';
import { TitlePageService } from 'src/app/services/title-page.service';
@Component({
  selector: 'app-contaazul-details-financial',
  templateUrl: './contaazul-details-financial.component.html',
  styleUrls: ['./contaazul-details-financial.component.scss'],
})
export class ContaazulDetailsFinancialComponent
  implements OnInit, AfterViewInit
{
  formFilters!: FormGroup;
  lastPage = false;
  leadsOptions: FilterOptionsInterface[] = [];
  sacadoOptions: FilterOptionsInterface[] = [];
  pageIndex = 0;
  pageSize = 20;
  totalValue!: number;
  cardsValue!: TransferContaAzulInterface;
  isLoading = true;
  constructor(
    private layoutService: LayoutService,
    private titlePageService: TitlePageService,
    private financialService: FinancialService,
    private buyerFinancialService: BuyerFinancialService
  ) {
    this.setHeader();

    this.formFilters = new FormGroup({
      lead: new FormControl(null),
      sacado: new FormControl(null),
      installment: new FormControl(null),
    });
    this.formFilters.controls.lead.valueChanges
      .pipe(
        debounceTime(500),
        switchMap((data: string): any => {
          if (
            typeof data !== 'object' &&
            data?.trimStart() !== '' &&
            data?.trimEnd() !== '' &&
            data?.length > 2
          ) {
            this.getLeadsFinancial(data);
          }

          return of('');
        })
      )
      .subscribe();

    this.formFilters.controls.sacado.valueChanges
      .pipe(
        debounceTime(500),
        switchMap((data: string): any => {
          if (
            typeof data !== 'object' &&
            data?.trimStart() !== '' &&
            data?.trimEnd() !== '' &&
            data?.length > 2
          ) {
            this.getSacadoFinancial(data);
          }

          return of('');
        })
      )
      .subscribe();
  }

  getLeadsFinancial(filter?: string) {
    this.financialService
      .getLeadsFinancial(filter)
      .pipe(take(1))
      .subscribe((res) => {
        this.leadsOptions = res.content;
      });
  }
  getSacadoFinancial(filter?: string) {
    this.financialService
      .getSacadoFinancial(filter)
      .pipe(take(1))
      .subscribe((res) => {
        this.sacadoOptions = res.content;
      });
  }
  displayLEAD(lead?: FilterOptionsInterface) {
    return this.leadsOptions.find((_) => _.id === lead?.id)?.name ?? '';
  }
  displaySacado(sacado?: FilterOptionsInterface) {
    return this.sacadoOptions.find((_) => _.id === sacado?.id)?.name ?? '';
  }
  displayedColumns: string[] = [
    'cedente',
    'sacado',
    'comprador',
    'number',
    'vencimento',
    'valor de face',
    'valorLiquidação',
    'dataLiquidacão',
    'valor',
  ];
  dataSource: BuyerDetailsFinancialInterface[] = [];
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.layoutService.hideMenu();
    });
  }
  setHeader(): void {
    this.titlePageService.setHeader({
      title: 'Detalhes',
      subtitle: '',
      locationBack: true,
    });
  }
  getDetailsFinancialBuyers() {
    const lead = this.formFilters.controls['lead']?.value;
    const drawee = this.formFilters.controls['sacado']?.value;
    const installmentNumber = this.formFilters.controls['installment']?.value;

    let requestData: BuyerDetailsFilterInterface = {
      leadId: lead?.id,
      draweeId: drawee?.id,
      installment: installmentNumber,
      type: 'EXPENSE',
      category: 'COMMISSION',
      date: new Intl.DateTimeFormat('pt-BR').format(
        new Date(this.buyerFinancialService.getDate())
      ),
    };

    const monthClosureFilters: ContaAzulTransferDetailFilterInterface = {
      leadId: lead?.id,
      draweeId: drawee?.id,
      installment: installmentNumber,
    };
    const monthClosureFiltersNotNullValues: any = {};
    Object.keys(monthClosureFilters).map((key) => {
      if (
        !!monthClosureFilters[
          key as keyof ContaAzulTransferDetailFilterInterface
        ]
      ) {
        monthClosureFiltersNotNullValues[
          key as keyof ContaAzulTransferDetailFilterInterface
        ] =
          monthClosureFilters[
            key as keyof ContaAzulTransferDetailFilterInterface
          ];
      }
    });

    const requestDataNotNullValues: any = {};

    Object.keys(requestData).map((key) => {
      if (!!requestData[key as keyof ContaAzulTransferDetailFilterInterface]) {
        requestDataNotNullValues[
          key as keyof ContaAzulTransferDetailFilterInterface
        ] = requestData[key as keyof ContaAzulTransferDetailFilterInterface];
      }
    });

    const formatedDate = new Intl.DateTimeFormat('pt-br', {
      month: 'numeric',
      year: 'numeric',
    }).format(new Date(this.buyerFinancialService.getDate()));
    
    this.financialService
      .getFiltersDetailContaAzul(formatedDate, monthClosureFiltersNotNullValues)
      .pipe(
        mergeMap((res) => {
          this.cardsValue = res;

          if (!this.lastPage) {
            const totalValue$ = this.financialService.geTotalValue(
              requestDataNotNullValues
            );
            const buyersDetails$ =
              this.financialService.getFinancialBuyersDetails(
                this.pageIndex,
                this.pageSize,
                requestDataNotNullValues
              );

            return forkJoin({ totalValue$, buyersDetails$ });
          } else {
            return of(null); // No additional processing needed
          }
        })
      )
      .subscribe((res) => {
        this.isLoading = false;
        const totalValue = res?.totalValue$;
        const buyersDetails = res?.buyersDetails$;

        if (totalValue !== null && totalValue !== undefined) {
          this.totalValue = totalValue * this.cardsValue.rebatePercentage;
        }

        if (buyersDetails !== null && buyersDetails !== undefined) {
          const leads = buyersDetails.content.map((i) => {
            i.value = i.paidValue * (this.cardsValue.rebatePercentage / 100);
            return i;
          });
          this.dataSource = this.dataSource.concat(leads); /* [...this.dataSource, ...leads] */
          if (!buyersDetails.last) this.pageIndex = this.pageIndex + 1;
          if (buyersDetails.last) this.lastPage = true;
        }
      });
  }
  clearFilters() {
    this.formFilters.reset();
    this.handleSubmit();
  }
  handleSubmit() {
    this.dataSource = [];
    this.lastPage = false;
    this.pageIndex = 0;
    this.isLoading = true;
    this.getDetailsFinancialBuyers();
  }
  handleAutocompleteBlur() {
    const lead = this.formFilters.controls['lead'].value;
    if (!lead?.id) {
      this.formFilters.patchValue({ lead: null });
    }
  }

  handleAutocompleteSacadoBlur() {
    const sacado = this.formFilters.controls['sacado'].value;
    if (!sacado?.id) {
      this.formFilters.patchValue({ sacado: null });
    }
  }

  ngOnInit(): void {
    this.getDetailsFinancialBuyers();
  }
}
