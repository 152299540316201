import { MenuService } from 'src/app/services/menu.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserInterface } from '../interfaces/user';
import { AuthService } from '../services/auth.service';
import { RouteService } from '../services/route.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

   user!: UserInterface | any;
   canActivateRoute!: boolean
  constructor(public auth: AuthService, public router: Router, private routeService: RouteService, private menuService : MenuService) {
    this.user = auth.user.value;
  }

   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url = state.url.substring(1)
    this.routeService.currURL.next(url)

    if(!this.auth.isAuthenticated()) {
      this.router.navigate(['/login']);
      this.canActivateRoute = false;
      return this.canActivateRoute;
    } else {
      this.menuService.setModulesMenu[route.data['menu']] ? this.menuService.setModulesMenu[route.data['menu']]() : null;
      this.canActivateRoute = true;
      return this.canActivateRoute;
    }


   }

}
