import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  CustomerFinanceDetailInterface,
  discountEntries,
  FinanceInterface,
} from '../interfaces/finance';
import {
  BuyerFinancialFilterInterface,
  BuyerFinancialResponseInterface,
} from '../interfaces/buyer-financial.interface';
import {
  BuyerDetailsFilterInterface,
  BuyerDetailsResponseInterface,
} from '../interfaces/buyer-details-financial.interface';
import {
  ContaAzulTransferDetailFilterInterface,
  TransferContaAzulInterface,
} from '../interfaces/transfer-conta-azul.interface';

@Injectable({
  providedIn: 'root',
})
export class FinancialService {
  initialDataFilter: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private http: HttpClient) {}

  getAll(month: any, year: any): Observable<FinanceInterface> {
    let headers = new HttpHeaders();
    headers = headers
      .set('Accept', '*/*')
      .set('Month', `${month}`)
      .set('Year', `${year}`);
    return this.http.get<FinanceInterface>(
      `${environment.finance_url}:8091/api/bill/summary`,
      { headers }
    );
  }

  getById(
    id: any,
    month: any,
    year: any
  ): Observable<CustomerFinanceDetailInterface> {
    let headers = new HttpHeaders();
    headers = headers
      .set('Accept', '*/*')
      .set('Month', `${month}`)
      .set('TenantId', `${id}`)
      .set('Year', `${year}`);
    return this.http.get<CustomerFinanceDetailInterface>(
      `${environment.finance_url}:8091/api/bill/summary/tenant`,
      { headers }
    );
  }

  addDiscount(
    discountData: discountEntries,
    month: string,
    year: string
  ): Observable<discountEntries> {
    let headers = new HttpHeaders();
    headers = headers
      .set('Accept', '*/*')
      .set('Month', `${month}`)
      .set('Year', `${year}`);
    return this.http.post<discountEntries>(
      `${environment.finance_url}:8091/api/account-entry/discount`,
      discountData,
      { headers }
    );
  }
  downloadInvoice(id: any, month: any, year: any) {
    let headers = new HttpHeaders();
    headers = headers
      .set('Accept', '*/*')
      .set('Month', `${month}`)
      .set('TenantId', `${id}`)
      .set('Year', `${year}`);
    return this.http.get(
      environment.finance_url + ':8091/api/bill/summary/tenant/report',
      { headers, responseType: 'blob' }
    );
  }

  getFinancialBuyers(
    pageIndex: number,
    pageSize: number,
    data: BuyerFinancialFilterInterface
  ) {
    return this.http.post<BuyerFinancialResponseInterface>(
      `${environment.financial_contaazul}/api/bill/summary/${pageIndex}/${pageSize}`,
      data
    );
  }
  getFinancialBuyersDetails(
    pageIndex: number,
    pageSize: number,
    data: BuyerDetailsFilterInterface
  ) {
    return this.http.post<BuyerDetailsResponseInterface>(
      `${environment.financial_contaazul}/api/bill/detail/${pageIndex}/${pageSize}`,
      data
    );
  }

  getLeadsFinancial(filter?: string): Observable<{ content: any[] }> {
    return this.http.get<{ content: any[] }>(
      environment.register_api +
        `secure-api/company/getall/LEAD/0/50/contaazul?filter=${filter || ''}`
    );
  }
  getSacadoFinancial(filter?: string): Observable<{ content: any[] }> {
    return this.http.get<{ content: any[] }>(
      environment.API_url +
        `management-api/customers/legacy/SACADO/status/NOT_CONFIRMED/0/50/?filter=${
          filter || ''
        }`
    );
  }
  geTotalValue(data: BuyerDetailsFilterInterface) {
    return this.http.post<number>(
      `${environment.financial_contaazul}/api/bill/detail/total`,
      data
    );
  }
  getFilterSearchBuyers(filter?: string): Observable<{ content: any[] }> {
    return this.http.get<{ content: any[] }>(
      environment.API_url +
        `management-api/customers/legacy/BUYER/status/ACTIVE/0/50/?filter=${
          filter || ''
        }`
    );
  }
  getTransferContaAzul(date: string) {
    return this.http.get<TransferContaAzulInterface>(
      `${environment.financial_contaazul}/api/month-closure/${date}`
    );
  }
  getFiltersDetailContaAzul(
    date: string,
    data: ContaAzulTransferDetailFilterInterface
  ) {
    return this.http.post<TransferContaAzulInterface>(
      `${environment.financial_contaazul}/api/month-closure/filters/${date}`,
      data
    );
  }
}
