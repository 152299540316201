<div class="container-page">
  <form [formGroup]="myForm" class="form-page">
    <h2>Trocar a senha</h2>

    <div class="alert alert-success mt-3" role="alert" *ngIf="successResponse">
      Senha alterada com sucesso!
    </div>

    <div class="alert alert-danger mt-3" role="alert" *ngIf="errorResponse">
      Ocorreu um erro ao tentar trocar a senha, tente novamente!.
    </div>

    <mat-form-field appearance="outline" class="mb-2">
      <mat-label>Senha</mat-label>
      <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required>
      <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide">
        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
      <mat-hint>Mínimo 6 dígitos</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Confirmar senha</mat-label>
      <input matInput [errorStateMatcher]="matcher" [type]="hide ? 'password' : 'text'"
        formControlName="confirmPassword" required>
      <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide">
        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
      <mat-error *ngIf="myForm.hasError('notSame')">
        As senhas não coincidem
      </mat-error>
    </mat-form-field>

    <button class="mt-3 mb-5" mat-flat-button color="primary" [disabled]="myForm.hasError('notSame') || !myForm.valid"
      (click)="changePassword()">Trocar Senha</button>

  </form>
</div>