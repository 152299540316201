<div class="timeline">
  <b class="d-block"
    >Adicionar histórico
    <mat-slide-toggle
      class="ml-3"
      [(ngModel)]="showAddHistory"
    ></mat-slide-toggle
  ></b>

  <form class="pt-4" [hidden]="!showAddHistory" [formGroup]="formData">
    <mat-form-field appearance="outline" floatLabel="always" class="w-100 mb-0">
      <mat-label>Descrição</mat-label>
      <textarea formControlName="observation" matInput required></textarea>
    </mat-form-field>

    <small class="text-secondary w-100 d-block">
      Tipo de registro
      <b [ngClass]="{ 'text-danger': !formData.get('type')?.value }">*</b>
    </small>
    <mat-button-toggle-group formControlName="type">
      <mat-button-toggle matTooltip="Contato via telefone" value="PHONE"
        ><mat-icon>phone</mat-icon></mat-button-toggle
      >
      <mat-button-toggle matTooltip="Contato via e-mail" value="MAIL"
        ><mat-icon>mail</mat-icon></mat-button-toggle
      >
      <mat-button-toggle matTooltip="Anotação" value="NOTE"
        ><mat-icon>sticky_note_2</mat-icon></mat-button-toggle
      >
    </mat-button-toggle-group>

    <button
      *ngIf="!savingData"
      mat-stroked-button
      color="primary"
      class="float-right p-2"
      [disabled]="!formData.valid"
      (click)="addHistoric()"
    >
      salvar
    </button>
    <button
      *ngIf="savingData"
      mat-stroked-button
      color="primary"
      class="float-right p-2"
      disabled
    >
      <mat-spinner class="d-inline-block mr-3" [diameter]="20"></mat-spinner
      >Salvando
    </button>
  </form>

  <hr />
  <b class="pb-4 d-block">Histórico</b>

  <mat-spinner class="mx-auto p-20" *ngIf="loading"></mat-spinner>

  <div class="blankslate" *ngIf="!timeline?.length && !loading">
    <mat-icon class="text-secondary">content_paste_off</mat-icon>
    <p class="text-secondary">Não localizamos histórico para este cliente.</p>
  </div>

  <ng-container *ngIf="timeline?.length && !loading">
    <app-timeline
      *ngFor="let item of timeline; last as isLast"
      [lastChild]="!!isLast"
      [icon]="
        item.type === 'PHONE'
          ? 'phone'
          : item.type === 'MAIL'
          ? 'mail'
          : item.type === 'NOTE'
          ? 'sticky_note_2'
          : item.type === 'RESPONSIBLE'
          ? 'supervisor_account'
          : 'autorenew'
      "
      [bgIcon]="
        item.type === 'PHONE'
          ? '#aadcb5'
          : item.type === 'MAIL'
          ? '#aac0dc'
          : item.type === 'NOTE'
          ? '#e6dfa4'
          : item.type === 'RESPONSIBLE'
          ? '#ffbbbb'
          : ''
      "
    >
      <div content>
        <span
          ><b>{{ item.responsible }} </b> {{ item.observation }}</span
        >
        <br />
        <small class="text-secondary">{{ item.date }}</small>
      </div>
    </app-timeline>
  </ng-container>
</div>
