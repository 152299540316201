import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import moment, { Moment } from 'moment';
import { FinancialService } from 'src/app/services/financial.service';
import {
  BuyerDetailsFilterInterface,
  BuyerDetailsFinancialInterface,
} from 'src/app/interfaces/buyer-details-financial.interface';
import { BuyerFinancialService } from 'src/app/services/buyer-financial.service';
import { debounceTime, switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { CategoryFinancialList } from 'src/app/utils/categoryFinancial-lists';
import { SelectionModel } from '@angular/cdk/collections';
import { DocumentFinancialList } from 'src/app/utils/documentFinancial.lists';
import { Router } from '@angular/router';
import {
  getCategoryColor,
  getCategoryName,
} from 'src/app/utils/buyer-details.utils';
import { LayoutService } from 'src/app/services/layout.service';
import { TitlePageService } from 'src/app/services/title-page.service';
import { FilterOptionsInterface } from 'src/app/interfaces/filterOptions.interface';

@Component({
  selector: 'app-buyer-details-financial',
  templateUrl: './buyer-details-financial.component.html',
  styleUrls: ['./buyer-details-financial.component.scss'],
})
export class BuyerDetailsFinancialComponent implements OnInit, AfterViewInit {
  formFilters!: FormGroup;
  pageIndex = 0;
  pageSize = 20;
  categoryOptions = CategoryFinancialList.list();
  documentOptions = DocumentFinancialList.list();
  company!: string;
  date!: string;
  selection = new SelectionModel<BuyerDetailsFinancialInterface>(true, []);
  row!: BuyerDetailsFinancialInterface;
  totalValue!: number;
  operationDelete = new SelectionModel<BuyerDetailsFinancialInterface>(
    true,
    []
  );
  isLoading = true;


  constructor(
    private financialService: FinancialService,
    private buyerFinancialService: BuyerFinancialService,
    private router: Router,
    private layoutService: LayoutService,
    private titlePageService: TitlePageService
  ) {
    this.setHeader();
    this.formFilters = new FormGroup({
      lead: new FormControl(),
      sacado: new FormControl(),
      additiveNumber: new FormControl(),
      titleNumber: new FormControl(),
      category: new FormControl(),
    });
    this.formFilters.controls.lead.valueChanges
      .pipe(
        debounceTime(500),
        switchMap((data: string): any => {
          if (
            typeof data !== 'object' &&
            data?.trimStart() !== '' &&
            data?.trimEnd() !== '' &&
            data?.length > 2
          ) {
            this.getLeadsFinancial(data);
          }

          return of('');
        })
      )
      .subscribe();

    this.formFilters.controls.sacado.valueChanges
      .pipe(
        debounceTime(500),
        switchMap((data: string): any => {
          if (
            typeof data !== 'object' &&
            data?.trimStart() !== '' &&
            data?.trimEnd() !== '' &&
            data?.length > 2
          ) {
            this.getSacadoFinancial(data);
          }

          return of('');
        })
      )
      .subscribe();
  }

  displayedColumns: string[] = [
    'select',
    'cedente',
    'sacado',
    'valor de face',
    'valor liquidado',
    'vencimento',
    'numero do titulo',
    'data',
    'documento',
    'aditivo',
    'valor',
    'categoria',
  ];
  dataSource: BuyerDetailsFinancialInterface[] = [];
  lastPage = false;
  leadsOptions: FilterOptionsInterface[] = [];
  sacadoOptions: FilterOptionsInterface[] = [];

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.layoutService.hideMenu();
    });
  }

  setHeader(): void {
    this.titlePageService.setHeader({
      title: 'Detalhes',
      subtitle: '',
      locationBack: true,
    });
  }

  getSacadoFinancial(filter?: string) {
    this.financialService
      .getSacadoFinancial(filter)
      .pipe(take(1))
      .subscribe((res) => {
        this.sacadoOptions = res.content;
        console.log(res);
      });
  }

  getLeadsFinancial(filter?: string) {
    this.financialService
      .getLeadsFinancial(filter)
      .pipe(take(1))
      .subscribe((res) => {
        this.leadsOptions = res.content;
        console.log(res);
      });
  }
  displayLEAD(lead?: FilterOptionsInterface) {
    return this.leadsOptions.find((_) => _.id === lead?.id)?.name ?? '';
  }
  displaySacado(sacado?: FilterOptionsInterface) {
    return this.sacadoOptions.find((_) => _.id === sacado?.id)?.name ?? '';
  }
  getDetailsFinancialBuyers() {
    const tenantId = this.buyerFinancialService.getTenantId();
    const date = this.buyerFinancialService.getDate();
    const formattedDate = new Intl.DateTimeFormat('pt-br').format(
      new Date(date)
    );
    const lead = this.formFilters.controls['lead']?.value;
    const drawee = this.formFilters.controls['sacado']?.value;
    const additiveNumber = this.formFilters.controls['additiveNumber']?.value;
    const titleNumber = this.formFilters.controls['titleNumber']?.value;
    const category = this.formFilters.controls['category']?.value?.category;
    const type = this.formFilters.controls['category']?.value?.type;
    let requestData: BuyerDetailsFilterInterface = {
      leadId: lead?.id,
      draweeId: drawee?.id,
      additive: additiveNumber,
      installment: titleNumber,
      date: formattedDate,
      category,
      type,
      tenantId,
    };
    const requesDataNotNullValues: any = {};

    Object.keys(requestData).map((key) => {
      if (!!requestData[key as keyof BuyerDetailsFilterInterface]) {
        requesDataNotNullValues[key as keyof BuyerDetailsFilterInterface] =
          requestData[key as keyof BuyerDetailsFilterInterface];
      }
    });

    if (!this.lastPage) {
      this.financialService
        .geTotalValue(requesDataNotNullValues)
        .subscribe((response) => {
          this.totalValue = response;
        });
      this.financialService
        .getFinancialBuyersDetails(
          this.pageIndex,
          this.pageSize,
          requesDataNotNullValues
        )
        .subscribe((data) => {
          this.isLoading = false;
          this.dataSource = this.dataSource.concat(data.content);
          if (!data.last) this.pageIndex = this.pageIndex + 1;
          if (data.last) this.lastPage = true;
        });
    }
  }
  getCategoryName(row: any) {
    const { accountType, entryType } = row;
    const name = getCategoryName(accountType, entryType);
    return name;
  }

  getDocumentFinancialName(document: any) {
    return this.documentOptions.find((_) => _.value === document)?.name ?? '-';
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource);
  }

  checkboxLabel(row: BuyerDetailsFinancialInterface): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      this.dataSource.indexOf(row) + 1
    }`;
  }
  clearFilters() {
    this.formFilters.reset();
    this.handleSubmit();
  }
  handleSubmit() {
    this.isLoading = true;
    this.dataSource = [];
    this.lastPage = false;
    this.pageIndex = 0;
    this.getDetailsFinancialBuyers();
  }
  handleAutocompleteBlur() {
    const lead = this.formFilters.controls['lead'].value;
    if (!lead?.id) {
      this.formFilters.patchValue({ lead: null });
    }
  }

  handleAutocompleteSacadoBlur() {
    const sacado = this.formFilters.controls['sacado'].value;
    if (!sacado?.id) {
      this.formFilters.patchValue({ sacado: null });
    }
  }

  getStatusColor(row: any) {
    const { accountType, entryType } = row;
    const color = getCategoryColor(accountType, entryType);
    return color;
  }
  ngOnInit(): void {
    this.getDetailsFinancialBuyers();
    this.company = this.buyerFinancialService.getCompany();

    const date = this.buyerFinancialService.getDate();
    const formatedDate = new Intl.DateTimeFormat('pt-br', {
      month: '2-digit',
      year: 'numeric',
    }).format(new Date(date));

    this.date = formatedDate;
  }
}
