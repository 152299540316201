<h2 mat-dialog-title class="mb-2">{{data?.action | titlecase}} {{ data?.entity | titlecase}}</h2>

<mat-dialog-content class="mat-typography">

  <div class="border rounded mb-4 p-2">
    <h3 class="mb-0">{{ data?.object?.description }}</h3>
    <div class="d-flex justify-content-start">
      <small class="mr-3">Nome: {{ data.object.name}}</small>
      <small>Data de Inclusão: {{data.object.createdAt}}</small>
    </div>
  </div>

  <mat-form-field appearance="outline" class="w-100" >
    <mat-label>Status</mat-label>
    <input matInput readonly [value]="data.action | uppercase">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-100"  *ngIf="data.show.observation">
    <mat-label>Justificativa</mat-label>
    <textarea matInput [(ngModel)]="rejection"  required cdkFocusInitial> </textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-between">
  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-flat-button [color]="data.btnColor" (click)="close()" cdkFocusInitial [disabled]="rejection.trim().length < 10 && data.show.observation">Confirmar</button>
</mat-dialog-actions>
