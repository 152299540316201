<div class="container-page" *ngIf="!showForgetPasswordPage">

  <form class="form-page" [formGroup]="formLogin">

    <img class="logo"
      src="https://s3-sa-east-1.amazonaws.com/antecipagov.public.docs/images/BROADFACTOR_MARCA_FUNDOCINZA_20.png"
      alt="">

    <div class="alert alert-danger" role="alert" *ngIf="hasError" data-cy="login-error-message">
      {{ errorMessage }}
    </div>

    <div class="pb-2">
      <mat-form-field appearance="outline">
        <mat-label>E-mail</mat-label>
        <input matInput formControlName="mail" required placeholder="exemplo@broadfactor.com" data-cy="email-input">
      </mat-form-field>
    </div>

    <div class="pb-3">
      <mat-form-field appearance="outline">
        <mat-label>Senha</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required autocomplete="on" data-cy="password-input">
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
          >
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-hint>Mínimo 6 dígitos</mat-hint>
      </mat-form-field>
    </div>

    <button class="mt-3 py-2" mat-flat-button color="primary" [disabled]="!formLogin.valid" (click)="login()"
    data-cy="login-button"
    >Entrar</button>

  </form>
</div>
