import { Component, Input, SimpleChanges } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Location } from '@angular/common';
import { SearchService } from 'src/app/services/search.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  @Input() header!: any;
  @Input() hasToolbar!: boolean;
  searchModel: any;

  constructor(
    private router: Router,
    private location: Location,
    private searchService: SearchService
  ) {
    this.searchService.clearGetEmmiter().subscribe((x: any) => {
      this.updateSearchModel(null);
    });

    router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((val) => this.updateSearchModel(null));
  }

  goBack() {
    if (this.header.previousRoute) {
      this.router.navigate([this.header.previousRoute]);
      return;
    }
    this.header?.locationBack
      ? this.location.back()
      : this.router.navigate(['/modules']);
  }

  updateSearchModel(value: any) {
    this.searchModel = value;
    this.searchService.search.next(value);
  }
}
