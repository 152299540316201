export class CustomerManagementUtils {
  static getTypeCustomer(value: string){
    switch(value){
      case 'plataform':
        return 'PLATAFORM';
      case 'fidc':
        return 'FIDC';
      case 'supplier':
        return 'SUPPLIER';
      case 'my-customers':
        return 'MY_CUSTOMERS';
      case 'financial-instituition':
        return 'FINANCIAL_INSTITUTION';
      case 'fomento':
        return 'FOMENTO'
      default:
        return 'something';
    }
  }

  static getFilterFlags(value: string){
    switch(value){
      case 'PLATAFORM':
        return {
          hasClient: true,
          hasStatus: true,
          hasSource: true,
          hasFilter: true,
          hasStatusDocument: false,
        };

      case 'SUPPLIER':
        return {
          hasClient: false,
          hasStatus: false,
          hasSource: false,
          hasFilter: true,
          hasStatusDocument: true,
        };
      default :
        return {
          hasClient: false,
          hasStatus: false,
          hasSource: false,
          hasFilter: true,
          hasStatusDocument: false,
        };
    }
  }

  static getFormatTypeToService(value: string){
    switch(value){
      case 'FIDC':
        return 'fidc';
      case 'SUPPLIER':
        return 'supplier';
      case 'MY_CUSTOMERS':
        return 'mine';
      case 'FINANCIAL_INSTITUTION':
        return 'if';
      case 'FOMENTO':
        return 'fomento'
      default:
        return 'something';
    }
  }

  static getHeader(value: string, status: string = 'ALL'){
    switch(value){
      case 'PLATAFORM':
        return {
          title: 'Clientes',
          subtitle: 'Clientes de operações privadas'
        };
      case 'MY_CUSTOMERS':
        return {
          title: 'Gestão de clientes',
          subtitle: 'Meus clientes'
        };

      case 'FIDC':
      case 'SUPPLIER':
      case 'FINANCIAL_INSTITUTION':
      case 'FOMENTO':
        const statusDictionary: { [key: string]: string } = {
          ALL: 'Todas as instituições financeiras cadastradas na plataforma',
          ENABLED: 'Instituições financeiras com cadastro finalizado e ativos na plataforma',
          PENDING: 'Instituições financeiras que iniciaram o cadastro e não finalizaram',
          BLOCKED: 'Instituições financeiras com acesso bloqueado na plataforma',
          VALIDATING: 'Validando dados/documentos da instituição financeira',
          NEW: 'Instituições financeiras que não finalizaram o cadastro',
          OVERDUE: 'Instituições financeiras com pendências financeiras',
        }

        return {
          title: 'Gestão de clientes',
          subtitle: statusDictionary[status]
        };


      default:
        return {
          title: 'Gestão de clientes',
          subtitle: 'SOMETHING'
        };
    }

  }
}
