// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_url: 'http://api.dev.antecipagov.com.br/',
  API_register: 'http://api.dev.register.broadfactor.com',
  register_url: 'http://register.dev.broadfactor.com',
  finance_url: 'http://api.dev.finance.antecipagov.com.br',
  register_api: 'http://api.register.dev.broadfactor.com/',
  operation_api: 'http://api.dev.operations.broadfactor.com/',
  aditive_api: 'http://api.dev.signer.broadfactor.com/api/',
  signer_url: 'http://cloud.bry.com.br/scad/workflow/',
  financial_contaazul: 'http://finance.dev.broadfactor.com',
  operation_api_token:
    'Cj$rJwr23ZjVYb7z3AfjY6%u&w^@M^WvwHWw@^m#Y4pwgB3Z7jfZ6m5sKZaO!LAfi7!BYyT$MBYx%YrhLRoe7Xag9ojjVKclLtFrR1jE@eqz&#5vVHhwoYQ0IrJHL1%y%nILjYuuE#YV9&mUe2luG&vYZZ%zYrE6jG*M4NQ&x6iB5&5CMpBdqd8H9tLw%VSP0xhu0r',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
