import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { RoutePages } from '../../enum/router.enum';
import { User } from '../../model/user';
import { AuthService } from '../../services/auth.service';
import { TitlePageService } from '../../services/title-page.service';

export interface card {
  icon: string;
  title: string;
  subtitle: string;
  path: string;
  disabled?: boolean;
  module: string;
}

@Component({
  selector: 'app-apps-module',
  templateUrl: './apps-module.component.html',
  styleUrls: ['./apps-module.component.scss'],
})
export class AppsModuleComponent implements OnInit {
  loading!: boolean;
  user!: User;

  applications: card[] = [
    {
      icon: 'dvr',
      title: 'AntecipaGov',
      subtitle: 'Lista de propostas e lista de cotações',
      path: RoutePages.AntecipaGov_QUOTES,
      disabled: true,
      module: 'ANTECIPAGOV',
    },
    {
      icon: 'groups',
      title: 'Gestão de clientes',
      subtitle: 'Gestão de clientes e mudança de status',
      path: RoutePages.CustomerManagement,
      disabled: true,
      module: 'MANAGEMENT_CUSTOMERS',
    },
    {
      icon: 'group_add',
      title: 'Gestão de Leads',
      subtitle: 'Gestão de leads Jaer, AntecipaGov e Plataforma Privada',
      path: RoutePages.Leads,
      disabled: true,
      module: 'MANAGEMENT_CUSTOMERS',
    },
    {
      icon: 'manage_accounts',
      title: 'Gestão de usuários',
      subtitle: 'Criação de usuários, lista de usuários',
      path: RoutePages.UserManagement,
      disabled: true,
      module: 'MANAGEMENT_USER',
    },
    {
      icon: 'cloud_upload',
      title: 'SERASA',
      subtitle:
        'Gerarador de remessas, envio de Remessas ou Retornos e processamento de conciliação',
      path: RoutePages.Serasa,
      disabled: true,
      module: 'MANAGEMENT_USER',
    },
    {
      icon: 'attach_money',
      title: 'Financeiro',
      subtitle: 'Demonstrativo mensal de finanças',
      path: RoutePages.Financial,
      disabled: true,
      module: 'MANAGEMENT_USER',
    },
    {
      icon: 'receipt_long',
      title: 'Operação',
      subtitle: 'Gestão de operações',
      path: RoutePages.Operacao,
      disabled: true,
      module: 'MANAGEMENT_CUSTOMERS',
    },
  ];

  constructor(
    private titlePageService: TitlePageService,
    private authService: AuthService
  ) {
    this.loading = true;
    this.authService.user.pipe(take(1)).subscribe((user: any) => {
      this.user = user;
      this.validateAccess();
    });
  }

  ngOnInit() {
    this.titlePageService.setHeader({ title: 'Módulos', subtitle: '' });
  }

  validateAccess() {
    if (this.user.userType === 'OPERATION') {
      const app: card[] = this.applications.filter(
        (x) => x.module !== 'MANAGEMENT_USER'
      );
      app.map((element) => {
        element.disabled = false;
      });
    } else {
      this.applications.map((card) => (card.disabled = false));
    }
  }
}
