export class StatusOptionsModal {
  static list() {
    return [
      { name: 'Cadastrado', value: 'REGISTERED' },
      { name: 'Disponível', value: 'AVAILABLE' },
      { name: 'À venda', value: 'FOR_SALE' },
      { name: 'Em análise', value: 'IN_ANALYSIS' },
      { name: 'Aguardando assinatura ', value: 'DOCUMENT' },
      { name: 'Pagos', value: 'PAID' },
      { name: 'Liquidado', value: 'SETTLED' },
      { name: 'Em cobrança', value: 'IN_COLLECTION' },
      { name: 'Baixado', value: 'DOWNLOADED' },
    ];
  }
}

export class ResponsibleOptionsModal {
  static list() {
    return [{ fullName: 'Todos' }];
  }
}
