export class CreateBlob {
    static Do(data: any, type: 'zip' | 'pdf', fileName: string) {
        var file = new Blob([data], { type: 'application/' + type })
        var fileURL = URL.createObjectURL(file);
        var a = document.createElement('a');
        a.href = fileURL;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
    }
}