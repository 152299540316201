<div class="delete-dialog-container">
  <div class="dialog-header">
    <mat-icon class="delete-icon">delete</mat-icon>
    <b>Deseja deletar este documento?</b>
    <small>Esta ação não poderá ser desfeita depois!</small>
  </div>
  <div class="dialog-body">
    <mat-icon class="document-icon">insert_drive_file</mat-icon>
    <p matTooltip="{{data?.name}}" class="name-file">{{ data?.name }}</p>
  </div>
  <div class="dialog-actions">
    <button (click)="close()" class="cancel-button" mat-stroked-button color="accent">Cancelar</button>
    <button mat-flat-button class="ml-1" (click)="deleteDoc()" [mat-dialog-close]="true" color="primary">Deletar Documento</button>
  </div>
</div>
