import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentage',
})
export class PercentagePipe implements PipeTransform {
  transform(percent?: string | number, ...args: unknown[]): string | void {
    if (percent === undefined || percent === null) return;
    let value = new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(+percent);
    let formatted = `${value}%`;

    return formatted;
  }
}
