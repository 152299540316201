import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RoutePages } from '../enum/router.enum';
import { RouteService } from './route.service';

export interface Menu {
  name: string;
  route: string;
  icon: string;
}

export enum menuTypes {}

@Injectable({ providedIn: 'root' })
export class MenuService {
  constructor() {}

  optinsMenu = new BehaviorSubject<any>(null);
  ca = new BehaviorSubject<any>(null);

  setMenu(menu: any) {
    this.optinsMenu.next(menu);
  }

  setCa(menu: Menu | null) {
    this.ca.next(menu);
  }

  public setModulesMenu: any = {
    antecipagov: () => {
      this.setMenu([
        {
          name: 'Cotações',
          route: RoutePages.AntecipaGov_QUOTES,
          icon: 'description',
        },
        {
          name: 'Propostas',
          route: RoutePages.AntecipaGov_PROPOSALS,
          icon: 'pending_actions',
        },
        {
          name: 'Operação',
          route: RoutePages.AntecipaGov_OPERATION,
          icon: 'military_tech',
        },
      ]);
      this.setCa(null);
    },
    customerManagement: () => {
      this.setMenu([
        {
          name: 'Meus clientes',
          route: RoutePages.CustomerManagement_MY_CUSTOMER,
          icon: 'folder_shared',
        },
        {
          name: 'Instituição Financeira',
          route: RoutePages.CustomerManagement_FI,
          icon: 'account_balance',
        },
        {
          name: 'Fomento',
          route: RoutePages.CustomerManagement_FACTORING,
          icon: 'account_balance',
        },
        {
          name: 'FIDC',
          route: RoutePages.CustomerManagement_FIDC,
          icon: 'account_balance',
        },
        {
          name: 'Fornecedor',
          route: RoutePages.CustomerManagement_SUPPLIER,
          icon: 'store',
        },
        {
          name: 'Plataforma',
          route: RoutePages.CustomerManagement_PLATAFORM,
          icon: 'dashboard_customize',
        },
      ]);
      this.setCa(null);
    },
    financial: () => {
      this.setMenu([
        {
          name: 'AntecipaGov',
          route: RoutePages.Financial + '/antecipaGov',
          icon: 'send',
        },
        {
           name: 'Conta Azul',
           route: RoutePages.Financial + '/contaAzul',
           icon: 'send',
         },
      ]);
      this.setCa(null);
    },
    leads: () => {
      this.setMenu([
        {
          name: 'Integrações',
          route: RoutePages.Integrations,
          icon: 'dashboard_customize',
        },
        {
          name: 'AntecipaGov',
          route: RoutePages.LeadsAntecipagov,
          icon: 'dashboard_customize',
        },
        {
          name: 'Broadfactor',
          route: RoutePages.LeadsPlataform,
          icon: 'dashboard_customize',
        },
        {
          name: 'Jaer',
          route: RoutePages.LeadsJaer,
          icon: 'dashboard_customize',
        },
        {
          name: 'Rejeitados',
          route: RoutePages.LeadsRejeitados,
          icon: 'dashboard_customize',
        },
      ]);
      this.setCa(null);
    },
    serasa: () => {
      this.setMenu([
        {
          name: 'Enviar documentos',
          route: RoutePages.SerasaUpload,
          icon: 'send',
        },
        {
          name: 'Gerar remessa',
          route: RoutePages.SerasaDocument,
          icon: 'send_and_archive',
        },
        {
          name: 'Processar conciliação',
          route: RoutePages.SerasaGenerateConciliation,
          icon: 'description',
        },
      ]);
      this.setCa(null);
    },
    userManagement: () => {
      this.setCa({
        name: 'Adicionar usuário ',
        route: RoutePages.UserManagement_CREATE,
        icon: '',
      });
      this.setMenu([
        {
          name: 'Usuários',
          route: RoutePages.UserManagement_LIST,
          icon: 'person',
        },
      ]);
    },
  };
}
