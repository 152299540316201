const statusColors = {
  COMMISSION_EXPENSE: '#ffe5d0',
  DOC_SIGN_EXPENSE: '#bff6e6',
  COMMISSION_DISCOUNT: '#e28c49',
  DOC_SIGN_DISCOUNT: '#e28c49',
};

const statusNames = {
  COMMISSION_EXPENSE: 'Comissão',
  DOC_SIGN_EXPENSE: 'Documentos',
  COMMISSION_DISCOUNT: 'Crédito Comissão',
  DOC_SIGN_DISCOUNT: 'Crédito Documentos',
};

const defaultColor = '#ECECEC';
const defaultName = '-';

export const getCategoryColor = (category: string, type: string) => {
  const key = `${category}_${type}`;
  const color = statusColors[key as keyof typeof statusColors];
  return color ?? defaultColor;
};

export const getCategoryName = (category: string, type: string) => {
  const key = `${category}_${type}`;
  const name = statusNames[key as keyof typeof statusNames];
  return name ?? defaultName;
};
