import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoutePages } from './enum/router.enum';
import { AuthGuard } from './guards/auth.guard';
import { AppsModuleComponent } from './pages/apps-module/apps-module.component';
import { LoginComponent } from './pages/login/login.component';
import { RecoverPasswordComponent } from './pages/login/recover-password/recover-password.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/modules',
    pathMatch: 'full',
  },
  {
    path: RoutePages.Login,
    component: LoginComponent,
  },
  {
    path: RoutePages.RecoverPassword,
    component: RecoverPasswordComponent,
  },
  {
    path: RoutePages.Modules,
    component: AppsModuleComponent,
    canActivate: [AuthGuard],
  },
  {
    path: RoutePages.CustomerManagement,
    data: { menu: RoutePages.CustomerManagement },
    loadChildren: () =>
      import(`./modules/customer-management.module`).then(
        (m) => m.CustomerManagementModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: RoutePages.UserManagement,
    data: { menu: RoutePages.UserManagement },
    loadChildren: () =>
      import(`./modules/user-management.module`).then(
        (m) => m.UserManagementModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: RoutePages.Leads,
    data: { menu: RoutePages.Leads },
    loadChildren: () =>
      import(`./modules/leads.module`).then((m) => m.LeadsModule),
    canActivate: [AuthGuard],
  },
  {
    path: RoutePages.AntecipaGov,
    data: { menu: RoutePages.AntecipaGov },
    loadChildren: () =>
      import(`./modules/antecipagov.module`).then((m) => m.AntecipaGovModule),
    canActivate: [AuthGuard],
  },
  {
    path: RoutePages.Serasa,
    data: { menu: RoutePages.Serasa },
    loadChildren: () =>
      import(`./modules/serasa.module`).then((m) => m.SerasaModule),
    canActivate: [AuthGuard],
  },
  {
    path: RoutePages.Financial,
    data: { menu: RoutePages.Financial },
    loadChildren: () =>
      import(`./modules/financial.module`).then((m) => m.FinancialModule),
    canActivate: [AuthGuard],
  },
  {
    path: RoutePages.Operacao,
    data: { menu: RoutePages.Operacao },
    loadChildren: () =>
      import(`./modules/contaAzul.module`).then((m) => m.ContaAzulModule),
    canActivate: [AuthGuard],
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
