import { LeadsService } from 'src/app/services/leads.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { CustomerService } from '../../../../services/customer.service';

@Component({
  selector: 'app-customer-timeline',
  templateUrl:
    '../../../../components/customer-timeline/customer-timeline.component.html',
  styleUrls: [
    '../../../../components/customer-timeline/customer-timeline.component.scss',
  ],
})
export class CustomerTimelineComponent implements OnInit {
  formData!: FormGroup;
  @Input() customerId!: string;
  @Input() isLead: boolean = false;
  timeline: any;
  loading: boolean = false;
  savingData: boolean = false;
  showAddHistory: boolean = false;

  constructor(
    private leadsService: LeadsService,
    private fb: FormBuilder,
    private auth: AuthService,
    private customerService: CustomerService,
    private matSnackBar: MatSnackBar
  ) {
    this.formData = this.fb.group({
      observation: ['', Validators.required],
      type: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.loading = true;

    if (!this.isLead) {
      this.customerService
        .getHistory(this.customerId)
        .pipe(take(1))
        .subscribe(
          (data) => {
            this.loading = false;
            this.timeline = data;
          },
          (err) => {
            this.loading = false;
            this.matSnackBar.open(
              'Ocorreu um erro ao tentar buscar o histórico deste cliente, tente novamente mais tarde',
              'OK',
              { duration: 5000 }
            );
          }
        );
    } else {
      this.leadsService
        .getHistory(this.customerId)
        .pipe(take(1))
        .subscribe(
          (data) => {
            this.loading = false;
            this.timeline = data;
          },
          (err) => {
            this.loading = false;
            this.matSnackBar.open(
              'Ocorreu um erro ao tentar buscar o histórico deste cliente, tente novamente mais tarde',
              'OK',
              { duration: 5000 }
            );
          }
        );
    }
  }

  addHistoric() {
    this.savingData = true;
    const data: any = {
      date: null,
      observation: this.formData.get('observation')?.value,
      responsible: this.auth.user.value?.fullName,
      type: this.formData.get('type')?.value,
    };
    this.formData.reset();

    if (!this.isLead) {
      this.customerService
        .createHistory(this.customerId, data)
        .pipe(take(1))
        .subscribe(
          (data) => {
            this.savingData = false;
            this.timeline.unshift(data);
          },
          (err) => {
            this.savingData = false;
            this.matSnackBar.open(
              'Ocorreu um erro ao tentar criar um histórico, tente novamente',
              'OK',
              { duration: 5000 }
            );
          }
        );
    } else {
      this.leadsService
        .createHistory(this.customerId, data)
        .pipe(take(1))
        .subscribe(
          (data) => {
            this.savingData = false;
            this.timeline.unshift(data);
          },
          (err) => {
            this.savingData = false;
            this.matSnackBar.open(
              'Ocorreu um erro ao tentar criar um histórico, tente novamente',
              'OK',
              { duration: 5000 }
            );
          }
        );
    }
  }
}
