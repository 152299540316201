import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface ButtonCard {
  name: string;
  route: string;
  disabled?: boolean;
}

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() buttons?: ButtonCard[];
  @Input() status?: any;
  @Output() clicked = new EventEmitter()

  constructor() { }

  ngOnInit() {
  }

}
