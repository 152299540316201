import { GetResponseInterface } from '../pages/suite/leads-management/plataform-leads/plataform-leads-edit/interfaces/get-response.interface';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MonthlyPlanInteface } from '../pages/suite/leads-management/plataform-leads/plataform-leads-edit/interfaces/monthly-plan.interface';
import { Pagination } from '../pages/suite/customer-management/interfaces/customer.interface';
import { response } from '../pages/suite/leads-management/integrations/integrations.component';

@Injectable({
  providedIn: 'root',
})
export class LeadsService {
  constructor(private http: HttpClient) {}
  updateCustomerResponsible(customerId: string, responsible: any) {
    return this.http.put(
      environment.API_url +
        'management-api/customers/update/responsible/lead/' +
        customerId,
      responsible
    );
  }

  updateStatusCustomer(id: string, status: {}) {
    return this.http.put(
      environment.API_url +
        'management-api/customers/update/lead/situation/' +
        id,
      status
    );
  }

  updateStatusCustomerTwo(id: string, status: {}) {
    return this.http.put(
      environment.API_url + 'management-api/customers/update/lead/status/' + id,
      status
    );
  }

  downloadDocuments(cnpj: string) {
    return this.http.get(
      environment.API_url +
        'management-api/customers/lead/download/all/' +
        cnpj,
      { responseType: 'arraybuffer' }
    );
  }

  changeDocumentStatus(
    id: string,
    documentId: string,
    status: string,
    responsible: string
  ) {
    return this.http.post(
      environment.register_api +
        `secure-api/company/${id}/document/update/${documentId}/${status}/${responsible}`,
      {}
    );
  }
  getMonthlyPlan(companyId: string): Observable<MonthlyPlanInteface> {
    let url = `${environment.register_api}secure-api/company/${companyId}/config/monthlyPlan`;
    return this.http.get<MonthlyPlanInteface>(url);
  }

  getCustomerData(id: string) {
    return this.http.get(
      environment.API_url + 'management-api/customers/details/lead/' + id
    );
  }

  getSignedDocuments(type: 'company' | 'partner', id: string) {
    return this.http.get(
      environment.API_url + 'management-api/documents/leads?path=' + id,
      { responseType: 'text' }
    );
  }

  getCounts(source?: any) {
    const external = source ? '/' + source : '';
    return this.http.get<any[]>(
      environment.API_url +
        'management-api/customers/count/legacy/LEAD' +
        external
    );
  }

  getHistory(id: string) {
    return this.http.get(
      environment.API_url + 'management-api/customers/history/lead/' + id
    );
  }

  createHistory(id: string, data: any) {
    return this.http.post(
      environment.API_url + 'management-api/customers/history/lead/' + id,
      data
    );
  }

  getLeads(
    page: number,
    size: number,
    status?: any,
    source?: any
  ): Observable<GetResponseInterface> {
    let url = `${environment.API_url}management-api/customers/legacy/LEAD/`;
    status !== 'ALL' ? (url += `Situation/${status}/`) : url;
    url += `${page}/${size}/`;
    url += `${source ? source : ''}`;
    return this.http.get<GetResponseInterface>(url);
  }
  getLeadsByStatus(
    page:Pagination,
    status?: any,
    typeClient?: any,
    filter?: string,
    source?: string,
  ): Observable<GetResponseInterface> {

    let url = `${environment.API_url}management-api/customers/legacy/${typeClient}/`;
    status === 'ALL'
      ? (url += `Situation/APPROVED/`)
      : (url += `status/${status}/`);
    url += `${page.index}/${page.size}/`
    source ? url += `${source}/` : '';
    url += `?filter=${filter || ''}`;
    return this.http.get<GetResponseInterface>(url);
  }
  getLeadsBySituation(
    page: number,
    size: number,
    status?: any,
    typeClient?: any,
    filter?: string,
    source?: string
  ): Observable<GetResponseInterface> {
    let url = `${environment.API_url}management-api/customers/legacy/${typeClient}/`;
    let external = `${page}/${size}/${source ? source : ''}`;
    status === 'ALL'
      ? (url += external)
      : (url += `Situation/${status}/${external}`);
    url += `?filter=${filter}`;

    return this.http.get<GetResponseInterface>(url);

  }
  getLeadsByTypeClient(
    page: number,
    size: number,
    typeClient: string,
    source?: string
  ): Observable<GetResponseInterface> {
    let external = `${page}/${size}/${source ? source : ''}`;
    let url = `${environment.API_url}management-api/customers/legacy/${typeClient}/${external}`;

    return this.http.get<GetResponseInterface>(url);
  }

  getCostumersByTypeClient(
    page:Pagination,
    typeClient?: any,
    situation?: string,
    source?: string
  ): Observable<GetResponseInterface> {
    let external = `${page.index}/${page.size}/${source ? source : ''}`;
    let url = `${
      environment.API_url
    }management-api/customers/legacy/${typeClient}/status/${
      situation || 'APPROVED'
    }/${external}`;

    return this.http.get<GetResponseInterface>(url);
  }

  getLeadsClientByTypeClient(
    page:Pagination,
    typeClient?: any,
    situation?: string,
    source?: string
  ): Observable<GetResponseInterface> {
    let external = `${page.index}/${page.size}/${source ? source : ''}`;
    let url = `${
      environment.API_url
    }management-api/customers/legacy/${typeClient}/Situation/${
      situation || 'APPROVED'
    }/${external}`;

    return this.http.get<GetResponseInterface>(url);
  }
  getAllApprovedLeads(
    page: number,
    size: number
  ): Observable<GetResponseInterface> {
    let url = `${environment.API_url}management-api/customers/legacy/LEAD/Situation/APPROVED/${page}/${size}`;
    return this.http.get<GetResponseInterface>(url);
  }

  getLeadsByCnpjOrName(
    typeClient: string,
    cnpjOrName: string,
    situation: string,
    page: number,
    size: number,
    source?: string
  ): Observable<GetResponseInterface> {
    let url = `${environment.API_url}management-api/customers/legacy/${typeClient}/`;
    url += situation !== 'ALL' ? `Situation/${situation}/` : '';
    url += `${page}/${size}/`;
    url += source ? `${source}` : '';
    url += `?filter=${cnpjOrName}`;

    return this.http.get<GetResponseInterface>(url);
  }
  getLeadsRejeitados(
    page: number,
    size: number,
    typeClient?: string,
    source?: string
  ): Observable<GetResponseInterface> {
    let url = `${environment.API_url}management-api/customers/legacy/${
      typeClient || 'LEAD'
    }/Situation/REJECTED/${page}/${size}/`;
    url += source ? `${source}` : '';

    return this.http.get<GetResponseInterface>(url);
  }

  getLeadsByRejectedReasons(
    rejectionReasons: string[],
    page: number,
    size: number,
    typeClient?: string,
    source?: string,
    filter?: string
  ): Observable<GetResponseInterface> {
    let url = `${environment.API_url}management-api/customers/legacy/${
      typeClient || 'LEAD'
    }/rejected/${page}/${size}/`;
    url += source ? `${source}` : '';
    url += `?filter=${filter || ''}`;
    return this.http.post<GetResponseInterface>(url, rejectionReasons || []);
  }

  getCustomersByCnpjOrName(
    typeClient: string,
    cnpjOrName: string,
    status: string,
    page: number,
    size: number,
    source?: string
  ): Observable<GetResponseInterface> {
    let url = `${environment.API_url}management-api/customers/legacy/${typeClient}/`;
    url += status !== 'ALL' ? `status/${status}/` : 'Situation/APPROVED/';
    url += `${page}/${size}/`;
    url += source ? `${source}/` : '';
    url += `?filter=${cnpjOrName || ''}`;

    return this.http.get<GetResponseInterface>(url);
  }

  getIntengrations(page: Pagination, filter:any) {
    return this.http.get<GetResponseInterface>(
      environment.register_api + `secure-api/company/${filter.typeClient}`
          +`/situation/integrations/${filter.situation ? filter.situation + '/' : '' }`
          +`${page.index}/${page.size}/${filter.source ? filter.source + '/' : ''}`
          +`?filter=${filter.enteredData ? filter.enteredData : ''}`
    );
  }

  getBroadfactorCustomers(page: Pagination, filter:any) {
    return this.http.get<GetResponseInterface>(
      environment.register_api + `secure-api/company/${filter.typeClient}`
          +`/situation/${filter.situation ? filter.situation + '/' : '' }`
          +`${page.index}/${page.size}/${filter.source ? filter.source + '/' : ''}`
          +`?filter=${filter.enteredData ? filter.enteredData : ''}`
    );
  }
}
