import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Document } from 'src/app/interfaces/modal-aditive.interface';

@Component({
  selector: 'app-change-status-modal',
  templateUrl: './change-status-modal.component.html',
  styleUrls: ['./change-status-modal.component.scss']
})
export class ChangeStatusModalComponent implements OnInit {

  rejection = '';


  constructor(
    public dialogRef: MatDialogRef<ChangeStatusModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChangeStatusModalData,
  ) {}

  ngOnInit(): void {
  }

  close(){
    if(this.data.show.observation && !this.rejection) return;

    const data = {rejection: this.rejection, bool: true};
    this.dialogRef.close(data);
  }
}

export interface ChangeStatusModalData {
  object: any;
  action: string;
  entity: string;
  show: {status: boolean, observation: boolean};
  btnColor: string;
}


