export class DocumentsCompanyList {
  static list() {
    return [
      {
        name: 'Última alteração do contrato social consolidado ou constituição e alterações',
        files: [],
        type: 'CONTRATO_SOCIAL',
      },
      {
        name: 'DRE',
        files: [],
        type: 'DRE',
      },
      {
        name: 'Comprovante de Endereço (até 60 dias)',
        files: [],
        type: 'ENDERECO',
      },
      {
        name: 'Foto fachada da empresa',
        files: [],
        type: 'FACHADA_EMPRESA',
      },
      {
        name: 'Declaração de faturamento mensal (últimos 12 meses)',
        files: [],
        type: 'FATURAMENTO',
      },
      {
        name: 'Fotos internas da empresa (estoque, produção, administrativo)',
        files: [],
        type: 'INTERIOR_EMPRESA',
      },
      {
        name: 'Nota fiscal',
        files: [],
        type: 'NF',
      },
      {
        name: 'Outros',
        files: [],
        type: 'OTHERS',
      },
      {
        name: 'Declaração de MEI',
        files: [],
        type: 'MEI',
      },
      {
        name: 'Cartão de CNPJ',
        files: [],
        type: 'CNPJ',
      },
      {
        name: 'Extrato do Simples Nacional',
        files: [],
        type: 'EXTRATO_SIMPLES',
      },
      {
        name: 'Último balanço',
        files: [],
        type: 'ULTIMO_BALANCO',
      },
      {
        name: 'Penúltimo balanço',
        files: [],
        type: 'PENULTIMO_BALANCO',
      },
      {
        name: 'Ata constituição',
        files: [],
        type: 'ATA_CONSTITUICAO',
      },
      {
        name: 'Ata eleição',
        files: [],
        type: 'ATA_ELEICAO',
      },
      {
        name: 'Número do CPF',
        files: [],
        type: 'CPF',
      },
      {
        name: 'Contrato de Prestação de Serviços Broadfactor',
        files: [],
        type: 'CONTRATO_SERVICO_BROADFACTOR',
      },
    ];
  }
}
export class MonthlyOptionsList {
  static list() {
    return [
      { name: 'Start', type: 'START' },
      { name: 'M.E', type: 'ME' },
      { name: 'EPP', type: 'EPP' },
      { name: 'Enterprise', type: 'ENTERPRISE' },
      { name: 'Escrow 1', type: 'ESCROW1' },
      { name: 'Escrow 2', type: 'ESCROW2' },
    ];
  }
}
export class TypeOptionsList {
  static list() {
    return [
      { name: 'Privado', value: 'private', selected: false },
      { name: 'Empenho', value: 'effort', selected: false },
    ];
  }
}
export class OptionsInvoicingDay {
  static list(): { date: string; value: string }[] {
    return [
      { date: '01/xx/xxxx', value: '1' },
      { date: '02/xx/xxxx', value: '2' },
      { date: '03/xx/xxxx', value: '3' },
      { date: '04/xx/xxxx', value: '4' },
      { date: '05/xx/xxxx', value: '5' },
      { date: '06/xx/xxxx', value: '6' },
      { date: '07/xx/xxxx', value: '7' },
      { date: '08/xx/xxxx', value: '8' },
      { date: '09/xx/xxxx', value: '9' },
      { date: '10/xx/xxxx', value: '10' },
      { date: '11/xx/xxxx', value: '11' },
      { date: '12/xx/xxxx', value: '12' },
      { date: '13/xx/xxxx', value: '13' },
      { date: '14/xx/xxxx', value: '14' },
      { date: '15/xx/xxxx', value: '15' },
      { date: '16/xx/xxxx', value: '16' },
      { date: '17/xx/xxxx', value: '17' },
      { date: '18/xx/xxxx', value: '18' },
      { date: '19/xx/xxxx', value: '19' },
      { date: '20/xx/xxxx', value: '20' },
      { date: '21/xx/xxxx', value: '21' },
      { date: '22/xx/xxxx', value: '22' },
      { date: '23/xx/xxxx', value: '23' },
      { date: '24/xx/xxxx', value: '24' },
      { date: '25/xx/xxxx', value: '25' },
      { date: '26/xx/xxxx', value: '26' },
      { date: '27/xx/xxxx', value: '27' },
      { date: '28/xx/xxxx', value: '28' },
      { date: '29/xx/xxxx', value: '29' },
      { date: '30/xx/xxxx', value: '30' },
      { date: '31/xx/xxxx', value: '31' },
    ];
  }
}

export class OptionsOperationSacado {
  static list(): { operation: string; type: string; checked?: boolean }[] {
    return [
      { operation: 'Confirmado sacado', type: 'CONFIRMED_DRAWEE' },
      { operation: 'Cedente envia boleto', type: 'ASSIGNOR_BILLET' },
      { operation: 'Comissária', type: 'COMMISSIONER' },
      { operation: 'Cheque', type: 'CHEQUE' },
      { operation: 'Conta escrow', type: 'ESCROW_ACCOUNT' },
      { operation: 'Confirmado pedido', type: 'CONFIRMED_REQUEST' },
      {
        operation: 'Sem confirmação - pulverizadas',
        type: 'NO_CONFIRMATION_SPRAYED',
      },
      { operation: 'Empenho', type: 'PAWN' },
    ];
  }
}

export class DocumentStyleList {
  static list() {
    const documentStatusStyle: DocumentStatusStyleInterface = {
      REJECTED: { icon: 'close', style: '#f44336', tooltip: 'Documento Rejeitado'},
      APPROVED: { icon: 'check', style: '#32ad59', tooltip: 'Documento Aprovado'},
      PENDING: { icon: 'pending', style: '#000', tooltip: 'Documento Pendente'},
      OUTDATED: { icon: 'unpublished', style: '#32ad59', tooltip: 'Documento Desatualizado' }, /* #084d6e azul */
      NOT_IMPORTED: { icon: 'insert_drive_file', style: '#808080', tooltip: 'Documento Não Importado' },
    };

    return documentStatusStyle;
  }
}

interface DocumentStatusStyleInterface {
  [key: string]: {
    icon: string;
    style: string;
    tooltip: string;
  };
}

export class RequiredTypesCompany{
  static list(){
    return [
      {
          description: "Cartão do CNPJ",
          type: "CNPJ",
          status: "NOT_IMPORTED"
      },
      {
          description: "Comprovante de endereço com menos de 60 dias",
          type: "ENDERECO",
          status: "NOT_IMPORTED"
      },
      {
          description: "Contrato Social / Estatuto Social e suas alterações atualizados",
          type: "CONTRATO_SOCIAL",
          status: "NOT_IMPORTED"
      },
      {
          description: "Quadro societário ou Representantes legais",
          type: "QUADRO_SOCIETARIO",
          status: "NOT_IMPORTED"
      },
      {
          description: "Lista de endividamento da empresa",
          type: "ENDIVIDAMENTO_EMPRESA",
          status: "NOT_IMPORTED"
      },
      {
          description: "Balanço/Balancete dos últimos 2 exercícios contábeis",
          type: "PENULTIMO_BALANCO",
          status: "NOT_IMPORTED"
      },
      {
          description: "Declaração de Faturamento ou Demonstrativo DAS (SIMPLES)",
          type: "FATURAMENTO",
          status: "NOT_IMPORTED"
      }
    ]
  }
}
