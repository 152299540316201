<mat-toolbar>
  <div class="w-100 d-flex align-items-center">
    <div class="d-flex align-items-center w-100">
      <h2 data-cy="modules-toolbar-title">Módulos</h2>
    </div>
    <app-user-avatar></app-user-avatar>
  </div>
</mat-toolbar>


<div class="container">
  <div class="cards-group">
    <div class="card-module" [ngClass]="{'d-none': item?.disabled === true}" *ngFor="let item of applications" [routerLink]="'/' + item.path">
      <mat-icon>{{item.icon}}</mat-icon>
      <span>{{item.title}}</span>
      <small class="text-secondary">{{item.subtitle}}</small>
    </div>
  </div>
</div>
