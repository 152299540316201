<div class="menu">
  <app-user-avatar></app-user-avatar>

  <button
    *ngIf="ca && !asCustomerManagement"
    class="mt-4"
    routerLinkActive="active"
    mat-flat-button
    [routerLink]="ca.route"
  >
    {{ ca.name }}
  </button>


  <mat-nav-list *ngIf="!asCustomerManagement" class="mt-4">
    <mat-list-item
      *ngFor="let item of optinsMenu"
      [routerLinkActive]="['active']"
      [routerLink]="item.route"
    >
      <mat-icon matListIcon>{{ item.icon }}</mat-icon>
      <a matLine>{{ item.name }}</a>
    </mat-list-item>
  </mat-nav-list>

  <mat-nav-list *ngIf="asCustomerManagement" class="mt-4 mb-2">
    <mat-list-item
      [routerLinkActive]="['active']"
      routerLink="customerManagement/plataform"
    >
      <mat-icon matListIcon>dashboard_customize</mat-icon>
      <a matLine>Clientes</a>
    </mat-list-item>
  </mat-nav-list>
  <mat-accordion *ngIf="asCustomerManagement" class="app-nav-accordion">
    <mat-expansion-panel expanded="true" class="mat-elevation-z0 panel-header">
      <mat-expansion-panel-header class="icon-indicator">
        <mat-panel-title>Antecipagov</mat-panel-title>

      </mat-expansion-panel-header>
      <mat-nav-list
        *ngFor="let item of optinsMenu; let l = last"
        [routerLinkActive]="['active']"
        [routerLink]="item.route"
        class="nav-list-container"
      >
        <mat-list-item
          [routerLinkActive]="['active']"
          [routerLink]="item.route"
          *ngIf="!l"
        >
          <mat-icon
            class="icon-list"
            *ngIf="item.name !== 'Plataforma'"
            matListIcon
          >
            {{ item.icon }}
          </mat-icon>
          <a
            class="antecipa-gov-link"
            *ngIf="item.name !== 'Plataforma'"
            matLine
          >
            {{ item.name }}
          </a>
        </mat-list-item>
      </mat-nav-list>
    </mat-expansion-panel>
  </mat-accordion>
</div>
