import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-notifier-modal',
  templateUrl: './notifier-modal.component.html',
  styleUrls: ['./notifier-modal.component.scss']
})
export class NotifierModalComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data:any,
  public snackBarRef: MatSnackBarRef<NotifierModalComponent>){}

  ngOnInit(): void {
  }

}
