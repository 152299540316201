
<div class="container">
    <div class="container-item">
        <span class="container-item-message">
            {{data.message}}
        </span>
        <span class="container-item-alternative">
            {{data.alternative}}
        </span>
    </div>
    
    <button *ngIf="data.button" (click)="snackBarRef.dismiss()">{{data.button}}</button>
</div>